enum VALIDATION {
    NAME = 'name',
    PRIMARYUSERNAME = 'primaryUserName',
    EMAIL = 'email',
    PHONE = 'phone',
    CPR = 'cpr',
    PRIMARYUSERCPR = 'primaryUserCpr',
    EXISTINGINSURANCE = 'existingInsurance',
    INSURANCEVALIDFROMDATE = 'validFromDate',
    VALIDBEFORENOW = 'validBeforeNow',
    REGNO = 'regNo',
    KONTONO = 'kontoNo',
    CUSTOMERAGE = 'customerAge',
    CPR_UNDER_EIGHTEEN = 'cprUnderEighteen',
    CPR_AGE = 'cprAge',
    LICENSEPLATE = 'licenseplate',
    AGE_UNDER_18 = 'under18',
    AGE_OVER_18 = 'over18',
}

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
export default class FieldValidator {
    private name: string;
    public errorMsg: string;

    constructor(name: string) {
        this.name = name;
    }

    public isValid (value: string): boolean{
        switch(this.name) {
            case VALIDATION.NAME : return this.isValidName(value);
            case VALIDATION.PRIMARYUSERNAME : return this.isValidName(value);
            case VALIDATION.EMAIL : return this.isValidEmail(value);
            case VALIDATION.PHONE : return this.isValidPhone(value);
            case VALIDATION.CPR : return this.isValidCpr(value);
            case VALIDATION.PRIMARYUSERCPR : return this.isValidCpr(value);
            case VALIDATION.EXISTINGINSURANCE : return this.isValidExistingInsurance(value);
            case VALIDATION.INSURANCEVALIDFROMDATE : return this.isValidFromDate(value);
            case VALIDATION.VALIDBEFORENOW : return this.isValidBeforeNow(value);
            case VALIDATION.REGNO : return this.isValidRegNo(value);
            case VALIDATION.KONTONO : return this.isValidKontoNo(value);
            case VALIDATION.CUSTOMERAGE : return this.isValidAge(value);
            case VALIDATION.CPR_UNDER_EIGHTEEN : return this.getAgeUnderEighteen(value) !== -1;
            case VALIDATION.CPR_AGE : return this.getAgeCpr(value) !== -1;
            case VALIDATION.AGE_UNDER_18 : return this.getAge(value, true) !== -1;
            case VALIDATION.AGE_OVER_18 : return this.getAge(value, false) !== -1;
            case VALIDATION.LICENSEPLATE : return this.isValidLicensePlate(value);

            default : return this.isValidDefault(value);
        }
    }


    public isValidDefault(value: string): boolean {
        if (value === undefined || value.length < 1) {
            this.errorMsg = 'Du mangler indtastning';
            return false;
        }
        return true;
    }
    public isValidName(value: string): boolean {
        if (!value || value.length < 4) {
            this.errorMsg = 'Du skal indtaste fornavn og efternavn';
            return false;
        }
        return true;
    }

    public isValidEmail(value: string): boolean {
        if (!value) {
            this.errorMsg = 'E-mail er ikke indtastet korrekt';
            return false;
        }
        const regex = new RegExp(emailRegex, "gi");
        const isValid = regex.test(value.trim());
        if (!isValid) {
            this.errorMsg = 'E-mail er ikke indtastet korrekt';
            return false;
        }
        return true;
    }

    public isValidPhone(value: string): boolean {
        if (!value || value.trim().replace(/\D/g,'').length < 8) {
            this.errorMsg = 'Telefonnummer er ikke indtastet korrekt';
            return false;
        }
        const regex = new RegExp(phoneRegex, "gi");
        if (!regex.test(value.trim())) {
            this.errorMsg = 'Telefonnummer er ikke indtastet korrekt';
            return false;
        }
        return true;
    }

    public isValidCpr(value: string): boolean {
        if (!value || value.trim().replace(/\D/g,'').length != 10) {
            this.errorMsg = 'CPR-nr er ikke indtastet korrekt';
            return false;
        }
        return true;
    }

    public isValidExistingInsurance (value: string): boolean {
        if (!value || value.trim().length < 2) {
            this.errorMsg = 'Forsikringsselskab er ikke indtastet korrekt';
            return false;
        }
        return true;
    }
    public isValidFromDate(value: string): boolean {
        const err = 'Indtast i formatet dd-mm-åååå';
        if (!value || value.trim().replace(/\D/g,'').length < 4) {
            this.errorMsg = err;
            return false;
        }
        const res = value.trim().match(/[\d]+/g);
        if (res.length < 3) {
            this.errorMsg = err;
            return false;
        }

        const now = new Date();
        const day = parseInt(res[0]);
        const month = parseInt(res[1]);
        const year = parseInt(res[2]);

        if (year > now.getFullYear() + 1 || year < 2000) {
            this.errorMsg = 'Årstal er forkert indtastet';
            return false;
        }
        if (year < now.getFullYear()) {
            this.errorMsg = 'Indtast dato efter i dag';
            return false;
        }
        if (month < 1 || month > 12) {
            this.errorMsg = 'Måned er ikke indtastet korrekt';
            return false;
        }

        const maxDayInMonth = new Date(year, month, 0).getDate();
        if (day < 1 || day > maxDayInMonth) {
            this.errorMsg = 'Dag er ikke indtastet korrekt (1-' + maxDayInMonth + ')';
            return false;
        }

        const userDate = new Date(year, month-1, day, 23, 59, 59, 999);
        if (userDate.getTime() < now.getTime()) {
            this.errorMsg = 'Indtast dato fra i dag eller senere';
            return false;
        }
        return true;
    }

    public isValidBeforeNow(value: string): boolean {
        const err = 'Indtast i formatet dd-mm-åååå';
        if (!value || value.trim().replace(/\D/g,'').length < 4) {
            this.errorMsg = err;
            return false;
        }
        const res = value.trim().match(/[\d]+/g);
        if (res.length < 3) {
            this.errorMsg = err;
            return false;
        }

        if (res[2].length !== 4) {
            this.errorMsg = 'Årstal er forkert indtastet';
            return false;
        }

        const now = new Date();
        const day = parseInt(res[0]);
        const month = parseInt(res[1]);
        const year = parseInt(res[2]);

        if (year > now.getFullYear() + 1) {
            this.errorMsg = 'Indtast en dato før i dag';
            return false;
        }
        if (year > now.getFullYear()) {
            this.errorMsg = 'Indtast en dato før i dag';
            return false;
        }
        if (month < 1 || month > 12) {
            this.errorMsg = 'Måned er ikke indtastet korrekt';
            return false;
        }

        const maxDayInMonth = new Date(year, month, 0).getDate();
        if (day < 1 || day > maxDayInMonth) {
            this.errorMsg = 'Dag er ikke indtastet korrekt (1-' + maxDayInMonth + ')';
            return false;
        }

        const userDate = new Date(year, month-1, day, 23, 59, 59, 999);
        if (userDate.getTime() >= now.getTime()) {
            this.errorMsg = 'Dato er senere end i dag';
            return false;
        }
        return true;
    }

    public isValidAge(value: string): boolean {
        try {
            const v = parseInt(value.trim().replace(/\D/g,''));
            if (isNaN(v) || v < 18 || v > 100) {
                this.errorMsg = 'Alder er ikke indtastet korrekt (18 - 100)';
                return false;
            }
            return true;
        } catch(err) {
            this.errorMsg = 'Alder er ikke indtastet korrekt (18 - 100)';
            return false;
        }
    }
    public isValidRegNo (value: string): boolean {
        if (!value || value.trim().length != 4) {
            this.errorMsg = 'Indtast 4 cifre';
            return false;
        }
        return true;
    }
    public isValidKontoNo (value: string): boolean {
        if (!value || value.trim().length < 6) {
            this.errorMsg = 'Konto nr. ikke korrekt';
            return false;
        }
        return true;
    }

    public isValidLicensePlate(value: string): boolean {
        if (!value || value.trim().length < 2) {
            this.errorMsg = 'Indtast gyldig nummerplade';
            return false;
        }
        return true;
    }

    public getAgeCpr(cpr: string): number {
        if (!this.isValidCpr(cpr)) {
            return -1;
        }
        const value = cpr.trim().replace(/\D/g,'');
        const dateNow = new Date();
        const thisYear = dateNow.getFullYear() - 2000;
        let year = parseInt(value.slice(4,6));
        if (year > thisYear) {
            year = 1900 + year;
        } else {
            year = 2000 + year;
        }

        const dateThen = new Date(year, parseInt(value.slice(2,4)) -1, parseInt(value.slice(0,2)));

        let diffYears =  dateNow.getFullYear() - dateThen.getFullYear();
        const diffMonths =  dateNow.getMonth() - dateThen.getMonth();
        const diffDays =  dateNow.getDate() - dateThen.getDate();

        if(diffMonths < 0) {
            diffYears--;
        }
        if(diffMonths === 0 && diffDays < 0) {
            diffYears--;
        }
        if (diffYears < 0 || diffYears > 100) {
            this.errorMsg = 'cpr ikke indtastet korrekt';
            return -1;
        }
        return diffYears;
    }
    public getAgeUnderEighteen(cpr: string): number {

        const diffYears = this.getAgeCpr(cpr);
        if (diffYears < 0 || diffYears > 17) {
            this.errorMsg = 'Dit barn skal være i mellem 0 og 18 år';
            return -1;
        }
        return diffYears;
    }

    public getAge(age: string, under18: boolean): number {
        let err = 'Alder skal være over 8 år';
        if(under18) {
            err = 'Dit barn skal være i mellem 0 og 18 år';
        }

        if(age === undefined) {
            this.errorMsg = err;
            return -1;
        }
        const age_ = ('' + age).trim().replace(/\D/g,'');
        if(age_.length === 0) {
            this.errorMsg = err;
            return -1;
        }
        const years = parseInt(age_);

        if (under18 && (years < 0 || years > 17)) {
            this.errorMsg = err;
            return -1;
        }
        if(!under18 && (years < 18 || years > 100)){
            this.errorMsg = err;
            return -1;
        }

        return years;

    }
}
