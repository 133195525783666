import { CalculatorPage } from "@/cms/definitions/content-types";
import BuyInsuranceComponent from "@/views/calculators/BuyInsuranceComponent.vue";
import UtilService from "@/services/utilService";
import { PropType } from 'vue';
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";

@Options({
    name: 'CalculatorPageComponent',
    components: {
        BuyInsuranceComponent
    },
    computed: mapState<any>({
        isInEditMode: state => state.epiContext.inEditMode
    }),
    props: {
        model: Object as PropType<CalculatorPage>
    },
})

export default class CalculatorPageComponent extends Vue {
    model: CalculatorPage;

    beforeMount() {
        UtilService.setPageMetaData(this.model);
    }

}
