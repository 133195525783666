import { HtmlBlock } from '@/cms/definitions/content-types';
import { cloneDeep } from 'lodash';
import BuyInsuranceSettings from '../BuyInsuranceSettings';

export const enum PACKAGES {
    LILLE = 'Lille',
    MELLEM = 'Mellem',
    STOR = 'Stor',
    SENIOR = 'Senior',
}

export const productId = 'SS1VUF9VUC0yOA==';

// export const lilleFeatures: Array<ProductFeatures> = [
export const lilleFeatures: Array<any> = [
    {
        name: 'SS1VUF9VUC0yOC02Mg==', // Invaliditet heltid - sum 1 mill
        sum: 1000000,
        flex1: '5',
        flex1Lov: 'LOV_12'
    },
    {
        name: 'SS1VUF9VUC0yOC03', // dødsfaldsdækning heltid - sum 50.000
        sum: 50000
    },
    {
        name: 'SS1VUF9VUC0yOC00Nw==' // ekstra udbetaling if customerAge < 75
    },
];

// export const mellemFeatures: Array<ProductFeatures> = [
export const mellemFeatures: Array<any> = [
    // ...lilleFeatures,
    {
        name: 'SS1VUF9VUC0yOC0zMA==' // Tygge skade
    },
    {
        name: 'SS1VUF9VUC0yOC00NQ==' // Brudskader // if customerAge < 75
    },
];

// export const storFeatures: Array<ProductFeatures> = [
export const storFeatures: Array<any> = [
    // ...mellemFeatures,
    {
        name: 'SS1VUF9VUC0yOC00OA==' // Extra hjælp // if customerAge < 75
    },
    {
        name: 'SS1VUF9VUC0yOC01Mw==' // Kritisk sygdom   if customerAge < 66
    },
];
export class AccidentSettings extends BuyInsuranceSettings{
    public work: Array<string> = [];
    public workIds: Array<string> = [];
    public seniorStep1 = 66;
    public seniorStep2 = 75;
    public maxCustomerAge = 74;
    public customerMaxAgePopupTitle: string = undefined;
    public customerMaxAgePopupContent: string = undefined;
    public customerMaxAgePopupRedirect: string = undefined;

    public customerOtherMaxAgePopupTitle: string = undefined;
    public customerOtherMaxAgePopupContent: string = undefined;
    public customerOtherMaxAgePopupRedirect: string = undefined;

    public customerOtherAgePopupTitle: string = undefined;
    public customerOtherAgePopupContent: string = undefined;
    public customerOtherAgePopupRedirect: string = undefined;



    public constructor(settingsBlock: HtmlBlock) {
        super(settingsBlock);
        this.loadAccidentSettings();
    }

    public getFeaturesByAge(features: Array<any>, customerAge) {
        let filteredFeatures: Array<any> = cloneDeep(features);
        const parsedAge = parseInt(customerAge);
        if (!parsedAge) {
            return filteredFeatures;
        }
        
        // +75, fjern ekstra udbetaling, brudskader og Extra hjælp
        if (parsedAge >= this.seniorStep2) {
            filteredFeatures = filteredFeatures.filter( feature =>
                feature.name !== 'SS1VUF9VUC0yOC00Nw==' &&
                feature.name !== 'SS1VUF9VUC0yOC00NQ==' &&
                feature.name !== 'SS1VUF9VUC0yOC00OA=='
                );
            }

        if (parsedAge > this.seniorStep1/* || parsedAge === 18*/) {
            filteredFeatures = filteredFeatures.filter( feature =>  feature.name !== 'SS1VUF9VUC0yOC01Mw==');
        }

        return filteredFeatures;
    }

    /**
     * load settings from CMS if present
     */
    private loadAccidentSettings() {
       try {
            let setting: string;
            const settingsArr = this.settingsBlock.markup.split('$');
            settingsArr.forEach(setting_ => {
                setting = setting_.toLowerCase().trim();
                if (setting.indexOf('work=') > -1 ) {
                    this.work = this.getSettingsValue(setting_).split('¤');
                } else if (setting.indexOf('workids=') > -1 ) {
                    this.workIds = this.getSettingsValue(setting_).split('¤');
                } else if (setting.indexOf('seniorstep1=') > -1 ) {
                    this.seniorStep1 = parseInt(this.getSettingsValue(setting_));
                } else if (setting.indexOf('seniorstep2=') > -1 ) {
                    this.seniorStep2 = parseInt(this.getSettingsValue(setting_));
                } else if (setting.indexOf('maxcustomerage=') > -1 ) {
                    this.maxCustomerAge = parseInt(this.getSettingsValue(setting_));
                } else if (setting.indexOf('customermaxagepopuptitle=') > -1 ) {
                    this.customerMaxAgePopupTitle = this.getSettingsValue(setting_);
                } else if (setting.indexOf('customermaxagepopupcontent=') > -1 ) {
                    this.customerMaxAgePopupContent = this.getSettingsValue(setting_);
                } else if (setting.indexOf('customermaxagepopupredirect=') > -1 ) {
                    this.customerMaxAgePopupRedirect = this.getSettingsValue(setting_);
                } else if (setting.indexOf('customerothermaxagepopuptitle=') > -1 ) {
                    this.customerOtherMaxAgePopupTitle = this.getSettingsValue(setting_);
                } else if (setting.indexOf('customerothermaxagepopupcontent=') > -1 ) {
                    this.customerOtherMaxAgePopupContent = this.getSettingsValue(setting_);
                } else if (setting.indexOf('customerothermaxagepopupredirect=') > -1 ) {
                    this.customerOtherMaxAgePopupRedirect = this.getSettingsValue(setting_);
                } else if (setting.indexOf('customerotheragepopuptitle=') > -1 ) {
                    this.customerOtherAgePopupTitle = this.getSettingsValue(setting_);
                } else if (setting.indexOf('customerotheragepopupcontent=') > -1 ) {
                    this.customerOtherAgePopupContent = this.getSettingsValue(setting_);
                } else if (setting.indexOf('customerotheragepopupredirect=') > -1 ) {
                    this.customerOtherAgePopupRedirect = this.getSettingsValue(setting_);
                }
            });
        } catch(e) {
            console.error(e);
        }
    }

    public productInfo = {
        "products": {
            "product_id": "SS1VUF9VUC0yOA==",
            "code": "I-UP_UP-28",
            "title": "Ulykkesforsikring",
            "tags": "#private #insurance #up",
            "optional_feature_ids": [
                "SS1VUF9VUC0yOC02Mw==",
                "SS1VUF9VUC0yOC00NQ==",
                "SS1VUF9VUC0yOC00Nw==",
                "SS1VUF9VUC0yOC00OA==",
                "SS1VUF9VUC0yOC0zMA==",
                "SS1VUF9VUC0yOC0xMg==",
                "SS1VUF9VUC0yOC0xNA==",
                "SS1VUF9VUC0yOC0xOA==",
                "SS1VUF9VUC0yOC00Mw==",
                "SS1VUF9VUC0yOC01Mg==",
                "SS1VUF9VUC0yOC02Mg==",
                "SS1VUF9VUC0yOC0xNQ==",
                "SS1VUF9VUC0yOC01Mw==",
                "SS1VUF9VUC0yOC00NA==",
                "SS1VUF9VUC0yOC03"
            ],
            "features": [
                {
                    "feature_id": "SS1VUF9VUC0yOC02Mg==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-62",
                    "tags": "#private #insurance",
                    "title": "Invaliditet heltid",
                    "description": "Dækning hele døgnet - uanset om du er på arbejde eller har fri.",
                    "sub_feature_ids": [
                        "SS1VUF9VUC0yOC0z",
                        "SS1VUF9VUC0yOC02MA==",
                        "SS1VUF9VUC0yOC0y",
                        "SS1VUF9VUC0yOC00Mg=="
                    ],
                    "exclude_risk_num": "63"
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC0xNQ==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-15",
                    "tags": "#private #insurance",
                    "title": "Udvidet motorcykel",
                    "description": "Dækker ulykker, der rammer dig som fører under kørsel på motorcykel."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC0zMA==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-30",
                    "tags": "#private #insurance",
                    "title": "Tyggeskade",
                    "description": "<p>Dækker rimelige og nødvendige udgifter til tandbehandling, hvis din tand går i stykker, mens du spiser. Omfatter også pludselig skade på gebis med op til 20.000 kr.<br></p>"
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC00NA==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-44",
                    "tags": "#private #insurance",
                    "title": "Udvidet luftfartøj",
                    "description": "Dækker ulykker, der rammer dig som fører af et privatfly."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC00Nw==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-47",
                    "tags": "#private #insurance",
                    "title": "Ekstra udbetaling",
                    "description": "Der udbetales ekstra erstatning ved en méngrad på mellem 20-29% og dobbelterstatning ved en méngrad på mere end 29%."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC00OA==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-48",
                    "tags": "#private #insurance",
                    "title": "Ekstra hjælp",
                    "description": "Giver økonomisk kompensation\nved hospitalsindlæggelse i mere end tre dage, dækker udgifter til f.eks.\nrengøringshjælp eller andre daglige gøremål, medicin og transport til\nbehandlingsstedet.",
                    "risk_sum": [
                        "10000"
                    ],
                    "default_risk_sum": "10000"
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC00NQ==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-45",
                    "tags": "#private #insurance",
                    "title": "Brudskader",
                    "description": "Du får udbetalt en erstatning på 1% af invaliditetssummen, dog maksimalt 15.000 kr., hvis du ved en ulykke f.eks. brækker en arm eller et ben. Dækker også ved overrivning af akillessenen eller korsbåndet."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC0xMg==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-12",
                    "tags": "#private #insurance",
                    "title": "Dødsfaldsdækning - fritid",
                    "description": "Hvis en ulykke er direkte årsag til din død, inden der er gået et år efter ulykkesdagen, får de/den begunstigede i policen udbetalt den aftalte dødfaldssum."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC01Mg==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-52",
                    "tags": "#private #insurance",
                    "title": "Kritisk sygdom - børn",
                    "description": "Der udbetales en erstatning, hvis dit barn får konstateret en kritisk sygdom som er omfattet af forsikringen. Se dine betingelser.",
                    "exclude_risk_num": "53",
                    "risk_sum": [
                        "125000"
                    ],
                    "default_risk_sum": "125000"
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC00Mw==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-43",
                    "tags": "#private #insurance",
                    "title": "Farlig sport",
                    "description": "Dækker dig i tilfælde af ulykker, der sker mens du f.eks dyrker faldskærmsudspring, paragliding, bjergbestigning eller lignende."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC0xNA==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-14",
                    "tags": "#private #insurance",
                    "title": "Fritidsjobsdækning",
                    "description": "<p>Dækker barnet i tilfælde af ulykker opstået på fritidsjobbet.</p>"
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC0xOA==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-18",
                    "tags": "#private #insurance",
                    "title": "Motorsport og ATV",
                    "description": "Dækker dig i tilfælde af ulykker under kørsel med gocart og ATV, samt deltagelse eller træning som amatør, i motorløb i bil eller på motorcykel på bane."
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC02Mw==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-63",
                    "tags": "#private #insurance",
                    "title": "Invaliditet fritid",
                    "description": "<p>Dækker i fritiden.</p>",
                    "sub_feature_ids": [
                        "SS1VUF9VUC0yOC02MQ==",
                        "SS1VUF9VUC0yOC0xMA==",
                        "SS1VUF9VUC0yOC02NA==",
                        "SS1VUF9VUC0yOC05"
                    ]
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC01Mw==",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-53",
                    "tags": "#private #insurance",
                    "title": "Kritisk sygdom",
                    "description": "Der udbetales en erstatning, hvis du får konstateret en kritisk sygdom som er omfattet af forsikringen. Se dine betingelser.",
                    "exclude_risk_num": "52",
                    "risk_sum": [
                        "125000"
                    ],
                    "default_risk_sum": "125000"
                },
                {
                    "feature_id": "SS1VUF9VUC0yOC03",
                    "family": "insurance_coverage",
                    "code": "I-UP_UP-28-7",
                    "tags": "#private #insurance",
                    "title": "Dødsfaldsdækning - heltid",
                    "description": "<p>Hvis en ulykke er direkte årsag til din død, inden der er gået et år efter ulykkesdagen, får de/den begunstigede i policen udbetalt den aftalte dødfaldssum.<br></p>",
                    "exclude_risk_num": "12"
                }
            ],
            "sub_features": [
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC02MQ==",
                    "code": "I-UP_UP-28-61",
                    "tags": "#private #insurance",
                    "title": "Sport",
                    "description": "<p>Dækker dig hvis du kommer til skade, mens du f.eks. spiller fodbold eller håndbold, dyrker karate eller cykeltræning, deltager i cykelløb, dykker eller deltager i tilsvarende aktiviteter.</p>"
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC0y",
                    "code": "I-UP_UP-28-2",
                    "tags": "#private #insurance",
                    "title": "Tandskade",
                    "description": "Dækker de udgifter, du har til tandbehandling, som er nødvendige for at opnå samme tandstatus, som du havde før ulykken."
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC00Mg==",
                    "code": "I-UP_UP-28-42",
                    "tags": "#private #insurance",
                    "title": "Sport",
                    "description": "Dækker dig hvis du kommer til skade, mens du f.eks. spiller fodbold eller håndbold, dyrker karate eller cykeltræning, deltager i cykelløb, dykker eller deltager i tilsvarende aktiviteter."
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC0z",
                    "code": "I-UP_UP-28-3",
                    "tags": "#private #insurance",
                    "title": "Fingererstatning",
                    "description": "Dækker med et fast beløb, hvis du får foretaget knogleamputation af hele det yderste led på enten ringfinger eller lillefinger, også selvom dit varige mén er under 5%."
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC0xMA==",
                    "code": "I-UP_UP-28-10",
                    "tags": "#private #insurance",
                    "title": "Fingererstatning",
                    "description": "Dækker med et fast beløb, hvis du får foretaget knogleamputation af hele det yderste led på enten ringfinger eller lillefinger, også selvom dit varige mén er under 5%."
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC05",
                    "code": "I-UP_UP-28-9",
                    "tags": "#private #insurance",
                    "title": "Tandskade",
                    "description": "Dækker de udgifter, du har til tandbehandling, som er nødvendige for at opnå samme tandstatus, som du havde før ulykken."
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC02MA==",
                    "code": "I-UP_UP-28-60",
                    "tags": "#private #insurance",
                    "title": "Behandling og genoptræning",
                    "description": "Dækker behandling og genoptræning hos f.eks. fysioterapeut eller kiropraktor."
                },
                {
                    "sub_feature_id": "SS1VUF9VUC0yOC02NA==",
                    "code": "I-UP_UP-28-64",
                    "tags": "#private #insurance",
                    "title": "Behandling og genoptræning",
                    "description": "<p>Dækker behandling og genoptræning hos f.eks. fysioterapeut eller kiropraktor.</p>"
                }
            ]
        }
    };

    // hardcoded Ulykke
    public oldCalcConfig =
        {
        "insuranceConfiguration": {
            "basePackageId": 18188,
            "coverages": [
            ],
            "excessIdDefault": 70,
            "excessList": [
                {
                    "amount": 3229,
                    "hidden": false,
                    "id": 64,
                    "minCustomerAge": 0,
                    "useAsDefault": false
                },
                {
                    "amount": 6457,
                    "hidden": false,
                    "id": 70,
                    "minCustomerAge": 0,
                    "useAsDefault": true
                }
            ],
            "packageIds": [
                18184,
                18186,
                18188
            ],
            "packages": [
                {
                    "id": 18188,
                    "name": PACKAGES.LILLE,
                },
                {
                    "id": 18186,
                    "name": PACKAGES.MELLEM,
                },
                {
                    "id": 18184,
                    "name": PACKAGES.STOR,
                }
            ],
        },
        textMappingsOuter: {
            "ui.coverage.mappings": "Dødsfaldsdækning - heltid=Dødsfaldsdækning",
            "ui.coverage.description.mappings":
                "Dødsfaldsdækning - heltid=Hvis en ulykke er direkte årsag til din død, inden der er gået et år efter ulykkesdagen, får de/den begunstigede i policen udbetalt den aftalte dødfaldssum.<br>Forsikringssum 50.000 kr.¤Invaliditet heltid=Dækning hele døgnet - uanset om du er på arbejde eller har fri.<br>Forsikringssum op til 1 mill. kr."
        }
    };

}
