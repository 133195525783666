
import { mapState } from 'vuex';
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
import { CardBlock, UsabillaInPageBlock } from '@/cms/definitions/content-types';
import { CarSettings } from '../../car/CarSettings';
// import { AccidentSettings } from '../../accident/AccidentSettings';
// import { HomeSettings } from '../../home/HomeSettings';
// import { HouseSettings } from '../../house/HouseSettings';
import { DogSettings } from '../../dog/DogSettings';
import { TravelSettings } from '../../travel/TravelSettings';
import { CustomerCalculation, RESET_CALCULATORS } from '@/store/modules/calculatorContext';
import { EnvironmentService } from '@/services/environmentService';
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import { PropType } from 'vue';

@Options({
    name: 'ReceiptStepComponent',
    components: {
        UsabillaInPageComponent,
    },
    computed: mapState<any>({
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
    props: {
        card: Object,
        usabillaBlock: Object as PropType<UsabillaInPageBlock>
    }
})

export default class ReceiptStepComponent extends Vue {
    card: CardBlock; // ReceiptCard from CMS
    usabillaBlock: UsabillaInPageBlock; // usabillaBlock from CMS

    public cms!: DogSettings | TravelSettings | CarSettings /*| AccidentSettings | HomeSettings | HouseSettings*/; // settings from CMS (store)

    public link: string = null;
    public linkTxt: string = null;
    public componentInit: boolean = false;

    public mounted() {
        this.setLink();
        store.dispatch(RESET_CALCULATORS);
        setTimeout(() => {
            this.componentInit = true;    
        }, 500);
        
    }

    private setLink() {
        const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;

        if (customerCalculation.hasCustomerData) {
            if (customerCalculation.useLogout) {
                this.link = '/logout';
                this.linkTxt = 'Log ud';
            } else {
                if (EnvironmentService.isApp()) {
                    this.link = '/mitalmbrand?client=app#/forsikring'
                } else {
                    this.link = '/mitalmbrand/forsikring';
                }
                this.linkTxt = 'Gå til forsikringer';
            }
        } else {
            this.link = '/'; // default - almbrand.dk
            this.linkTxt = this.card.linkText;
        }
    }
}
