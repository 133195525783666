import { mapState } from 'vuex';
import FieldValidator from '@/services/fieldValidatorService';
import { HouseSettings } from '../../house/HouseSettings';
import { Validator } from '../../BuyInsuranceHelper';
import ExistingAbProductsComponent from '../../components/ExistingABProductsComponent.vue'
import { nextTick, PropType } from 'vue';
import { CardBlock } from '@/cms/definitions/content-types';
import { CustomerCalculation } from '@/store/modules/calculatorContext';
import store from '@/store/store';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'PersonInfoStepComponent',
    props: {
        card: Object as PropType<CardBlock>, // Card from cms
        isValid: Boolean, // wether card is complete
    },
    components: {
        ExistingAbProductsComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
})

export default class PersonInfoStepComponent extends Vue {
    card: CardBlock; // Card from cms
    isValid: boolean; // wether card is complete

    public model!: any; // datastructure for user input (store)
    public cms!: HouseSettings; // settings from CMS (store)


    public fieldValidator: FieldValidator = Validator;
    public defaultAddress: string = null;
    public readonlyAge: string = null;
    public readonlyAddress: string = null;

    public created () {
        const addressParam = this.$route.query.address as string;
        if (addressParam) {
            this.defaultAddress = addressParam;
        }
        const customerAgeParam = this.$route.query.customerAge as string;
        if (customerAgeParam) {
            this.model.personInfo.customerAge = customerAgeParam;
        }

        const almbrandCustomerParam = this.$route.query.almbrandCustomer as string;
        if (almbrandCustomerParam) {
            this.model.personInfo.almbrandCustomer = almbrandCustomerParam;
        }

        const existingAlmBrandProductsParam = this.$route.query.existingAlmBrandProducts as Array<string>;
        if (existingAlmBrandProductsParam) {
            this.model.personInfo.existingAlmBrandProducts = existingAlmBrandProductsParam;
        }
    }

    public mounted() {
        const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
        if (!this.model.personInfo.address || customerCalculation.hasCustomerData ) {
            // wait for transition to begin showing zipcode field
            setTimeout(() => {
                const input = (document.querySelector('#person_info .autocomplete-input') as HTMLInputElement);
                if (input) {
                    input.focus();
                }
            }, this.cms.animationTimeout  + 500);
        }

        if (this.model.multipleProducts) {
            const commonModel = store.getters.getCalculatorCommonModel;
            if (commonModel.personInfo.address && this.model.productName === InsurancesProductEnum.HUS_FORSIKRING) {
                this.readonlyAddress = this.model.personInfo.address;
            }
            if (this.fieldValidator.isValidAge(commonModel.personInfo.customerAge)) {
                this.readonlyAge = this.model.personInfo.customerAge;
            }
        }
        window.addEventListener('keyup' , this.moveOn );
    }

    public beforeUnmount() {
        window.removeEventListener('keyup', this.moveOn);
    }

    public moveOn(evt) {
        if (evt.keyCode === 13 && this.isValid) {
            this.nextStep();
        }
    }

    public nextStep() {
        this.$emit('nextStep', this.card.routePath);
    }

    public addressSearch(address) {
        if (address.matchText) {
            this.model.personInfo.address = address.matchText;
            this.model.personInfo.zipCode = address.data.postnr;
            this.model.personInfo.zipName = address.data.postnrnavn;
            this.model.personInfo.streetName = address.data.vejnavn;
            this.model.personInfo.houseNr = address.data.husnr;
            this.model.personInfo.floorNr = address.data.etage;
            this.model.personInfo.doorNr = address.data.dør;
            this.model.personInfo.kvhxValue = address.data.kvhx;
            this.model.pristineStep.set(this.card.name, true);
            nextTick( () => {
                // wait for transition to begin showing customerAge field
                if(!this.model.personInfo.customerAge) {
                    setTimeout(() => {
                        const input = document.getElementById('customerAge');
                        if (input) {
                            input.focus();
                        }
                    }, 300);
                } else {
                    setTimeout(() => {
                        (document.activeElement as any).blur();
                    }, 300);
                }
            });

            return;
        }

        this.model.personInfo.address = undefined;
        this.model.personInfo.zipCode = undefined;
        this.model.personInfo.zipName = undefined;
        this.model.personInfo.streetName = undefined;
        this.model.personInfo.houseNr = undefined;
        this.model.personInfo.floorNr = undefined;
        this.model.personInfo.doorNr = undefined;
        this.model.personInfo.kvhxValue = undefined;
    }
}
