import { createStore } from 'vuex'

import Actions from './actions';
import Getters from './getters';
import Mutations from './mutations';
import { EpiContentState } from '@/store/modules/epiContent';
import { EpiContextState } from '@/store/modules/epiContext';
import epiContent from '@/store/modules/epiContent';
import epiContext from '@/store/modules/epiContext';
import ProposalContext, { ProposalContextState } from '@/store/modules/ProposalContext';
import selectableContext, { SelectableContextState } from '@/store/modules/selectableContext';
import authenticationContext, { AuthenticationState } from '@/store/modules/authenticationContext';
import calculatorContext, { CalculatorContextState } from "@/store/modules/calculatorContext";


export type CmsRootState = {
  isTouchDevice: boolean,
  epiRouting: [],
  usabilla: { mounted: number, count: number},
  // modalState: boolean,
  modal: {id: string, show: boolean}
  epiContent?: EpiContentState,
  epiContext?: EpiContextState,
  // appReplaceRoute: boolean,
  ProposalContext?: ProposalContextState,
  selectableContext?: SelectableContextState,
  authenticationContext?: AuthenticationState;
  calculatorContext?: CalculatorContextState,
  recaptchaSiteKey: string,
  showSpinner: boolean,
  openServiceUrl: string,
  openServiceCalculatorUrl: string,
  // mailOSSUrl: string,
  mailOSSNoLogin: string,
  teamMailOSSNoLogin: string,
  // mailTeamUrl: string,
}

const rootState = {
  isTouchDevice: false,
  epiRouting: [],
  usabilla: { count: 0, mounted: 0},
  // modalState: false,
  modal: {id: 'none', show: false},
  recaptchaSiteKey: undefined,
  showSpinner: false,
  openServiceUrl: '/api/v1/openserviceproxy',
  // openServiceCalculatorUrl: '/api/naboo/v1/openserviceproxy',
  openServiceCalculatorUrl: '/api/aibo/v1/openserviceproxy',
  // mailOSSUrl: '/api/naboo/v1/checkoutmail/SendOssMail',
  // mailOSSNoLogin: '/api/naboo/v1/checkoutmail/sendossnotloggedin',
  // teamMailOSSNoLogin: '/api/naboo/v1/checkoutmail/sendteamnotloggedin',
  mailOSSNoLogin: '/api/aibo/v1/checkoutmail/sendossnotloggedin',
  teamMailOSSNoLogin: '/api/aibo/v1/checkoutmail/sendteamnotloggedin',
  // mailTeamUrl: '/api/naboo/v1/checkoutmail/sendTeamMail',
    
} as CmsRootState;

export default createStore<CmsRootState>({
  state: rootState,
  mutations: Mutations,
  actions: Actions,
  getters: Getters,
  modules: {
    epiContent,
    epiContext,
    selectableContext,
    ProposalContext,
    authenticationContext,
    calculatorContext
  }
})

