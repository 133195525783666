import { Options, Vue } from 'vue-class-component';
import UrlUtilService from "@/services/urlUtilService";
import UrlHandler from '@/views/calculators/UrlHandler';
import store from '@/store/store';
import { nextTick } from 'vue';
import { BuyInsuranceHelper } from '../BuyInsuranceHelper';
@Options({
    name: 'SelectCalculatorPageComponent',
    components: {
    }

})
export default class SelectCalculatorPageComponent extends Vue {
    // KLK hack, sometimes the url isn't resolved correctly and basket/product not loaded
    private delay = 25;
    private hasRedirected = false;

    async beforeCreate() {
        setTimeout(() => {
            this.resolveUrl()
        }, this.delay); 
        // klk hack , sometimes page doesn't load!!
        setTimeout(() => {
            if (!this.hasRedirected) {
                console.warn('SelectCalculatorPageComponent has not redirected - trying again');
                this.resolveUrl();
            }
            
        }, this.delay * 200);  // 5 seconds

    }

    private async resolveUrl() {
        // got specific product
        if( this.$route.query.product ) {
            const productName = this.$route.query.product as string; 
            const query = this.$route.query;
            
            //await store.dispatch('setAppReplaceRoute', true);
            this.$router.replace({
                path: productName,
                force: true,
                query
                // query: this.$route.query
            });
            this.hasRedirected = true;
            return;
        }
        // check if there is any products defined by queryParams, and put them in the basket
        if( this.$route.query.products ) {
            // console.log('this.$route.query.products', this.$route.query.products);

            await UrlHandler.handlePreselectedProducts(this.$route.query);
            // KLK hack delay
            await new Promise((res) => setTimeout(res, this.delay));
        }
        const insurance = UrlHandler.gotoFirstProductInBasket();
        // console.log('Url resolved to', insurance);

        let url = UrlUtilService.ensureCorrectLink(insurance);
        let query = '';
        try {
            const inx = this.$route.fullPath.indexOf('?');
            if (inx > -1) {
                query = this.$route.fullPath.substr(inx, this.$route.fullPath.length);
                url += query;
            }
            console.log('url', url);
            

        } catch (error) {
            console.error(error);
            // too bad
        }
        // await store.dispatch('setAppReplaceRoute', true);
        this.hasRedirected = true;
        // this.$router.replace(url);
        this.$router.replace(url);
    }
}
