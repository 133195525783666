import { Log } from "@/appinsights/logging/log";
import store from "@/store/store";

export default class UrlUtilService {
    private static instance = new UrlUtilService(window.location);
    private _baseUrlPath : string;
    private retryRecaptcha = true;

    private constructor(readonly location: Location) {
        this._baseUrlPath = this.getBasePath();
    }
    
    public static get baseUrlPath(): string {
        return this.instance._baseUrlPath;
    }

    public static urlPathIndex(index: number) {
        const url = window.location.pathname;
        const arr = url.split("/");
        return arr[index];
    }
    
    private getRootPath() {
        const indexOfSlash = this.location.pathname.substr(1).indexOf("/");
    
        return location.pathname.substr(0, indexOfSlash + 1);
    }

    private getBasePath() {
        if(this.location.hash?.length > 0) {
            //when there is an # in the url we assume it is running the old paths
            return '';
        }
        return this.getRootPath();
    }


    public static ensureCorrectBasePath(url: string) {
        //check if the url is absolute/external
        if (url.startsWith('http://') || url.startsWith('https://')) {
            return url;
        }

        if (url.startsWith(this.baseUrlPath)) {
            return url;
        }

        if(url.startsWith('/')) {
            return this.baseUrlPath + url;
        }

        return this.baseUrlPath + "/" + url;
    }

    public static ensureCorrectLink(url: string) {
        if (this.baseUrlPath === '') {
            if (url.startsWith("/")) {
                return url.replace(this.instance.location.pathname, '');
            }
            
        }
        const correctUrl = this.ensureCorrectBasePath(url);
        return correctUrl;
    }

    public static isDevelop() {
        return (location.href.startsWith('https://localhost') || 
                    location.href.startsWith('https://alpha') || 
                    location.href.startsWith('https://ab-fnol.azurewebsites.net/'));
    }

    
    public static async getReCaptchaToken() {
        try {
            if ((window as any).grecaptcha?.execute) {
                const token = await (window as any).grecaptcha.execute(store.getters.getRecaptchaSiteKey, { action: 'online_sale' });
                return token;
            } else {
                throw('no grecaptcha');
            }
        } catch(e) {
            console.error(e);
            if (this.instance.retryRecaptcha) {
                this.instance.retryRecaptcha = false;
                Log.warning('try fetching ReCaptcha after delay!!!!');
                return await new Promise( () => setTimeout(this.getReCaptchaToken, 200));
            }
        }
    }

    public static isExternalLink(routePath): boolean {
        return routePath && (routePath.startsWith('//') || routePath.toLocaleLowerCase().startsWith('https'));
    }
}
