import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { key: "vaccine" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: "healthy" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: "beenUnhealthy" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: "vet" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioComponent = _resolveComponent("RadioComponent")!
  const _component_ValidationErrorComponent = _resolveComponent("ValidationErrorComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_TransitionGroup, { name: "expand-list" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "pb-3 text-left",
            innerHTML: _ctx.model.dogInfo.dogCount === 2 ? _ctx.cms.questionVaccineMultiTxt : _ctx.cms.questionVaccineTxt
          }, null, 8, _hoisted_2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jaNejArray, (item, inx) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass([(inx + 1) === _ctx.jaNejArray.length ? 'pb-3' : 'pb-2']),
              key: 'vaccine' + inx
            }, [
              _createVNode(_component_RadioComponent, {
                name: "vaccine",
                id: 'vaccine' + item.value,
                inputValue: item.value,
                preSelect: _ctx.model.dogInfo.vaccine,
                onRadioComponent: _ctx.handleRadioClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.displayValue), 1)
                ]),
                _: 2
              }, 1032, ["id", "inputValue", "preSelect", "onRadioComponent"])
            ], 2))
          }), 128)),
          (!_ctx.model.pristineStep.get(_ctx.card.name) && !_ctx.model.dogInfo.vaccine)
            ? (_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                key: 0,
                class: "input-error--show input-error__radio mt-n3",
                text: "Vælg Ja eller Nej"
              }))
            : _createCommentVNode("", true)
        ]),
        (_ctx.model.dogInfo.vaccine === 'ja')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", {
                class: "pb-3 text-left",
                innerHTML: _ctx.model.dogInfo.dogCount === 2 ? _ctx.cms.questionHealthyMultiTxt : _ctx.cms.questionHealthyTxt
              }, null, 8, _hoisted_4),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jaNejArray, (item, inx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass([(inx + 1) === _ctx.jaNejArray.length ? 'pb-3' : 'pb-2']),
                  key: 'healthy' + inx
                }, [
                  _createVNode(_component_RadioComponent, {
                    name: "healthy",
                    id: 'healthy' + item.value,
                    inputValue: item.value,
                    preSelect: _ctx.model.dogInfo.healthy,
                    onRadioComponent: _ctx.handleRadioClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.displayValue), 1)
                    ]),
                    _: 2
                  }, 1032, ["id", "inputValue", "preSelect", "onRadioComponent"])
                ], 2))
              }), 128)),
              (!_ctx.model.pristineStep.get(_ctx.card.name) && !_ctx.model.dogInfo.vaccine && !_ctx.model.dogInfo.healthy)
                ? (_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                    key: 0,
                    class: "input-error--show input-error__radio mt-n3",
                    text: "Vælg Ja eller Nej"
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.model.dogInfo.vaccine === 'ja' && _ctx.model.dogInfo.healthy === 'ja')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "pb-3 text-left",
                innerHTML: _ctx.model.dogInfo.dogCount === 2 ? _ctx.cms.questionBeenUnhealthyMultiTxt : _ctx.cms.questionBeenUnhealthyTxt
              }, null, 8, _hoisted_6),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jaNejArray, (item, inx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass([(inx + 1) === _ctx.jaNejArray.length ? 'pb-3' : 'pb-2']),
                  key: 'beenUnhealthy' + inx
                }, [
                  _createVNode(_component_RadioComponent, {
                    name: "beenUnhealthy",
                    id: 'beenUnhealthy' + item.value,
                    inputValue: item.value,
                    preSelect: _ctx.model.dogInfo.beenUnhealthy,
                    onRadioComponent: _ctx.handleRadioClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.displayValue), 1)
                    ]),
                    _: 2
                  }, 1032, ["id", "inputValue", "preSelect", "onRadioComponent"])
                ], 2))
              }), 128)),
              (!_ctx.model.pristineStep.get(_ctx.card.name) && !_ctx.model.dogInfo.vaccine && !_ctx.model.dogInfo.healthy && !_ctx.model.dogInfo.beenUnhealthy)
                ? (_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                    key: 0,
                    class: "input-error--show input-error__radio mt-n3",
                    text: "Vælg Ja eller Nej"
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.model.dogInfo.vaccine === 'ja' && _ctx.model.dogInfo.healthy === 'ja' && _ctx.model.dogInfo.beenUnhealthy === 'nej')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", {
                class: "pb-3 text-left",
                innerHTML: _ctx.model.dogInfo.dogCount === 2 ? _ctx.cms.questionVetMultiTxt : _ctx.cms.questionVetTxt
              }, null, 8, _hoisted_8),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jaNejArray, (item, inx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass([(inx + 1) === _ctx.jaNejArray.length ? 'pb-3' : 'pb-2']),
                  key: 'vet' + inx
                }, [
                  _createVNode(_component_RadioComponent, {
                    name: "vet",
                    id: 'vet' + item.value,
                    inputValue: item.value,
                    preSelect: _ctx.model.dogInfo.vet,
                    onRadioComponent: _ctx.handleRadioClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.displayValue), 1)
                    ]),
                    _: 2
                  }, 1032, ["id", "inputValue", "preSelect", "onRadioComponent"])
                ], 2))
              }), 128)),
              (!_ctx.model.pristineStep.get(_ctx.card.name) && !_ctx.model.dogInfo.vaccine && !_ctx.model.dogInfo.healthy && !_ctx.model.dogInfo.beenUnhealthy && !_ctx.model.dogInfo.vet)
                ? (_openBlock(), _createBlock(_component_ValidationErrorComponent, {
                    key: 0,
                    class: "input-error--show input-error__radio mt-n3",
                    text: "Vælg Ja eller Nej"
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}