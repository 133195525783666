// https://www.npmjs.com/package/vue3-autocomplete
import Autocomplete from 'vue3-autocomplete';
// import 'vue3-autocomplete/dist/vue3-autocomplete.css'
import { Options, Vue } from "vue-class-component";

@Options({
    name: "SearchComponent",
    components: {
    },
    props: {
        autocompleteId: {type: String, default: '_'+(Math.random())},
        defaultValue: {type: String, default: undefined},
        label: String,
        resultList: Array,
        placeholder: {type: String,default: ''},
        searchByCleanedInput: {type: Boolean,default: false},
        isValid: {type: Boolean,default: false},
        readonlyValue: {type: String, default: null},
    }
})

export default class SearchComponent extends Vue {
    autocompleteId: string;
    defaultValue?: string; // if model has value
    label: string;
    resultList: Array<string>;
    results: Array<string> = [];
    placeholder?: string;
    searchByCleanedInput?: boolean; // replace non chars in searchstring with space
    isValid?: boolean; // valid searchresult
    readonlyValue?: string;

    private localResultList: Array<string> = []; // local lowercase copy of resultList
    private doBlur = true;
    private autocomplete: Autocomplete;
    public searching = false;
    public dirty = true;

    public mounted() {
        // this.autocomplete = this.$refs.resultList as Autocomplete;
        this.resultList.forEach( (res) => {
            if (this.searchByCleanedInput) {
                this.localResultList.push(res.toLocaleLowerCase().replace(/[\W_]+/g," ")); // replace all non chars with space
            } else {
                this.localResultList.push(res.toLocaleLowerCase());
            }
        });
    }

    public setAutoCompleteField(input) {
        this.autocomplete = input;
        if (this.defaultValue) {
            this.autocomplete.setText(this.defaultValue);
            this.dirty = false;
        }
    }

    public displayItem(item) {
        return item.matchText;
    }

    public async search(input): Promise<any> {
        this.searching = true;
        this.doBlur = true;
        this.dirty = true;
        // this.lastResult = undefined;
        if (input.length < 2) {
            this.results = [];
            this.searching = false;
            return;
            // return Promise.resolve([]);
        }
        const response = await this.getlocalResultList(input);
        if (response.status !== 200) {
            this.$emit('searcherror');
            this.results = [];
            this.searching = false;
            return;
        }
        if (response.data.matches.length > 0) {
            //this.lastResult = response.data.matches[0];
            this.results = response.data.matches;
            // this.showList = [];
            // response.data.matches.forEach(element => {
            //     this.showList.push(element.matchText);
            // });

        }
        //  else {
        //     this.showList = [];
        //     return;
        // }
        this.searching = false;
        // console.log('showList', this.results);
        
        // UI DEBUG
        // setTimeout(() => {
        //     if (this.results && this.results.length > 2) {
        //         const container = document.querySelector(".vue3-results-container");
        //         const clone = container.cloneNode(true);
        //         document.querySelector(".vue3-autocomplete-container").appendChild(clone);                
        //     }
        // }, 1000);
    }
    public testHtml = null;

    private async getlocalResultList(input: string): Promise<any> {
        let inputLower = input.toLocaleLowerCase()
        const result: any = {
            status: 200,
            data: {
                matches: []
            },
        };
        if (this.searchByCleanedInput) {
            inputLower = inputLower.replace(/[\W_]+/g," "); // replace all non chars with space
        }

        this.localResultList.forEach( (elem, inx) => {
            if (elem.indexOf(inputLower) >= 0) {
                result.data.matches.push( {
                    matchText: this.resultList[inx],
                });
            }
        });
        return Promise.resolve(result);
    }

    public blur() {

        if (this.doBlur && this.results.length > 0) {
            if (this.autocomplete.searchText !== this.results[0]) {
                this.handleSubmit(this.results[0]);
            }
        }
        this.doBlur = false;

        // if (!this.input.searchText) {
        //     // clear search
        //     this.$emit('search', {
        //         matchText: undefined,
        //     });
        //     return;
        // }

        // if (!this.lastResult.submitted) {
        //     setTimeout(() => {
        //         this.lastResult.matchText = this.input.value;
        //         this.handleSubmit(this.lastResult);
        //     }, 200);
        // }
    }

    public handleSubmit(result) {
        // console.log('handleSubmit', result);
        this.autocomplete.setText(result.matchText);
        //this.searching = false;
        // find result in original case
        //this.lastResult.submitted = true;
        this.$emit('search', {
            matchText: result.matchText,
            id: this.autocompleteId
        });
        this.doBlur = false;
        this.dirty = false;
        
        // nextTick( () => {
        //     this.input.value = result;    
        // })
    }
    public async onFocus(evt) {
        this.dirty = true;
        // console.log('onfocus', this.dirty);
        //this.searching = true;
        this.$emit('searchfocus', evt);
        await this.search(this.autocomplete.searchText);

    }
}
