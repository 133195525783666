
import CoverageComponent from "../coverageComponent/coverageComponent.vue";
import CoverageAdapter from '@/views/calculators/model_legacy/CoverageAdapter';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'packageBlockComponent',
    components: {
        CoverageComponent,
    }, props: {
        coverages: Array,
        track: {type: Boolean, default: true },
        trackGtm:{type: Boolean, default: false },  
        trackGtmLabel: String,
    }
})
export default class PackageBlockComponent extends Vue {
    coverages: Array<CoverageAdapter>;
    track: boolean; // track using css classes
    trackGtm: boolean;// track using gtm attributes
    trackGtmLabel?: string;// optional label for gtm attributes
}