import { Options, Vue } from "vue-class-component";
import ModalComponent from '@/sharedcomponents/baseComponents/ModalComponent/ModalComponent.vue';
import UtilService from '@/services/utilService';
import AppService from "@/services/appService";


@Options({
    name: 'CookieFooter',
    components: {
        ModalComponent
    }
})
export default class CookieFooter extends Vue {
    public get cookieInformation() {
        return this.$root['cookieInformation'];
    }

    public get isThemed() {
        return UtilService.isThemed;
    }

    public isLoggedInAsApp() {
        return AppService.isEnabled();
      }
    
}
