import { mapState } from 'vuex';
import GtmService from "@/services/gtmService";
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import { nextTick } from 'vue';
import { DogSettings } from '../dog/DogSettings';
import { BasketSettings } from '../basket/BasketSettings';
import { BuyInsuranceHelper } from '../BuyInsuranceHelper';

@Options({
    name: 'ContactComponent',
    components: {
    },
    computed: mapState<any>({
        productModel: state => state.calculatorContext[state.calculatorContext.active].model,
        productCms: state => state.calculatorContext[state.calculatorContext.active].cms,
        basketModel: state => state.calculatorContext.basket.model,
        basketCms: state => state.calculatorContext.basket.cms,
    }),
    props: {
        name: String,
        useBasketModel: {type: Boolean, default: false},
    }
})

export default class ContactComponent extends Vue {
    name: string; // name for tracking
    useBasketModel?: boolean;
    
    public productModel!: any; // datastructure for user input (store)
    public basketModel!: any; // datastructure for basket
    public productCms!: any; // datastructure for user input (store)
    public basketCms!: any; // datastructure for basket


    public cms: DogSettings /*| TravelSettings*/ | BasketSettings; // settings from CMS (store; // settings from CMS (store)
    public model: any = null;
    
    public showCall: boolean = true;
    public showWrite: boolean = true;
    public showOffer: boolean = true;

    public created() {
        this.model = this.useBasketModel ? this.basketModel : this.productModel;
        this.cms = this.useBasketModel ? this.basketCms : this.productCms;
    }
    public mounted() {
        // this.showCall = this.cms.contactCanCall;
        this.handleShowCall();
        this.showWrite = this.cms.contactCanWrite;
        this.showOffer = this.cms.contactCompleteOffer;
    }

    private handleShowCall() {
        if (this.cms.contactCanCall) {
            this.showCall = BuyInsuranceHelper.isOpenHours(this.cms.openForCall, this.cms.closingDaysForCall);
            this.showCall = true;
            return;
        }
        this.showCall = true;
    }

    public openModal(type: string) {
        const modal = this.fillModal(type);
        BuyInsuranceHelper.togglePopup(this.cms, modal);
        setTimeout(() => {
            const elem = (document.querySelector('div.button-container [data-mainbtn]') as HTMLElement);
            if (elem) {
                elem.focus();
            }
        }, 500);
        this.trackcontact();
    }

    private fillModal(type: string): any {
        const modal = this.prepareModal();
        switch (type) { 
            case 'phone' : 
                    modal.btnType = 'tel';
                    modal.id = 'contact_popup';
                    modal.show = true;
                    return modal;
            case 'mail' : 
                    modal.btnType = 'mailto';
                    modal.btnLabel = this.cms.btnLabelEmail;
                    modal.btnAction = this.cms.btnActionEmail;
                    modal.id = 'contact_popup';
                    modal.show = true;
                    return modal;
            default: return modal;
        }
    }

    private prepareModal() {
        return {
            icon: this.cms.btnIcon,
            show: false,
            id: 'none',
            track: false,
            trackToken: undefined,
            content: this.showCall ? this.cms.contactText : this.cms.contactTextNoCall,
            btnType: undefined,
            btnStyle: this.cms.btnStyle,
            btnLabel: this.cms.btnLabel,
            btnIcon: this.cms.btnIcon,
            btnAction: this.cms.btnAction,
            btnSecondStyle: this.cms.btnSecondStyle,
            btnSecondLabel: 'OK',//this.cms.btnSecondLabel,
            title: this.cms.contactTitle,
            redirect: undefined,
        }
    }


    private trackcontact() {
        GtmService.triggerCustomGtmEvent({
            'event': 'track-vp',
            //'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.name}/contact`
            'virtualPath': `TODO klk Track`
        });
    }

}
