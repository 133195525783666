import { Options, Vue } from "vue-class-component";
import AppService from "@/services/appService";

@Options({
    name: "pageTitleComponet",
    props: {
        preTitle: String,
        mainTitle: String,
        dontSendMainTitleToApp: Boolean,
        showTitleInApp: Boolean
    }
})
export default class PageTitleComponet extends Vue {
    preTitle: string;
    mainTitle: string;
    dontSendMainTitleToApp: boolean;
    showTitleInApp: boolean;
    
    isWeb: boolean;

    public created() {
        if (!this.dontSendMainTitleToApp) {
            AppService.sendMessage({ MAIN_TITLE: this.mainTitle });
        }

        this.isWeb = !AppService.isEnabled();
    }

    public get showTitle() {
        return (this.isWeb || this.showTitleInApp) && (this.preTitle || this.mainTitle);
    }
}
