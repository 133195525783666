import { mapState } from "vuex";
import { ReceiptPage } from "@/cms/definitions/content-types";
import ReceiptComponent from "@/views/Proposition/ReceiptComponent.vue";
import UtilService from '@/services/utilService';
import { Options, Vue } from "vue-class-component";
import {PropType} from 'vue'

@Options({
    name: 'ReceiptPageComponent',
    components: {
        ReceiptComponent
    },
    computed: mapState<any>({
        isInEditMode: state => state.epiContext.inEditMode
    }),
    props: {
        model: Object as PropType<ReceiptPage>
    },
})

export default class ReceiptPageComponent extends Vue {
    model: ReceiptPage;

    beforeMount() {
        UtilService.setPageMetaData(this.model);
    }

}
