import { HeaderCardBlock, HtmlBlock, BasketPage } from "@/cms/definitions/content-types";
import { UPDATE_BASKET } from "@/store/modules/calculatorContext";
import { BuyInsuranceHelper } from "@/views/calculators/BuyInsuranceHelper";
import { BasketSettings } from "@/views/calculators/basket/BasketSettings";
import { Model } from "@/views/calculators/basket/Model";
// import LayoutPrivate from "@/sharedcomponents/pageComponents/";
import PageTitle from "@/sharedcomponents/pageComponents/pageTitle/PageTitleComponent.vue";
import ContentArea from "@/cms/components/infrastructure/contentArea/ContentArea.vue";
import  AppService from "@/services/appService";
import { cloneDeep } from "lodash";
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";
import store from "@/store/store";
import UrlHandler from "@/views/calculators/UrlHandler";
import BasketWidgetComponent from "@/views/calculators/components/BasketWidgetComponent.vue";
import UtilService from "@/services/utilService";
import GtmService from "@/services/gtmService";

@Options({
    name: 'BasketPageComponent',
    components: {
        ContentArea,
        PageTitle,
        // LayoutPrivate,
        BasketWidgetComponent,
    },
    computed: mapState<any>({
        isInEditMode: state => state.epiContext.inEditMode
    }),
    props: {
        model: Object as PropType<BasketPage>
    },
})

export default class BasketPageComponent extends Vue {
    public model!: BasketPage;
    public cmsInit = false;
    public preTitle = '';
    public mainTitle = '';
    public headerBadgeTxt: string = null;

    // LIFECYCLE
    beforeMount() {
        UtilService.setPageMetaData(this.model);
        UrlHandler.handlePreselectedProducts(this.$route.query);
        this.handleBasket().then(() => this.handleTitles());
        this.handleTracking();
    }

    // COMPUTED
    get showLogo(): boolean {
        return !UrlHandler.path.includes('mitalmbrand/');
    }

    get isLoggedInApp(): boolean {
        return AppService.isEnabled();
    }
    get hideMenus() {
        if (this.model.disableAuthenticationCheck 
            || this.model.hideMenus
            || !UrlHandler.path.includes('mitalmbrand/')) {
            return true;
        }

        return false;
    }

    public get homeUrl(): string {
        if (this.hideMenus) {
            // goto Almbrand.dk
            return 'https://www.almbrand.dk';
        }
        return '/';
    }

   // METHODS
    private async handleBasket() {

        const settingsBlock = { markup: '' } as HtmlBlock

        this.model.mainArea.forEach(block => {
            if (block.contentType === 'HtmlBlock') {
                if (block.name.toLocaleLowerCase() === 'global settings') {
                    // global settings first
                    settingsBlock.markup = (block as HtmlBlock).markup + settingsBlock.markup
                } else {
                    settingsBlock.markup += (block as HtmlBlock).markup;
                }
            } else if (block.contentType === 'HeaderCardBlock' ) {
                const headerBlock = block as HeaderCardBlock;
                const text = headerBlock.text && headerBlock.text.trim() === '' ? '' : headerBlock.text;
                const hasText = text !== '';
                const hasCss = headerBlock.css && headerBlock.css?.trim() !== '';
                
                if (hasText) {
                    this.headerBadgeTxt = this.headerBadgeTxt ? this.headerBadgeTxt + text : text
                }
                if (hasCss) {
                    this.headerBadgeTxt = this.headerBadgeTxt ? this.headerBadgeTxt + headerBlock.css : headerBlock.css
                }
                if (headerBlock.script) {
                    const s = document.createElement('script');
                    s.type = 'text/javascript';
                    try {
                      s.appendChild(document.createTextNode(headerBlock.script));
                      document.body.appendChild(s);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        });

        if (store.getters.getBasket) {
            this.cmsInit=true;
            return;
        }

        const cms = new BasketSettings(settingsBlock);

        // this is first load - update store
        await store.dispatch(UPDATE_BASKET, {cms, model: cloneDeep(Model.model)});
        const model = store.getters.getBasket.model;
        // check for contact_information
        const basket = store.getters.getSelectableGroup('basket');
        basket.forEach( (product) => {
            const calc = store.getters.getCalculator(product);
            if (calc && calc.model?.contact_information?.customerNo) {
                model.contact_information.customerNo = calc.model.contact_information.customerNo;
                if(calc.model.contact_information.name) {
                    model.contact_information.name = calc.model.contact_information.name;
                }
                if (calc.model.contact_information.phone) {
                    model.contact_information.phone = calc.model.contact_information.phone;
                }
                if (calc.model.contact_information.email) {
                    model.contact_information.email = calc.model.contact_information.email;
                }
            }
        });
        const rebate = BuyInsuranceHelper.getDiscount(store, cms);
        model.calculation.discount = rebate.discount;
        model.calculation.discountDisplay = rebate.discountDisplay;
        this.cmsInit = true;
    }

    private handleTitles() {
        if (this.cmsInit && screen.width < 768) {
            this.model.mainTitle = store.getters.getBasket.cms.shortMainTitleChooseInsurance;
            this.model.preTitle = store.getters.getBasket.cms.shortTitleChooseInsurance;
        }
    }

    public handleTracking() {
        try {
            const query = this.$route.query;
            const source = query.utm_source as string
            if (source) {
                GtmService.triggerCustomGtmEvent({
                    'event': 'track-vp',
                    'virtualPath': `${UrlHandler.getTrackingPreUrl()}kurv/utm_source/${source}`
                });
            }
            const medium = query.utm_medium as string
            if (medium) {
                GtmService.triggerCustomGtmEvent({
                    'event': 'track-vp',
                    'virtualPath': `${UrlHandler.getTrackingPreUrl()}kurv/utm_medium/${medium}`
                });
            }
            const content = query.utm_content as string
            if (content) {
                GtmService.triggerCustomGtmEvent({
                    'event': 'track-vp',
                    'virtualPath': `${UrlHandler.getTrackingPreUrl()}kurv/utm_content/${content}`
                });
            }
            const campaign = query.utm_campaign as string
            if (campaign) {
                GtmService.triggerCustomGtmEvent({
                    'event': 'track-vp',
                    'virtualPath': `${UrlHandler.getTrackingPreUrl()}kurv/utm_campaign/${campaign}`
                });
            }
        } catch (error) {
            console.error(error);
            // too bad
        }

    }

}
