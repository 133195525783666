export default class ThemeService {
    static changeFavicon(src: string) {
        const head = document.head || document.getElementsByTagName('head')[0];
        const oldLinks = document.querySelectorAll("[id^=favicon]");
        const oldLinksArray = Array.from(oldLinks);

        oldLinksArray.forEach((link) => {
            head.removeChild(link);
        });

        const newLink = document.createElement('link');
              newLink.id = 'dynamic-favicon';
              newLink.rel = 'shortcut icon';
              newLink.href = src;

        head.appendChild(newLink);
    }
}
