
import { Options, Vue } from 'vue-class-component';
import GtmService from "@/services/gtmService";

@Options({
    name: 'CheckboxComponent',
    props: {
         name: String,
         id: String,
         labelledby: {type: String, default: undefined},
         inputValue: {type: String, default: undefined},
         preSelect: {type: Boolean, default: false},
         disabled: {type: Boolean, default: false},
         testProp: {type: String, default: undefined}, // Used for targetting component data with automatic test
         trackingEnabled: {type: Boolean, default: true }, // tracks to gtm, by default sends event action: radio, label: content of slot
         change: Boolean,
        //  label: String
    }
})

export default class CheckboxComponent extends Vue {
    name: string;
    id: string;
    labelledby?: string;
    inputValue?: string;
    preSelect?: boolean;
    disabled: boolean;
    testProp?: string; // Used for targetting component data with automatic test
    trackingEnabled!: boolean; // tracks to gtm, by default sends event action: radio, label: content of slot
//    @Provide() change: boolean = false;
    change: boolean = false;

    private trackEvt: any = {
        event: 'Checkmark',
        eventAction: 'checkmark field',
        eventCategory: 'Engagement',
        eventLabel: `${this.trackLabel} - ${this.change ? 'on' : 'off'}`,
    }

    // LIFECYCLE
    public created() {
        if (this.preSelect !== undefined) {
            this.change = this.preSelect;
        }
    }

    // METHODS
    public changeItem() {
        this.change = !this.change;
        const item = (this as any).$refs[this.name + this.id];
        (item as HTMLInputElement).checked = true;
        this.changed();
    }

    public changed() {
        (this as any).$emit('CheckboxComponent', {
            name: this.name,
            id: this.id,
            value: this.inputValue !== undefined ? this.inputValue : this.change,
            checked: this.change
        });
        this.track();
        (this as any).$refs[this.name + this.id].blur();
    }

    private track(): void {
        this.trackEvt.eventLabel = `${this.trackLabel} - ${this.change ? 'on' : 'off'}`;
        GtmService.triggerCustomGtmEvent(this.trackEvt);
    }

    // COMPUTED
    get trackLabel(): string {
        return (this as any).$slots?.default[0]?.text || this.inputValue || this.id || this.name || "";
    }
}
