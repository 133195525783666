import { Options, Vue } from "vue-class-component";
import Datepicker from 'vue3-datepicker';
import { ref } from 'vue';
import { add, sub , format, parse, isBefore, isAfter, isSameDay } from 'date-fns';
import da from 'date-fns/locale/da';
import UrlUtilService from '@/services/urlUtilService';

export enum DateValidationRules {
    BeforeToday = 'BeforeToday',
    AfterToday = 'AfterToday',
    BeforeDate = 'BeforeDate',
    AfterDate = 'AfterDate',
}
const now = new Date();
const validClassName = 'calendar input--valid';
const errMsg = 'Datoen kan ikke vælges';
@Options({
    name: "DatePickerComponent",
    components: {
        Datepicker, 
    },
    props: {
        label: String, // top label for field
        popoverContent: String, // if not undefined popovericon will show
        placeholder: {type: String, default: 'Vælg dato'},
        innerId: String, // name of attribute on innerData object - ex. birthdate - resolves to {model: person { birthdate: [updated value from pickedDate]}}
        innerData: Object, // data obejct for innerId - ex {model: person {}}
        showCalendarIcon: {type: Boolean, default: true},
        readonlyValue: {type: String, default: null}, // set value in field and renderes field readonly and valid
        format: {type: String, default: 'dd-MM-yyyy'},
        validationRule: String, // optional - enum DateValidationRules
        dateLimit: String, // optional - depending on validationRule - wether specifc date is upper- or lower limit for valid dates. only used when BeforeDate and AfterDate
        dateLimitIncluded: { type: Boolean, default: false}, // wether today is a valid selection - only used when rule: BeforeToday and AfterToday
        maxUpperLimitYears: {type: Number, default: 1}, // max upper range
        maxLowerLimitYears: {type: Number, default: 100}, // max lower range
        validateOnLoad: {type: Boolean, default: false}, // validate pickedDate on load (mounted)
        required: {type: Boolean, default: true}, // is field validated when empty
    }
})

export default class DatePickerComponent extends Vue {
    label: string;
    popoverContent: string;
    placeholder: string;
    innerId: string
    innerData: any;
    showCalendarIcon: boolean;
    readonlyValue: string; 
    format: string;
    validationRule: string; 
    dateLimit: string; 
    dateLimitIncluded: boolean;
    maxUpperLimitYears: number;
    maxLowerLimitYears: number;
    validateOnLoad: boolean;
    required: boolean;

    public validationErrorFormatText: string = '';
    public hasError = false;

    private dateLimitDate: Date = undefined;
    public pickedDate = null;
    //public disabledDates = ref(sub(now, { days: 1 }));
    public lowerLimit = null;
    public upperLimit = null;
    public locale = da;
    public validClass: string = null;
    public validClassReadOnly = validClassName;
    

    public created() {
        if (this.innerData && this.innerData[this.innerId]) {
            const date = parse(this.innerData[this.innerId], this.format, now);
            this.pickedDate = ref(date);
        }
        this.setupValidation();
        this.sanityDevelopCheck();
        this.validationErrorFormatText = errMsg;
    }

    public mounted() {
        if (this.readonlyValue) {
            return;
        }
        (this.$refs.datePicker as any).$refs.inputRef.setAttribute('autocomplete', 'off');
        if (this.validateOnLoad) {
            (this.$refs.datePicker as any).$refs.inputRef.blur();
        }
    }

    public change(evt) {
        this.validClass = undefined;
        let value: string = undefined;
        this.hasError = false;
        if (this.innerData) {
            this.innerData[this.innerId] = undefined;
        }
        if (!this.required && !this.pickedDate) {
            return;
        }

        try {
            value = format(new Date(this.pickedDate + ''), this.format);
        } catch(e) {
            this.hasError = true;
            return;
        }
        
        if (!this.isValidDate(new Date(this.pickedDate))) {
            this.hasError = true;
            return;
        }
        this.validClass = validClassName;
        if (this.innerData) {
            this.innerData[this.innerId] = value;
            this.$emit('dateSelected', value);
        }
        if (evt.type === 'blur') {
            this.$emit('dateSelectedBlur', value);
        }
        
    }

    public insureCorrectFormat(evt) {
        this.validClass = undefined;
        if (evt.keyCode === 13 || evt.keyCode === 27) { // enter/escape
            return;
        }
        const inputValue = (this.$refs.datePicker as any).input?.trim();
        const length = inputValue ? inputValue.length : 0; 
        if (!this.required && length === 0) {
            return;
        }

        if (evt.type !== 'blur' && inputValue.length !== evt.target.selectionStart) {
            // user is editing
            return;
        }
        const res = inputValue.match(/[\d]+/g);
        
        if (evt.type !== 'blur' && (!res || !res[2])) {
            return;
        }

        if (evt.type !== 'blur' && res[0].length + res[1].length === 5) {
            return; // all is wel
        }
        
        try {

            let dateString = res[0];
            dateString = (res[0].length < 2 ? '0' + res[0] : res[0]) + '-' + (res[1].length < 2 ? '0' + res[1] : res[1]) + '-';
            if (res[2]) {
                dateString += res[2]
            }
            (this.$refs.datePicker as any).input = dateString;
        } catch(e) {
            this.hasError= true;
            (this.$refs.datePicker as any).$refs.inputRef.blur();
            return;
    }

        if ((this.$refs.datePicker as any).input.length === 10 || evt.type === 'blur') {
            // force validate
            const date = parse((this.$refs.datePicker as any).input, this.format, now)
            if (date.toString() === 'Invalid Date') { 
                this.hasError = true;
                (this.$refs.datePicker as any).$refs.inputRef.blur();
                return;
            }
        }

        this.change(evt);
    }

    public reset() {
        this.hasError = false;
        this.validClass = null;
        if (this.innerData) {
            this.innerData[this.innerId] = undefined;
        }
    }

    private setupValidation() {
        if (this.validationRule && (this.validationRule === DateValidationRules.AfterDate || this.validationRule === DateValidationRules.BeforeDate)) {
            // dateLimit day is not allowed, add or remove one day
            this.dateLimitDate = parse(this.dateLimit, this.format, now);
            switch (this.validationRule) {
                case DateValidationRules.AfterDate : 
                    if (!this.dateLimitIncluded) {
                        this.dateLimitDate = add(this.dateLimitDate,  {days: 1});
                    }
                    this.lowerLimit = ref(this.dateLimitDate);
                    this.upperLimit = ref(add(this.dateLimitDate, { years: this.maxUpperLimitYears}));
                    break;
                case DateValidationRules.BeforeDate : 
                    if (!this.dateLimitIncluded) {
                        this.dateLimitDate = sub(this.dateLimitDate,  {days: 1});
                    }
                    this.upperLimit = ref(this.dateLimitDate);
                    this.lowerLimit = ref(sub(this.dateLimitDate, { years: this.maxLowerLimitYears}));
                    break;
            }
        } else if (this.validationRule) {
            // 'now' is not allowed by default, add or remove one day
            this.dateLimitDate = now;
            switch (this.validationRule) {
                case DateValidationRules.AfterToday : 
                    if (!this.dateLimitIncluded) {
                        this.dateLimitDate = add(now,  {days: 1});
                    }
                    this.lowerLimit = ref(this.dateLimitDate);
                    this.upperLimit = ref(add(this.dateLimitDate, { years: this.maxUpperLimitYears}));
                    break;
                case DateValidationRules.BeforeToday : 
                    if (!this.dateLimitIncluded) {
                        this.dateLimitDate = sub(now,  {days: 1});
                    }
                    this.upperLimit = ref(this.dateLimitDate);
                    this.lowerLimit = ref(sub(this.dateLimitDate, { years: this.maxLowerLimitYears}));
                    break;
            }
        } else { // just insure max - min selections
            this.dateLimitDate = now;
            this.lowerLimit = ref(sub(this.dateLimitDate, { years: this.maxLowerLimitYears}))
            this.upperLimit = ref(add(this.dateLimitDate, { years: this.maxUpperLimitYears}));
        }
    }
    private isValidDate(date: Date): boolean {
        let ok = false;
        this.hasError = ok;
        switch (this.validationRule) {
            case DateValidationRules.AfterToday :
            case DateValidationRules.AfterDate : 
                ok = (isAfter(date, this.dateLimitDate) || isSameDay(date, this.dateLimitDate)) && isBefore(date, new Date(this.upperLimit + '') );
                this.hasError = !ok;
                return ok;
            case DateValidationRules.BeforeToday:
            case DateValidationRules.BeforeDate : 
                ok = (isBefore(date, this.dateLimitDate) || isSameDay(date, this.dateLimitDate)) && isAfter(date, new Date(this.lowerLimit + '') );
                this.hasError = !ok;
                return ok;
            default : 
                ok = isBefore(date, this.upperLimit) && isAfter(date, new Date(this.lowerLimit + ''));
                this.hasError = !ok;
                return ok;
        }
    }

    private sanityDevelopCheck() {
        if (!UrlUtilService.isDevelop()) {
            return;
        }
        if (!this.validationRule) {
            console.warn('DatePicker develop: No validation rule set');
            return;
        }
        switch (this.validationRule) {
            case DateValidationRules.AfterDate :
            case DateValidationRules.AfterToday :
            case DateValidationRules.BeforeDate :
            case DateValidationRules.BeforeToday : break;
            default : console.warn( 'DatePicker sanityDevelopCheck: invalid validation Rule', '<' +this.validationRule+ '>', 'validation rule must be in ', Object.values(DateValidationRules));
        }
        if (this.validationRule && (this.validationRule === DateValidationRules.AfterDate || this.validationRule === DateValidationRules.BeforeDate)) {
            if (!this.dateLimit) {
                console.error('DatePicker sanityDevelopChecklop: dateLimit not set')
            } else {
                const date: any = parse(this.dateLimit, this.format, now);
                if (date.toString() === 'Invalid Date') {
                    console.error('DatePicker sanityDevelopCheck: dateLimit could not be parsed in format', this.format, ' - dateLimit is', '<'+this.dateLimit+'>');
                }
            }
        }
    }
}
