import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "accordion" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CoverageComponent = _resolveComponent("CoverageComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coverages, (coverageAdapter) => {
      return (_openBlock(), _createBlock(_component_CoverageComponent, {
        key: coverageAdapter.id,
        coverageAdapter: coverageAdapter,
        isCovered: true,
        track: _ctx.track,
        trackGtm: _ctx.trackGtm,
        trackGtmLabel: _ctx.trackGtmLabel
      }, null, 8, ["coverageAdapter", "track", "trackGtm", "trackGtmLabel"]))
    }), 128))
  ]))
}