import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, withKeys as _withKeys, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-451391ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "name", "disabled", "aria-labelledby", "value", "data-t-rdo-input", "change"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['radio-styled', 'radio-styled--'+_ctx.size, { 'radio-styled--inline': _ctx.inlineType }])
  }, [
    _createElementVNode("input", {
      class: "radio-styled__input",
      type: "radio",
      id: _ctx.id,
      ref: (_ctx.name + _ctx.id),
      name: _ctx.name,
      disabled: _ctx.disabled,
      "aria-labelledby": _ctx.labelledby,
      value: _ctx.inputValue,
      "data-t-rdo-input": _ctx.testProp,
      change: _ctx.change,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changed && _ctx.changed(...args)))
    }, null, 40, _hoisted_1),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("label", _mergeProps({
          key: 0,
          class: "radio-styled__label",
          for: _ctx.id,
          tabindex: "0",
          onKeyup: [
            _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.changeItem()), ["enter"])),
            _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.changeItem()), ["space"]))
          ]
        }, _ctx.trackingAttributes), [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 16, _hoisted_2))
      : (_openBlock(), _createElementBlock("span", _mergeProps({
          key: 1,
          class: "radio-styled__label",
          tabindex: "0",
          onKeyup: [
            _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.changeItem()), ["enter"])),
            _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.changeItem()), ["space"]))
          ]
        }, _ctx.trackingAttributes), null, 16))
  ], 2))
}