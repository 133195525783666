import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "tile__item__icon" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkComponent = _resolveComponent("LinkComponent")!
  const _directive_epi_edit = _resolveDirective("epi-edit")!

  return _withDirectives((_openBlock(), _createBlock(_component_LinkComponent, {
    class: "tile__item tile__item--link",
    to: _ctx.model.link.url,
    target: _ctx.model.externalLinkTarget,
    "data-gtm-action": "tile click",
    "data-gtm-label": _ctx.model.title,
    "data-gtm-url": _ctx.trackingUrl
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "tile__item__icon__img",
          src: _ctx.model.icon.url,
          alt: ""
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("div", {
        class: "tile__item__title",
        innerHTML: _ctx.model.title
      }, null, 8, _hoisted_3)
    ]),
    _: 1
  }, 8, ["to", "target", "data-gtm-label", "data-gtm-url"])), [
    [_directive_epi_edit, 'Link']
  ])
}