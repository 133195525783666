import UrlUtilService from '@/services/urlUtilService';
import Axios, { AxiosRequestConfig } from 'axios';
import AxiosRetry from 'axios-retry';

const timeout = 15000;

export default class AxiosService {
    private axiosRetry = AxiosRetry;
    
    protected axiosRetry3 = Axios.create({
        timeout,
        headers: {
            // 'Accept': 'application/json',
            'Accept': '*/*',
            'Accept-Language': 'da'
        }
    });
    private axiosOnce = Axios.create({
        timeout,
        headers: {
            //'Accept': 'application/json',
            'Accept': '*/*',
            'Accept-Language': 'da'
        }
    });
    protected defaultUrl: string;
    private withCredentials: boolean = true;
    private recaptchaEnabled: boolean = true;

    private retryConfig = { 
        retries: 3,
        retryDelay: (retryCount: number) => {
        return retryCount * 100; // time interval between retries
        },
        retryCondition: (error) => {
            if (!error?.response?.status) {
                return true;
            }
            return error?.response?.status > 240;
        },
        shouldResetTimeout : true,
    };

    constructor(url?: string) {
        this.defaultUrl = url;

        this.axiosRetry(this.axiosRetry3, this.retryConfig);


        this.axiosRetry3.interceptors.request.use(async (config) => {
            return await this.addHeaders(config);
          });      

          this.axiosOnce.interceptors.request.use(async (config) => {
            return await this.addHeaders(config);
          });
    }

    public setWithCredentials(withCredentials: boolean) {
        this.withCredentials = withCredentials;
    }

    public useRecaptcha(useRecaptcha: boolean) {
        this.recaptchaEnabled = useRecaptcha;
    }

    public async getQuery(url: string, params): Promise<any> {
        const url_ = url ? url : this.defaultUrl;
        try {
            return await this.axiosOnce.get(url_, 
                this.buildParams(params)
            );    
        } catch (error) {
            console.error(error);
            if (error?.response) {
                return error.response;
             }
            
        }
    }


    public async get(url: string = this.defaultUrl): Promise<any> {
        try {
            return await this.axiosOnce.get(url, this.buildParams(undefined));    
        } catch (error) {
            console.error(error);
            if (error?.response) {
                return error.response;
             }
            
        }
    }
    public getRetryDefaultUrl(params?: any): Promise<any> {
        return this.getRetry(undefined, params)
    }

    public async getRetry(url: string, params?: any): Promise<any> {
        let response;
        try {
            const url_ = url ? url : this.defaultUrl;
            response = await this.axiosRetry3.get(url_, 
                this.buildParams(params)
            );
            return response;
        } catch (error) {
            console.error(error);
            if (error?.response) {
                return error.response;
             }
        }

    }

    /** default url is used if Url param is undefined */
    public async post(url: string, params?: any): Promise<any> {
        try {
            const url_ = url ? url : this.defaultUrl
            return await this.axiosOnce.post(url_, this.buildParams(params));
        } catch (error) {
            console.error(error);
            
            if (error?.response) {
                return error.response;
             }
        }
    }

    public async delete(url: string, params?: any): Promise<any> {
        try {
            const url_ = url ? url : this.defaultUrl
            return await this.axiosOnce.delete(url_, this.buildParams(params));
        } catch (error) {
            console.error(error);
            if (error?.response) {
                return error.response;
             }
        }

    }

    public async put(url: string, params?: any): Promise<any> {
        try {
            const url_ = url ? url : this.defaultUrl
            return await this.axiosOnce.put(url_, this.buildParams(params));
        } catch (error) {
            console.error(error);
            
            if (error?.response) {
                return error.response;
             }
        }

    }

    // /**
    //  * POST REST kald der kan styre oauth token i headeren via følgende localforage værdier
    //  *
    //  * loginRequired: hvis det er påkrævet at headeren indeholder oauth token (kan sættes i epi-server på selv blokken)
    //  *
    //  * token: selve oauth token som api-service har returneret efter login
    //  *
    //  * @param urlEndpoint
    //  * @param payload
    //  * @param contentType
    //  * @param toggleSpinner
    //  * @param successCallback
    //  * @param errorCallback
    //  * @param baseUrl - override default baseUrl
    //  */
    // public async abPostWithToken(urlEndpoint: string, payload: any, contentType = "application/json", toggleSpinner: boolean, successCallback: any, errorCallback: any, baseURL: string = undefined) {
    //     const self = this;
    //     toggleSpinner === true ? modal({ overlay: true, spinner: true }) : false;

    //     let headers = await this.buildSecurityHeader(contentType);
    //     if (baseURL || baseURL === '') {
    //         headers = Object.assign(headers, {baseURL})
    //     }

    //     self.abaxiosRetry(self.abAxios, { retries: 3 });
    //     await self.abAxios.post(urlEndpoint, payload, headers)
    //         .then(function (response) {
    //             toggleSpinner === true ? modal({ overlay: true, spinner: true }) : false;
    //             successCallback(response);
    //         })
    //         .catch(function (error) {
    //             toggleSpinner === true ? modal({ overlay: true, spinner: true }) : false;
    //             self.handleApiError(error);
    //             errorCallback(error);
    //         });
    // }

    private async addHeaders(config): Promise<AxiosRequestConfig> {
        let recaptchaToken: string;
        // console.log('add headers - recaptchaEnabled', this.recaptchaEnabled);
        
        if (this.recaptchaEnabled) {
            recaptchaToken = await UrlUtilService.getReCaptchaToken();
        }
        // console.log('token', recaptchaToken);
        
        config.headers = {
            'x-recaptcha-token': recaptchaToken,
            'Accept': 'application/json',
            'Accept-Language': 'da',
        }
        return config;
    }

    private buildParams(params) {
        return Object.assign({}, {params}, { timeout ,withCredentials: this.withCredentials})        
    }

}
