import store from "@/store/store";
import CoverageAdapter from "@/views/calculators/model_legacy/CoverageAdapter";
import GtmService from "@/services/gtmService";
import { Options, Vue } from "vue-class-component";

@Options({
  name: "CoverageComponent",
  components: {
  },
  props: {
    coverageAdapter: Object,
    track: Boolean, // track using css classes
    trackGtm: Boolean, // track using gtm attributes
    trackGtmLabel: String, // optional label for gtm attributes
  },
})
export default class CoverageComponent extends Vue {
  coverageAdapter: CoverageAdapter;
  track: boolean; // track using css classes
  trackGtm: boolean; // track using gtm attributes
  trackGtmLabel?: string; // optional label for gtm attributes
  trackingUrl: string;

  public refreshKey: string = Math.random()+ '';

  beforeMount() {
    this.trackingUrl = GtmService.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
  }
  public animateController() {
    if (this.coverageAdapter?.coverage?.description) {
      this.coverageAdapter.selected = !this.coverageAdapter.selected;
      this.refreshKey = Math.random() + '';
    }
  }
  public getTrackGtmLabel(): string {
    if (this.trackGtm) {
      if (this.trackGtmLabel) {
        return `${this.trackGtmLabel} - ${this.coverageAdapter.title}`;
      }
      return this.coverageAdapter.title;
    }
    return undefined;
  }
}
