import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vertical-app vertical-flow"
}
const _hoisted_2 = { class: "vertical-flow__form__step vertical-flow__form__step--header" }
const _hoisted_3 = { class: "vertical-flow__form__step__title" }
const _hoisted_4 = { class: "vertical-flow__form__step__text vertical-flow__form__step__text--initial" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["onClick", "data-gtm", "data-gtm-click-text"]
const _hoisted_8 = ["textContent"]
const _hoisted_9 = { class: "vertical-flow__form__step__text" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 0,
  class: "vertical-flow__form__step__accordion text-center"
}
const _hoisted_13 = { class: "vertical-flow__form__step__content" }
const _hoisted_14 = ["onClick", "innerHTML", "data-gtm", "data-gtm-click-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerticalStepComponent = _resolveComponent("VerticalStepComponent")!

  return (_ctx.renderPage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: "validation-message",
                innerHTML: _ctx.getCustomerInfo()
              }, null, 8, _hoisted_5)
            ])
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.proposalSteps, (step, inx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'step' + inx
          }, [
            _createElementVNode("div", {
              id: '_' + step.id,
              class: _normalizeClass(['vertical-flow__form__step',
                        {'vertical-flow__form__step--inactive' : !_ctx.isActiveStep(step)},
                        {'vertical-flow__form__step--validated' : _ctx.isValid(step) && !_ctx.isActiveStep(step)}])
            }, [
              _createElementVNode("div", {
                class: "vertical-flow__form__step__title",
                onClick: ($event: any) => (_ctx.gotoStep(step)),
                "data-gtm": step.dataGtm,
                "data-gtm-click-text": step.title
              }, [
                _createElementVNode("div", {
                  class: "vertical-flow__form__step__number",
                  textContent: _toDisplayString((inx + 1))
                }, null, 8, _hoisted_8),
                _createElementVNode("div", _hoisted_9, [
                  _withDirectives(_createElementVNode("div", {
                    class: "main",
                    innerHTML: step.title
                  }, null, 8, _hoisted_10), [
                    [_vShow, step.title]
                  ]),
                  _withDirectives(_createElementVNode("div", {
                    class: "validation-message",
                    innerHTML: step.subtitle
                  }, null, 8, _hoisted_11), [
                    [_vShow, step.subtitle && !_ctx.isActiveStep(step) || step.subtitle]
                  ])
                ])
              ], 8, _hoisted_7),
              _createVNode(_Transition, { name: "slide-vertical" }, {
                default: _withCtx(() => [
                  (_ctx.isActiveStep(step))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_VerticalStepComponent, { step: step }, null, 8, ["step"])
                        ]),
                        _createElementVNode("a", {
                          class: _normalizeClass(['btn-solid-secondary mt-3', {'btn-disabled': !_ctx.isValid(step)}]),
                          onClick: ($event: any) => (_ctx.nextStep(step)),
                          innerHTML: step.buttonLabel,
                          "data-gtm": step.dataGtm,
                          "data-gtm-click-text": step.title
                        }, null, 10, _hoisted_14)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024)
            ], 10, _hoisted_6)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}