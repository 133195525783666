
import { mapState } from 'vuex';
import ButtonComponent from '@/sharedcomponents/baseComponents/buttonComponent/buttonComponent.vue';
import ModalComponent from '@/sharedcomponents/baseComponents/ModalComponent/ModalComponent.vue';
import GtmService from "@/services/gtmService";
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import { nextTick } from '@vue/runtime-core';

@Options({
    name: 'ToggleModalComponent',
    components: {
        ModalComponent,
        ButtonComponent,
    },
    computed: mapState<any>({
        modal: state => store.state.modal,
    }),
})

export default class ToggleModalComponent extends Vue {
    public modal: any;
    
    // private lastStateShow = false;

    mounted() {
        store.subscribeAction((action, state) => {
            if (action.type === 'toggleModal') {
				//if (this.modal?.id === action.payload.id) {
                    if (!action.payload.isActive) {
                        this.modal.show = false;
                        // this.lastStateShow = false;
                    }
				//}
            }
        });        

    }
    public get showModal() {
        const show = /*this.modal?.id !== undefined && this.modal?.id !== 'none' &&*/ this.modal.show === true;
        if (show/* && !this.lastStateShow*/) {
            // trigger
            nextTick(() => {
                store.dispatch('toggleModal',{
                    isActive: this.modal.show,
                    id: this.modal.id,
                });
            });    

        } else {
            // Hack to Fix scrollbar missing
            (document.body as HTMLElement).style.overflow = 'initial';
        }
        return show;
    }

    public closeModal() {
        store.dispatch('toggleModal', {show: false, id: this.modal.id});
    //         setTimeout(() => {
    //             this.resetModal();
    //             // Hack to Fix scrollbar missing
    //             (document.body as HTMLElement).style.overflow = 'initial';
    //         }, 1000);

    }

    public track() {
        console.warn('TODO KLK track varaiable modal');
        
        // default tracking
        // let virtualPath = `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/error/customercall`;

        if (this.modal.track) {
            const token = this.modal.trackToken ? this.modal.trackToken : 'error';
            // virtualPath = `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/${token}/`;
        }

        GtmService.triggerCustomGtmEvent({
            'event': 'track-vp',
            virtualPath: 'Todo KLK track'
        });

    }

    public getHrefType(): string {
        if (this.modal.btnType === 'tel') {
            return 'tel:';
        }
        if (this.modal.btnType === 'mailto') {
            return 'mailto:';
        }
        return '';
    }

    public getIcon(): string {
        return this.modal.btnType === 'tel' ? this.modal.btnIcon: undefined;
    }

    public getLabel(): string {
        if (this.modal.btnAction && (this.modal.btnType === 'tel' || this.modal.btnType === 'mailto')) {
            return this.modal.btnLabel + this.modal.btnAction;
        }
        return this.modal.btnLabel;
    }

    public getTarget(): string {
        if (this.modal.btnType === 'link') {
            return '_blank';
        }
        return undefined;
    }

    public handleInlineAction() {
        if (typeof this.modal.btnInlineAction == 'function') {
            this.modal.btnInlineAction();

        } else {
            this.$router.push(this.modal.btnInlineAction);
        }
    }
}
