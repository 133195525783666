import Adapter from "./Adapter";
export default class CoverageAdapter extends Adapter {

    public coverage: any;//Coverage;

    public constructor(coverage: any) {
        super(-1 + "", coverage.title, coverage.subtitle);
        /* TODO KLK ???
        if(feature.optional_properties && feature.optional_properties.sum && feature.optional_properties.sum.length > 0) {
            this.optionalProperties = feature.optional_properties.sum;
            if(feature.optional_properties.default_sum && feature.optional_properties.default_sum !== '') {
                this.selectedOptionalProperty = feature.optional_properties.default_sum;
            }
        }
        */
        this.coverage = coverage;
    }
}