import { Options, Vue } from "vue-class-component";
import AxiosService from '@/services/axiosService';
import Autocomplete from 'vue3-autocomplete';

@Options({
    name: "AddressSearchComponent",
    components: {
    },
    props: {
        label: String,
        autocompleteId: String,
        defaultValue: {type: String,default: null},
        isOnlyZipSearch: {type: Boolean, default: false},
        isValid: {type: Boolean, default: false},
        placeholder: String,
        readonly: {type: String, default: null},
    }
})
export default class AddressSearchComponent extends Vue {
    label: string;
    autocompleteId?: string;
    defaultValue?: string; // if model has value
    isOnlyZipSearch?: boolean; // search only zipcode/city
    isValid?: boolean; // valid search result
    placeholder: string;
    readonly?: string;
    
    public dirty: boolean = true;
    private autocomplete: Autocomplete;
    public searching = false;
    public results: Array<string> = [];
    private doBlur = true;

    //private searchUrl = 'https://dawa.aws.dk/autocomplete?';
    //struktur=mini&

    private searchUrl = 'https://api.dataforsyningen.dk/autocomplete?';
    private searchParams = 'startfra=adgangsadresse&type=adresse&supplerendebynavn=true&stormodtagerpostnumre=true&multilinje=false&fuzzy=false&q=';
    public axiosService = new AxiosService();

    public mounted() {
        this.axiosService.useRecaptcha(false);
        if (this.isOnlyZipSearch) {
            // this.searchUrl = "https://dawa.aws.dk/postnumre/autocomplete?";
            this.searchUrl = "https://api.dataforsyningen.dk/postnumre/autocomplete?";
            
            this.searchParams = "supplerendebynavn=true&stormodtagerpostnumre=true&multilinje=false&fuzzy=false&startfra=vejnavn&type=adresse&q=";
        }
        this.axiosService.setWithCredentials(false);
    }

    public setAutoCompleteField(input) {
        this.autocomplete = input;
        if (this.defaultValue) {
            this.autocomplete.setText(this.defaultValue);
            this.dirty = false;
        }
    }
    
    public displayItem(item) {
        return this.isOnlyZipSearch ?  item.tekst : item.forslagstekst
    }
    
    public async focus(): Promise<any> {
        this.dirty = true;
        await this.search(this.autocomplete.searchText);
    }
    
    public async search(input): Promise<any> {
        this.dirty = true;
        this.doBlur = true;

        this.results = []
        if (input.length < 2) {
            return;
        }
        this.searching = true;
        const url = `${this.searchUrl}${this.searchParams}${encodeURI(input)}`;
        const response = await this.axiosService.getRetry(url);
        if (response.status !== 200) {
            return;
        }
        if (response.data.length > 0) {
            this.results = response.data;
        } 
        this.searching = false;
    }

    public handleSubmit(result) {
        this.autocomplete.setText(this.isOnlyZipSearch ?  result.tekst : result.forslagstekst);
        this.emit(result);
    }

    public blur(evt) {
        if (this.doBlur && this.results.length > 0) {
            if (this.autocomplete.searchText !== this.results[0]) {
                this.handleSubmit(this.results[0]);
            }
        }
        this.doBlur = false;
    }

    private emit(result) {
        if (result) {
            let res: any;
            if (this.isOnlyZipSearch) { // Postnr
                res = {
                    matchText: result.tekst,
                    zipCode: result.postnummer.nr,
                    zipName: result.postnummer.navn,
                };
            } else { // adresse
                res = {
                    matchText: result.forslagstekst,
                    data: result.data, // specific datafields from DAWA
                };
            }
            this.$emit('AddressSearch', res);
            this.searching = false;
            this.doBlur = false;
            this.dirty = false;
        }
    }

}
