
import { UPDATE_AUTH } from "@/store/modules/authenticationContext";
import TagsEnum from "@/enums/TagsEnum";
import { CustomerCalculation } from "@/store/modules/calculatorContext";
import UrlHandler from "./UrlHandler";
import store from '@/store/store';
import { Log } from "@/appinsights/logging/log";


const _logProperties = { SourceContext: "LoginHandler", context: "spa" };

export default class UserLoginHandler {

    public static async loadCustomer(cms: any) {
        const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
        try {
            if (!cms.tryLoadCustomer) {
                customerCalculation.hasCustomerData = false;
                return;
            }
            
            if (customerCalculation.customerFetched) {
                // customer try fetched or already loaded
                return;
            }
            
            if (!store.state.authenticationContext.isLoaded)
            {
                await store.dispatch(UPDATE_AUTH);
            }

            if (!store.state.authenticationContext.authenticated) {
                return;
            }
            
            const customer = await this.getCustomer();

            if (!customer?.person) {
                return;
            }
            if (customer.tags?.includes(TagsEnum.BUSINESS)) {
                // dont use business customers
                return;
            }

            customerCalculation.customer.contact_information.customerNo = customer.customer_id;
            if(!customerCalculation.customer.contact_information.customerNo) {
                return;
            }

            const phoneNo = this.getCustomerPhoneNo();
            customerCalculation.customer.contact_information.phone = phoneNo;

            customerCalculation.customer.contact_information.name = `${customer.person.firstname} ${customer.person.lastname}`;

            // enough data to share
            customerCalculation.hasCustomerData = true;
            // show logout btn at end og flow if not in mitalmbrand
            customerCalculation.useLogout = !UrlHandler.path.includes('mitalmbrand');

            const details = customer.contact.contact_details;
            let floorNr: string;
            let floorSide: string;
            let floorDoor: string;
            details.forEach(detail => {
                // console.log('key', detail.type);
                // console.log('value', detail.detail);
                if(detail.type === 'Email__c' && detail?.detail) {
                    customerCalculation.customer.contact_information.email = detail.detail;
                } else if(detail.type === 'Floor__c' && detail?.detail && detail.detail !== 'null') {
                    floorNr = detail.detail.toLocaleLowerCase();
                } else if(detail.type === 'Floor_Side_Code__c' && detail?.detail && detail.detail !== 'null') {
                    floorSide = detail.detail.toLocaleLowerCase();
                } else if(detail.type === 'Floor_Door__c' && detail?.detail && detail.detail !== 'null') {
                    floorDoor = detail.detail.toLocaleLowerCase();
                }
            });


            let addr = customer.contact.address.street_name + " " + customer.contact.address.building_no_and_letter + ", ";
            if (floorNr) {
                addr = addr + floorNr + ". ";
            }
            if (floorSide) {
                addr = addr + floorSide + ". ";
            }
            if (floorDoor) {
                addr = addr + floorDoor + ". ";
            }
            addr = addr + customer.contact.address.postal_code + " " + customer.contact.address.city_name;

            customerCalculation.customer.personInfo.address = addr;
            customerCalculation.customer.personInfo.zipCode = `${customer.contact.address.postal_code}`;
            customerCalculation.customer.personInfo.zipName = `${customer.contact.address.city_name}`;
            customerCalculation.customer.personInfo.streetName = `${customer.contact.address.street_name}`;
            customerCalculation.customer.personInfo.houseNr = `${customer.contact.address.building_no_and_letter}`;
            customerCalculation.customer.personInfo.floorNr = floorNr;
            if (floorSide) {
                customerCalculation.customer.personInfo.doorNr = floorSide;
            }
            if (floorDoor) {
                if(customerCalculation.customer.personInfo.doorNr) {
                    customerCalculation.customer.personInfo.doorNr += ' ' + floorDoor
                } else {
                    customerCalculation.customer.personInfo.doorNr = floorDoor;
                }
            }
            // console.log('customer loaded', customerCalculation.customer);
            
            // const agreementPage = await this.getAgreementList(1, false)
            // const plusCustomerProducts = [];
            // if(agreementPage?.agreements?.length > 0) {
            //     agreementPage.agreements.forEach(agg => {
            //         const title = this.capitalizeFirstLetter(agg.title);
            //         if(cms.discountPrimaryProducts.includes(title)) {
            //             if (!plusCustomerProducts.includes(title)) {
            //                 plusCustomerProducts.push(title);
            //             }
            //         }
            //         if (cms.discountSecondaryProducts.includes(title)) {
            //             if(!plusCustomerProducts.includes(title)) {
            //                 plusCustomerProducts.push(title);
            //             }
            //         }
            //     });
            // }

            // if (plusCustomerProducts?.length > 0) {
            //     customerCalculation.customer.personInfo.existingAlmBrandProducts = plusCustomerProducts;
            // }
            // else {
            //     customerCalculation.customer.personInfo.existingAlmBrandProducts = [];
            // }
            // customerCalculation.customer.personInfo.almbrandCustomer = 'ja';
            // customerCalculation.agreementsFetched = true;
            // console.log('agreements loaded', customerCalculation.customer.personInfo.existingAlmBrandProducts);
            
        } catch (error) {
            console.error(error);
        } finally {
            customerCalculation.customerFetched = true;
        }

    }

    private static async getCustomer() {
        // try {
        //     let customer = null;
        //     if (store.getters.getCustomer &&
        //         (store.getters.getCustomer.person || store.getters.getCustomer.organisatio)) {
        //         customer = store.getters.getCustomer;
        //     } else {
        //         customer = await this.getCustomerViaRest();
        //     }
        //     return customer;
        // } catch (err) {
        //     console.error(err);
        //     return undefined;
        // }
        return undefined;
    }    

    private async getCustomerViaRest() {
        // return await this.abAxiosSelfservice.customerDetailsServiceLayer.fetchCustomerDetails().then(resp => {
        //     store.dispatch('setCustomer', resp.data);
        //     const userData: UserData = store.getters.getUserData;
        //     userData.setCanChangeOnline(resp.data);
        //     store.dispatch('setUserData', userData);

        //     return resp.data;
        // }).catch(err => {
        //     console.error(err);
        //     return undefined;
        // })
    }

    private static getCustomerPhoneNo(): string {
        const mobileNo = store.getters.getCustomer.contact.contact_details.find( (mobile) => {
            return mobile.type.toLowerCase() === 'mobile__c';
        });
        if(mobileNo && mobileNo.detail && mobileNo.detail !== 'null') {
            return mobileNo.detail;
        }

        const phoneNo = store.getters.getCustomer.contact.contact_details.find( (phone) => {
            return phone.type.toLowerCase() === 'phone';
        });
        if(phoneNo && phoneNo.detail && phoneNo.detail !== 'null') {
            return phoneNo.detail;
        }
        return undefined;
    }

}
