import BuyCar from './BuyCarComponent';
import { BuyInsuranceHelper, Formatter, monthYearArray, ValuePair } from '../BuyInsuranceHelper';
import { cloneDeep } from 'lodash';
import AxiosService from '@/services/axiosService';
import store from '@/store/store';
// import { getCarLicenseplateSearch } from '@/views/TestData/calculators/apiTestData';
// import CarTestCalculation from '@/views/TestData/calculators/CarCalculation.json';
import { defaultConfig } from '@/cms/api/ApiConfig';
import GtmService from '@/services/gtmService';

export default class CarCalculator {
    private buyCar: BuyCar; // component using calculator
    private model: any; // the data structure for state and properties
    private cms: any; // settings from EPI
    private licensePlateSearchUrl : string;
    private calcConfig: any;
    // used for first three calculations, to see if there's known error codes
    private succesCalc = true;
    private axiosService: AxiosService;
    private calculatorUrl: string;
    private calculatorInfo: Array<any>;


    constructor(buyCar: BuyCar) {
        this.buyCar = buyCar;
        this.model = buyCar.model;
        this.cms = buyCar.cms;
        this.calculatorInfo = JSON.parse(this.buyCar.calculatorInfoBlock.markup);
        //private baseUrl: string = '/insurance/car'
        // const headers = [{ name: 'quote-api', value: 'true'}];
        // this.abAxiosSelfserviceOpen = new AbAxiosSelfservice(store.getters.getConfigEpi.openServiceUrl + this.baseUrl,
        //     'application/json',
        //     store.getters.getConfigEpi.csrfToken,
        //     headers);
        
        this.calculatorUrl =  defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/car/quotes';

        this.licensePlateSearchUrl = defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + 
            '/insurance/car/variants/by-registration-number/';
        this.axiosService = new AxiosService(this.calculatorUrl);
        this.setUpConfig();
    }

    public async setUpConfig() {
        if (!this.calcConfig) {
            if (this.model.campaign.valid) {
                this.cms.calculatorConfigId = this.cms.campaigns.get(this.model.campaign.ID).blockId;
            }
            
            const calculatorInfo = this.calculatorInfo.find(cfg => cfg.calculatorConfigId === this.cms.calculatorConfigId);
            
            this.calcConfig = calculatorInfo.insuranceConfiguration;
            this.cms.mapCoverages(calculatorInfo.textMappingsOuter);

        }
        this.model.calculation.abCalc = {
            excessIdDefault: this.calcConfig.excessIdDefault,
            packageIds: this.calcConfig.packageIds,
            packages: cloneDeep(this.calcConfig.packages),
            coveragesDetails: this.calcConfig.coverages,
        };

        this.setupExcessList();

        // map coverages to each package
        this.model.calculation.abCalc.packages.forEach(pack => {
            // we can only extend, when Ansvar+ is handled explicit
            if (this.cms.extendCoverages) {
                pack.coverages = [];
                pack.includedCoverageIds.forEach(riskId => {
                    const coverage = this.calcConfig.coverages.find( x => riskId === x.riskId);
                    pack.coverages.push(coverage);
                });
                pack.expandedCoverages = [];
                pack.includedCoveragesExpandedIds.forEach(riskId => {
                    const coverage = this.calcConfig.coverages.find( x => riskId === x.riskId);
                    pack.expandedCoverages.push(this.cms.getCoverageName(coverage.name));
                });
            } else {
                pack.coverages = [];
                pack.includedCoveragesExpandedIds.forEach(riskId => {
                    const coverage = this.calcConfig.coverages.find( x => riskId === x.riskId);
                    pack.coverages.push(coverage);
                });
            }
        });


        this.model.calculation.abCalc.packs = new Map<number, Object>();
        this.model.calculation.abCalc.excesses.forEach(excess => {
            const calcs = [];
            this.model.calculation.abCalc.packs.set(excess.id, calcs);
            this.model.calculation.abCalc.packages.forEach( (pack) => {
                calcs.push(this.buyCar.helper.reducePack(cloneDeep(pack)));
            });
        });
        this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'
        if(!this.model.carInfo.yearlyMileage) {
            this.model.carInfo.yearlyMileage = this.cms.kmForbrug[2]; // op til 15.000 km
        }

        this.buyCar.helper.mapCoveragesFromProductConfig();
        this.updateCalcKeys();
    }

    public setupExcessList() {
        this.model.calculation.abCalc.excesses = this.calcConfig.excessList;
        if (this.model.calculation.abCalc.excesses[0].id === this.model.calculation.abCalc.excessIdDefault) {
            // ensure default excess is last in list
            this.model.calculation.abCalc.excesses.reverse();
        }
        this.model.ownRiskOptions = [];
        this.model.ownRiskLabels = [];

        this.model.calculation.abCalc.excesses.forEach((excess) => {
            if (this.model.personInfo.customerAge >= excess.minCustomerAge) {
                this.model.ownRiskOptions.push(excess.id);
                this.model.ownRiskLabels.push(Formatter.format(excess.amount) + ' kr.');
            }
        });
    }

    private checkFirstPackagePrices(excessId: number) {
        let ok = true;
        const packages = this.model.calculation.abCalc.packs.get(excessId);
        packages.forEach(calc => {
            if (!calc.totalPrice) {
                ok = false;
            }
        });
        return ok;
    }

    private async calculatePackagesEpi(monthly: boolean, excessId: number): Promise<boolean> {
        this.succesCalc = true;

        const rebate = this.buyCar.helper.getDiscount();
        this.model.calculation.discount = rebate.discount;
        this.model.calculation.discountDisplay = rebate.discountDisplay;

// FuelType/VehicleGroup discount
        const special = BuyInsuranceHelper.handleSpecialCarDiscounts(this.buyCar);

        let promises = [];
        for (let index = 0; index < this.model.calculation.abCalc.packageIds.length; index++) {
            const packageId = this.model.calculation.abCalc.packageIds[index];
            promises.push(this.onePackageCalcEpi(monthly, excessId, packageId, special.specialDiscount));
        }
        await Promise.all(promises);
        return Promise.resolve(this.succesCalc);
    }

    public async searchLicenseplate(value) {
        // return await getCarLicenseplateSearch();

        const url = `${this.licensePlateSearchUrl}${encodeURI(value)}`;
        return await this.axiosService.get(url);
    }
    public async getCalculations(excessId?: number) : Promise<boolean> {
        if (!this.model.calculation.isCalculated) {
            this.model.showSpinner = true;
            this.model.calculating = true;
            // handle "eternal" spinning
            setTimeout(() => {
                if(this.model.showSpinner) {
                    this.model.showSpinner = false;
                    this.model.calculating = false;
                }
            }, this.cms.calculationSpinnerTimeout);
        }

        return this.getEpiCalculations();
    }

    private async getEpiCalculations(): Promise<boolean> {
        // reset calculation
        this.model.choosePackage.useTiaOffer = false;
        await this.setUpConfig();
        let success = true;

        const selectedExcessId = this.model.choosePackage.ownRiskId || this.model.calculation.abCalc.excessIdDefault;

        if (this.buyCar.helper.hasAddressSupplement()) {
            this.model.calculation.addressSupplement = this.cms.addressSupplement;
        } else {
            this.model.calculation.addressSupplement = 1;
        }
        // resetPrices
        const pack = this.model.calculation.abCalc.packs.get(selectedExcessId);
        pack.totalPriceDisplay = undefined;

        success = await this.calculatePackagesEpi(true, selectedExcessId);
        // check if has got prices
        if(!success) {
            success = this.checkFirstPackagePrices(selectedExcessId);
        }
        // get yearlyprices
        this.calculatePackagesEpi(false, selectedExcessId);

        this.model.calculation.isCalculated = true;
        this.model.showSpinner = false;
        this.model.calculating = false;
        return success;
    }

    private async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number, specialDiscount: number) {
        if (this.isSpecialCarOrExcludedBrand()) {
            this.succesCalc = false;
            return await this.specialCarModal();
        }
        const response = await this.axiosService.getRetryDefaultUrl(this.createParamsEpi(excessId, packageId, monthly ? 1 : 0));
        
        if (response.status !== 200) {
            this.succesCalc = false;

            if (response?.quote_problem?.length > 0) {
                return await this.specialCarModal();
            }

            BuyInsuranceHelper.togglePopup(this.cms, {
                title: 'Der er desværre sket en fejl',
                content: this.cms.defaultCalcErrorContent,
                track: false,
                btnSecondLabel: 'Ok',
                id: 'calcWarning',
                show: true,
            });
            return Promise.resolve(false);
        }

        const pack = this.model.calculation.abCalc.packs.get(excessId);
        //BuyInsuranceHelper.mapPriceResult(response); - doesn't work - car is different from other calculators
        this.mapPriceResultForCar(response);
        this.buyCar.helper.setPrices(packageId, response, monthly, pack, specialDiscount);
        this.updateCalcKeys();
        return Promise.resolve(true);
    }

    private mapPriceResultForCar(response) {
        const res = response.data;
        response.data.totalPrice = res.total;
        response.data.statutoryFee = res.detail.statutory_fee;
        response.data.rebatePrice = res.rebate_price;
    }

    private createParamsEpi( excessId: number, packageId: number, monthlyPrices: number ) {
        // move these outside
        const carOwnerYears = this.cms.carOwnerYears.indexOf(this.model.personInfo.carOwnerYears);
        let yearlyDrivingInKm = this.cms.kmForbrug.indexOf(this.model.carInfo.yearlyMileage);
        yearlyDrivingInKm = ((yearlyDrivingInKm * 5000) + 5000);
        const customerAge  = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g,''));
        let groupAgreement = 0;
        if (this.model.campaign.valid) {
            groupAgreement = this.cms.campaigns.get(this.model.campaign.ID).groupId;
        }
        const pack = this.model.calculation.abCalc.packages.find( pack => packageId === pack.id);

        // create params
        const params = {
            // kid: this.model.carInfo.kid,
            // car_weight: this.model.carInfo.kid ? this.model.carInfo.carWeight : undefined,

            annual_driving_exceed_in_km: yearlyDrivingInKm,
            claims_last_3_years: 0,
            plus_customer: 'PK0',
            customer_age: customerAge,
            excess_id: excessId,
            years_with_own_car: carOwnerYears,
            postal_code: this.model.personInfo.zipCode,
            payments_per_year: monthlyPrices === 1 ? 12 : 1,
            selected: pack.calculateRisks.toString(),
            brand: this.model.carInfo.brand,
            model: this.model.carInfo.model,
            variant_id: this.model.carInfo.variantId,
            group_agreement: groupAgreement > 0 ? groupAgreement : undefined,
        }
        return params;
    }

    private createParamsEpiSpecialCar() {
        const monthlyPrices = 1;
        const packageId = this.model.calculation.abCalc.packageIds[0]; // superkasko
        const excessId = this.model.calculation.abCalc.excessIdDefault;
        const pack = this.model.calculation.abCalc.packages.find( pack => packageId === pack.id);
        // console.log('pack.calculateRisks', pack.calculateRisks.toString());
        
        // create params
        const params = {
            years_with_own_car: 2,
            annual_driving_exceed_in_km: 15000,
            customer_age: 30,
            postal_code: 1656,
            plus_customer: 'PK0',
            claims_last_3_years: 0,
            variant_id: this.model.carInfo.variantId,
            model: this.model.carInfo.model,
            brand: this.model.carInfo.brand,
            excess_id: excessId,
            payments_per_year: monthlyPrices === 1 ? 12 : 1,
            selected: pack.calculateRisks.toString(),
        }
        
        return params;
    }

    public async isSpecialCar(): Promise<boolean> {
        if (!this.model.carInfo.checkForSpecialCar) {
            this.model.carInfo.checkForSpecialCar = true;

            if (this.isSpecialCarOrExcludedBrand()) {
                return await this.specialCarModal();
            } 

            const response = await this.axiosService.getRetryDefaultUrl(this.createParamsEpiSpecialCar());
            if (response.status === 400) {
                if (response?.quote_problem?.length > 0) {
                    return await this.specialCarModal();
                }
            }
        }
        return Promise.resolve(false);
    }

    private isSpecialCarOrExcludedBrand() {
        if (/*this.model.carInfo.special ||*/ this.cms.excludedVehicleGroups.includes(this.model.carInfo.vehicleGroup + '')) {
            return true;
        }
        const brand = this.model.carInfo.brand?.toLocaleLowerCase()?.trim();
        return this.cms.carBrandBlackList.includes(brand);
    }

    private async specialCarModal(): Promise<any> {
        await new Promise((res) => setTimeout(res, 500));
        BuyInsuranceHelper.togglePopup(this.cms, {
            title: this.cms.specialCarTitle,
            content: this.cms.specialCarContent,
            track: true,
            trackToken: `error/specialcar`,
            btnSecondLabel: 'Ok',
            id: 'calcWarning',
            show: true,
        });
        return Promise.resolve(true);
    }

    public updateCalcKeys() {
        this.model.calculation.updatePrices = this.model.calculation.updatePrices.map( (elem) => {
            return elem += '1';
        });
    }

}
