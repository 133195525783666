
import { mapState } from 'vuex';
import { CardBlock } from '@/cms/definitions/content-types';
import { DogSettings } from '../../dog/DogSettings';
import { BuyInsuranceHelper, Validator } from '../../BuyInsuranceHelper';

import FieldValidator from '@/services/fieldValidatorService';
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import store from '@/store/store';
// import AbDatePickerComponent from '@/views/sharedcomponents/baseComponents/components/abDatePickerComponent/AbDatePickerComponent.vue';
@Options({
    name: 'DogInfoStepComponent',
    components: {
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
    props: {
        card: Object as PropType<CardBlock>,
        isValid: Boolean,
    }
})

export default class DogInfoStepComponent extends Vue {
    card: CardBlock; // Card from cms
    isValid: boolean; // whether card is complete

    public model!: any; // datastructure for user input (store)
    public cms!: DogSettings; // settings from CMS (store)
    public readonlyDogName1: string = null;
    public readonlyDogName2: string = null;
    public fieldValidator: FieldValidator = Validator;
    public genderOptions = ['T', 'H'];
    public genderLabels = ['Tæve', 'Han'];

    public mounted(): void {
        window.addEventListener('keyup' , this.moveOn );
    }
    public beforeUnmount() {
        window.removeEventListener('keyup', this.moveOn);
    }

    public moveOn(evt) {
        if (evt.keyCode === 13 && this.isValid) {
            this.nextStep();
        }
    }

    public dogCount(evt) {
        if (evt.id === 'dogCount') {
            const val = parseInt(evt.value);
            this.model.dogInfo.dogCount = val;
            if(this.model.dogInfo.dogCount === 3) {
                // cannot calculate more than 2 dogs
                if (this.model.dogInfo.dogCount && this.model.dogInfo.dogCount > 2) {
                    const popup = {
                            content: this.cms.manyDogsContentWarning,
                            track: true,
                            trackToken: `warning/morethantwodogs`,
                            btnSecondLabel: 'Ok',
                            id: 'calcWarning',
                            title: this.cms.manyDogsTitleWarning,
                            show: true,
                    }
                    BuyInsuranceHelper.togglePopup(this.cms, popup);
                    return;
                }
            }
            setTimeout(() => {
                let input;
                if (!this.model.dogInfo.dogName1) {
                    input = document.getElementById('dogName1');
                } else if (this.model.dogInfo.dogCount === 2 && !this.model.dogInfo.dogName2 ) {
                    input = document.getElementById('dogName2');
                }
                if (input) {
                    input.focus();
                }
            }, this.cms.initialTimeout);
        }

    }

    public dogTypeSelected(evt) {
        const dogId = parseInt(evt.id);
        const inx = this.cms.dogList.indexOf(evt.matchText);
        const typeId = this.cms.dogListIds[inx];
        dogId === 1 ? this.model.dogInfo.dogType1 = evt.matchText : this.model.dogInfo.dogType2 = evt.matchText;
        dogId === 1 ? this.model.dogInfo.dogTypeId1 = typeId : this.model.dogInfo.dogTypeId2 = typeId;
        if (dogId === 1) {
            setTimeout(() => {
                if (!this.model.dogInfo.dogName2) {
                    const input = document.getElementById('dogName2');
                    if (input) {
                        input.focus();
                    }
                }
            }, this.cms.initialTimeout);
        }
    }

    public handleGender(evt) {
        this.model.dogInfo[evt.id] = evt.value;
    }
    public get validDogCount(): boolean {
        return this.model.dogInfo.dogCount && this.model.dogInfo.dogCount !== 3;
    }

    public get showSecondDog(): boolean {
        return this.model.dogInfo.dogCount === 2 &&
                this.model.dogInfo.dogName1 &&
                this.model.dogInfo.dogTypeId1 &&
                this.model.dogInfo.gender1 &&
                this.fieldValidator.isValidBeforeNow(this.model.dogInfo.birthdate1);
    }

    public nextStep() {
        this.$emit('nextStep', this.card.routePath);
    }

 }
