import { TileBlock } from "@/cms/definitions/content-types";
import store from '@/store/store';
import AppService from "@/services/appService";
import GtmService from "@/services/gtmService";
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";


@Options({
  name: "TileApp",
  components: {},
  computed: mapState<any>({
    isEditable: (state) => state.epiContext.isEditable,
    parentModel: (state) => state.epiContent.model,
  }),
  props: {
    model: Object as PropType<TileBlock>,
  },
})
export default class TileApp extends Vue {
  model: TileBlock;

  isApp = false;

  trackingUrl: string;

  // LIFECYCLE
  beforeMount() {
    this.isApp = AppService.isEnabled();

    this.trackingUrl = GtmService.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
  }

  // METHODS
  sendMessage() {
    AppService.sendMessage(JSON.parse(this.model.appMessage));
  }
}
