import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button-container text-center" }
const _hoisted_2 = ["textContent", "data-gtm", "data-gtm-click-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExistingAbProductsComponent = _resolveComponent("ExistingAbProductsComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.model.showExistingAlmBrandProducts)
      ? (_openBlock(), _createBlock(_component_ExistingAbProductsComponent, {
          key: "ExistingAbProductsComponent",
          card: _ctx.card
        }, null, 8, ["card"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        class: _normalizeClass([_ctx.card.buttonStyle, 'mt-3', {'btn-disabled': !_ctx.isValid}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextStep())),
        textContent: _toDisplayString(_ctx.card.linkText),
        "data-gtm": _ctx.cms.dataGtm,
        "data-gtm-click-text": _ctx.card.routePath
      }, null, 10, _hoisted_2)
    ])
  ]))
}