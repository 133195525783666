import { createApp } from 'vue';
import App from '@/App.vue';
import EpiEdit from '@/cms/directives/epiEdit';
import router from './router';
import store from '@/store/store';
import appInsights, { AppInsightsOptions } from '@/appinsights/appinsights';
import '@/epiBootstrap';
import { defaultCmsConfig } from "@/cms/CmsConfig"
defaultCmsConfig.contentArea.rootHtmlTag = 'section';

// import AccordionGroupBlock from '@/cms/components/blocks/accordionBlock/AccordionBlock.vue';
// import TextBlock from '@/cms/components/blocks/textBlock/TextBlock.vue';
// import ImageBrandBlock from '@/overview/misc/imageBrandBlock/imageBrandBlock.vue';
import PopoverComponent from '@/sharedcomponents/baseComponents/popoverComponent/popoverComponent.vue';
import ValidationErrorComponent from '@/sharedcomponents/baseComponents/ValidationErrorComponent/ValidationErrorComponent.vue';
import SegmentedControlComponent from '@/sharedcomponents/baseComponents/segmentedControlComponent/segmentedControlComponent.vue';
import SelectModalComponent from '@/sharedcomponents/baseComponents/selectModalComponent/selectModalComponent.vue'
import CheckboxComponent from "@/sharedcomponents/baseComponents/CheckboxComponent/CheckboxComponent.vue";
import RadioComponent from "@/sharedcomponents/baseComponents/RadioComponent/RadioComponent.vue";
import InputFieldComponent from "@/sharedcomponents/baseComponents/inputFieldComponent/InputFieldComponent.vue";
import DatePickerComponent from '@/sharedcomponents/baseComponents/datePickerComponent/DatePickerComponent.vue';
import SearchComponent from '@/sharedcomponents/baseComponents/searchComponent/SearchComponent.vue';
import MessageComponent from '@/sharedcomponents/baseComponents/messageComponent/messageComponent.vue';
import ToggleModalComponent from '@/sharedcomponents/baseComponents/toggleModalComponent/ToggleModalComponent.vue';
import AutocompleteComponent from "@/sharedcomponents/baseComponents/autoCompleteComponent/AutocompleteComponent.vue";
import AddressSearchComponent from './sharedcomponents/baseComponents/addressSearchComponent/AddressSearchComponent.vue';
// BasketPage
import TileBlock from "@/sharedcomponents/pageComponents/tileBlock/TileBlock.vue";

// Register all Episerver view components globally. This requires webpack!
// Otherwise we need to register all components manually here in main.ts.

const app = createApp(App)
    .directive('epi-edit', EpiEdit)
    .use(store)
    .use(router)
    .use(appInsights, { router } as AppInsightsOptions)
    .component('PopoverComponent', PopoverComponent)
    .component('ValidationErrorComponent', ValidationErrorComponent)
    .component('SegmentedControlComponent', SegmentedControlComponent)
    .component('CheckboxComponent', CheckboxComponent)
    .component('RadioComponent', RadioComponent)
    .component('InputFieldComponent', InputFieldComponent)
    .component('SelectModalComponent', SelectModalComponent)
    .component('DatePickerComponent', DatePickerComponent)
    .component('SearchComponent', SearchComponent)
    .component('MessageComponent', MessageComponent)
    .component('ToggleModalComponent', ToggleModalComponent)
    .component('AutocompleteComponent', AutocompleteComponent)
    .component('AddressSearchComponent', AddressSearchComponent)
    .component('TileBlock', TileBlock)
    ;

// addPageComponents('@/views/Proposition/pages');
// addPageComponents('@/views/calculators/pages');

const addPageComponents = (requireComponent) => {
    // const requireComponent = require.context(path, true, /.vue$/);
    console.log('requireComponent.keys()', requireComponent.keys());
    
    requireComponent.keys().forEach((fileName) => {
        const componentConfig = requireComponent(fileName);
    
        // Gets the component name regardless folder depth
        const namePart = fileName.split('/').pop() ?? '';
        const componentName = namePart.replace(/\.\w+$/, '');
    
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        console.log("### adding componentName: "+componentName);
        app.component(componentName, componentConfig.default || componentConfig);
    });
}
let requireComponent = require.context('@/views/Proposition/pages', true, /.vue$/);
addPageComponents(requireComponent);

requireComponent = require.context('@/views/calculators/pages', true, /.vue$/);
addPageComponents(requireComponent);
// // Register all Episerver view components globally. This requires webpack!
// // Otherwise we need to register all components manually here in main.ts.
// let requireComponent = require.context('@/views/Proposition/pages', true, /.vue$/);
// requireComponent.keys().forEach((fileName) => {
//     const componentConfig = requireComponent(fileName);

//     // Gets the component name regardless folder depth
//     const namePart = fileName.split('/').pop() ?? '';
//     const componentName = namePart.replace(/\.\w+$/, '');

//     // Look for the component options on `.default`, which will
//     // exist if the component was exported with `export default`,
//     // otherwise fall back to module's root.
//     //console.log("###componentName: "+componentName);
//     app.component(componentName, componentConfig.default || componentConfig);
// });

app.mount('#app');

declare global {
    interface Window {
        epi: {
            inEditMode: boolean
            isEditable: boolean
            subscribe(eventName: string, callback: (message: { url: string }) => void): void
            ready: boolean
        }
    }
}