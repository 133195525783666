import store from "@/store/store";

export default class UtilService {
    /**
     * Make whole string lowercase and Capitalize 1. Letter
     * If allWords, then take every word to lowercase, split by ' ' and capitalize 1 letter.
     * checks also for double ' '
     * TODO perhaps also check also on '-'
     * @param str
     * @param allWords default = false
     */

    public static capitalizeFirstLetter(str: string, allWordsInString: boolean = false) {
        if (!str || str.trim() === '') {
            return '';
        }

        const str_ = str.toLocaleLowerCase();
        if (!allWordsInString) {
            return str_[0].toUpperCase() + str_.slice(1);
        }
        return str_.split(' ').map(w => {
            w = w.trim();
            return w !== '' ? w[0].toUpperCase() + w.slice(1) : w;
        }).join(' ');

      }

      /**
       * Beregner alder udfra Cpr nr.
       * alder kan ikke være under 18
       * @param cpr
       */
      public static getAgeFromCpr(cpr: string): number {
        const dateNow = new Date();
        const thisYear = dateNow.getFullYear() - 2000;
        let year = parseInt(cpr.slice(4,6));
        if (year > ( thisYear - 18 )) {
            year = 1900 + year;
        } else {
            year = 2000 + year;
        }
        const dateThen = new Date(year, parseInt(cpr.slice(2,4)) -1, parseInt(cpr.slice(0,2)));
        dateThen.setFullYear(year);
        dateThen.setMonth(parseInt(cpr.slice(2,4)) -1);
        dateThen.setDate(parseInt(cpr.slice(0,2)));

        let diffYears =  dateNow.getFullYear() - dateThen.getFullYear();
        const diffMonths =  dateNow.getMonth() - dateThen.getMonth();
        const diffDays =  dateNow.getDate() - dateThen.getDate();

        if(diffMonths < 0) {
            diffYears--;
        }
        if(diffMonths === 0 && diffDays < 0) {
            diffYears--;
        }
        if(diffYears < 18) {
            diffYears += 100;
        }
        return diffYears;
    }

    public static setPageMetaData(model: any) {
        try {
            if (!model) {
                return;
            }
            if (model.metaTitle?.length > 0) {
                document.title = model.metaTitle;
            }
            if (model.metaDescription?.length > 0) {
                document.querySelector('meta[name="description"]').setAttribute("content", model.metaDescription);
            }
            if (model.metaKeywords?.length > 0) {
                document.querySelector('meta[name="keywords"]').setAttribute("content", model.metaKeywords);
            }
        } catch(e) {
            // document.querySelector fails
        }
    }    

    public static browserIe11OrOlder() {
        let bool = false;
        if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
            bool = true;
        }
        return bool;
    }

    public static get isThemed() {
        // @ts-ignore: Object is possibly 'null'.
        // let themed = this.$root["themeBrand"];
        let themed = store.getters.getThemeContext;
        if (themed) {
          return true;
        }
        return false;
      }

}

