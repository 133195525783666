import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex text-left mt-3 mb-3" }
const _hoisted_2 = { class: "grow strong" }
const _hoisted_3 = { class: "strong" }
const _hoisted_4 = {
  key: "updateSuggestKey",
  class: "highlights fill mb-4 mt-5"
}
const _hoisted_5 = { class: "text-left" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "button-container text-center" }
const _hoisted_9 = ["textContent", "data-gtm", "data-gtm-click-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OverviewCalculatorStepComponent = _resolveComponent("OverviewCalculatorStepComponent")!
  const _component_PopoverComponent = _resolveComponent("PopoverComponent")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_TransitionGroup, {
      name: "slide-simple",
      key: _ctx.updateProdKey
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productNames, (productName, inx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'productName' + inx + _ctx.updateProdKey
          }, [
            _createVNode(_component_OverviewCalculatorStepComponent, { productName: productName }, null, 8, ["productName"])
          ]))
        }), 128))
      ]),
      _: 1
    })),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("div", _hoisted_2, "Samlet pris", 512), [
        [_vShow, _ctx.hasPrices]
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getMonthlyTotalPrice(true)), 1)
    ]),
    _createVNode(_TransitionGroup, { name: "slide-simple" }, {
      default: _withCtx(() => [
        (_ctx.suggestText)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", { innerHTML: _ctx.suggestText }, null, 8, _hoisted_6),
                (_ctx.basketCms.plusCustomerPopover)
                  ? (_openBlock(), _createBlock(_component_PopoverComponent, {
                      key: 0,
                      type: "icon",
                      class: "page-component__popover text-left",
                      buttonClass: "popover__icon popover__icon__questionmark",
                      innerData: _ctx.basketCms.plusCustomerPopover
                    }, null, 8, ["innerData"]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", { innerHTML: _ctx.suggestText1 }, null, 8, _hoisted_7)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_router_link, {
        style: {"border-color":"initial"},
        class: "text-center btn-link-secondary mt-3",
        to: _ctx.forsikringerLink,
        textContent: _toDisplayString(_ctx.basketCms.addProductsTxt),
        "data-gtm": _ctx.basketCms.dataGtm,
        "data-gtm-click-text": _ctx.basketCms.addProductsTxt
      }, null, 8, ["to", "textContent", "data-gtm", "data-gtm-click-text"]),
      (_ctx.isValid && _ctx.productNames.length > 1)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: _normalizeClass(['text-center',_ctx.card.buttonStyle, 'mt-3', {'btn-disabled': !_ctx.isValid}]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextStep())),
            textContent: _toDisplayString(_ctx.card.linkText),
            "data-gtm": _ctx.basketCms.dataGtm,
            "data-gtm-click-text": _ctx.card.routePath
          }, null, 10, _hoisted_9))
        : _createCommentVNode("", true)
    ])
  ]))
}