import store from '@/store/store';
import LinkComponent from '@/sharedcomponents/baseComponents/linkComponent/linkComponent';
import GtmService from '@/services/gtmService';
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";


@Options({
  name: "TileNav",
  components: {
    LinkComponent,
  },
  computed: mapState<any>({
    isEditable: (state) => state.epiContext.isEditable,
    parentModel: (state) => state.epiContent.model,
  }),
  props: {
    model: Object as PropType<any>,
  },
})
export default class TileNav extends Vue {
  model: any;

  trackingUrl!: string;

  beforeMount() {
    this.trackingUrl = GtmService.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
  }
}
