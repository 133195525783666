import { Options, Vue } from 'vue-class-component';
import PageTitleComponent from '@/sharedcomponents/pageComponents/pageTitle/PageTitleComponent.vue';
import { PropositionPage} from '@/cms/definitions/content-types';
import store from '@/store/store';
import CookieFooter from "@/sharedcomponents/pageComponents/cookieFooter/cookieFooter.vue";
import LogoutService from '@/services/logoutService';
import VerticalContainerComponent from './VerticalContainerComponent.vue';

@Options({
    name: 'PropositionComponent',
    components: {
        PageTitleComponent,
        VerticalContainerComponent,
        // ContactComponent,
        CookieFooter,
    },
    props: {
        model: Object,
    }
})

export default class PropositionComponent extends Vue {

    public model: PropositionPage; // epi contentModel
    public usabillaBlock: any = null;
    public usabillaBlockLeaveIntent: any = null;
    public renderPage = false;
    public headline: string = '...';

    public beforeCreate() {
        LogoutService.handleIdleLogout('/logout', 30); // TODO KLK - remove data from session(?)
    }

    public async beforeMount() {
        store.state.showSpinner = true;

        this.handlePreTitle();
        window.addEventListener('resize', this.handlePreTitle);

        // TODO avoid timeout
        setTimeout(() => {
            this.renderPage = true;
        }, 500);

    }

    public beforeUnmount() {
        // Todo klk proper Vue event
		window.removeEventListener('resize', this.handlePreTitle);
	}

    public get showSpinner() {
        return store.state.showSpinner;
    }
    private handlePreTitle() {
        this.headline = this.model.headline;
        // screen.width < 768 ? this.preTitle = this.cms.shortTitle : this.preTitle = this.model.preTitle;
    }

}
