export default {
    setEpiRouting({ commit }: { commit: any }, epiRouting: []) {
        commit("SET_EPI_ROUTING", epiRouting);
    },
    setUsabillaCount({ commit }: { commit: any }) {
        commit("SET_USABILLA_COUNT");
    },
    setUsabillaMounted({ commit }: { commit: any }) {
        commit("SET_USABILLA_MOUNTED");
    },
    toggleModal({ commit }: { commit: any }, modal: {id: string, isActive: boolean}) {
        commit('TOGGLE_MODAL', modal);
    },

    // setAppReplaceRoute({ commit }: { commit: any }, appReplaceRoute: boolean) {
    //     commit('SET_APP_REPLACE_ROUTE', appReplaceRoute);
    // },
    // setOldAppVersionAlerted({ commit }: { commit: any }, consent: boolean) {
    //     commit('SET_OLD_APP_VERSION_ALERTED', consent);
    // },
    setRecaptchaSiteKey({ commit }: { commit: any }, recaptchaSiteKey: string) {
        commit('RECAPTHCA_SITE_KEY', recaptchaSiteKey);
    },
};
