import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import { nextTick } from 'vue';
import VueScrollTo from "vue-scrollto";
import { mapState } from 'vuex';
import TestConfig from './TestData/TestData';
import AxiosService from '@/services/axiosService';
import { defaultConfig } from '@/cms/api/ApiConfig';
import ModelMapper from './ModelMapper';
import UrlUtilService from '@/services/urlUtilService';
import VerticalStepComponent from './VerticalStepComponent.vue'
import { ADD_PROPOSAL_STEPS } from '@/store/modules/ProposalContext';

@Options({
    name: 'VerticalContainerComponent',
    components: {
        VerticalStepComponent,
    },
    computed: mapState<any>({
        // steps: state => state.ProposalContext.steps,
        party: state => state.ProposalContext.party,
        offerId: state => state.ProposalContext.offerId,
        price: state => state.ProposalContext.price,
    }),
    props: {
        model: Object
    }
})

export default class VerticalContainerComponent extends Vue {

    public formatter: Intl.NumberFormat = Intl.NumberFormat("da-DK", { minimumFractionDigits: 0,  maximumFractionDigits: 0 } as any);

    public model: any;
    
    // public steps!: Map<string, any>;
    public party!: any;
    public offerId!: string;
    public price!: number;
    public customerInfoLabel!: string;


    public renderPage = false;
    private waitActive = true;
    private activeStep = undefined;
    public proposalSteps = [];

    public created() {
        window.addEventListener('keyup' , this.moveOn );
    }
    public beforeUnmount() {
        window.removeEventListener('keyup', this.moveOn);
    }

    public moveOn(evt) {
        if (evt.keyCode === 13 && this.isValid) {
            this.nextStep(this.activeStep);
        }
    }

    public async mounted() {
        store.state.showSpinner = true;
        await this.getProposal();
        this.activeStep = store.getters.getActiveProposalStep;
        this.scrollMeTo(this.activeStep);

        this.renderPage = true;  
        store.state.showSpinner = false;
        // make sure activeStep is not loaded immediately - enables transition
        nextTick( () => {
            this.waitActive = false;
        });
        // store.state.modal = this.testModal();
    }

    private async getProposal() {
        const baseUrl = defaultConfig.baseUrl + store.state.openServiceUrl;
        const proposeUrl = TestConfig.getPolicyProposalUrl();
        const axiosService = new AxiosService();
        let proposal: any;
        let doMock = false;
        try {
            proposal = await axiosService.get(baseUrl + proposeUrl);
            if (proposal?.status !== 200) {
                doMock = true;
            }
        } catch(e) {
            console.error('TODO cannot load proposal', e);
            doMock = true;
        }
        if (doMock && UrlUtilService.isDevelop()) {
            console.warn('fallback DEV load HardCoded proposal');
            proposal = TestConfig.getPolicyProposalHardCoded();
        }
        const modelMapper: ModelMapper = new ModelMapper(proposal, this.model);
        this.proposalSteps = modelMapper.getPropsalSteps();

        await store.dispatch(ADD_PROPOSAL_STEPS, this.proposalSteps);
    }

    public getSubtitle(step: any) {
        return step.subtitle;
    }
    
    public isActiveStep(step: any): boolean {
        return step.id === this.activeStep?.id && !this.waitActive;
    }

    public getCustomerInfo() {
        const customerInfo = `<strong>Tilbudnummer:</strong> ${this.offerId}
            <div class="flex flex__space">
                <span><strong>Dine oplysninger:</strong> ${this.party.name}</span><span><strong>${this.formatter.format(this.price)} kr./år</strong></span>
            </div>`;
        return customerInfo;
    }
    public isValid(step: any) {
        return step.isValid;
    }

    public gotoStep(step: any) {
        const previousStepsValid = store.getters.getIsPreviousProposalStepsValid(step.id);
        if (previousStepsValid) {
            this.activeStep = step;
            this.scrollMeTo(this.activeStep);
            this.reloadLoop();
        }
    }    
    public nextStep(step: any) {
        if (!step.isValid) {
            return;
        }
        const active = store.getters.getActiveProposalStep;
        if (!active) {
            // all steps are valid
            // todo check if it's last step
            store.state.showSpinner = true;
            console.warn('TODO klk secure signicat url!!!');
            setTimeout(() => {
                //location.href = store.state.ProposalContext.signatureUrl;
                store.state.modal = this.testModal();
            }, 2000);
            return;
        }

        this.activeStep = active;
        this.scrollMeTo(this.activeStep);
        this.reloadLoop();
    }

    private testModal() {
        // setup fail modal as default
        const modal: any = {};
        // modal.modalId = '12';
        modal.show = true;
        modal.id = 'proposal'; // never changes
        modal.track = false;
        modal.trackToken = undefined;
        modal.content = `<ul>
            <li>Underskriv hos Signicat</li>
            <li>Gå til kvittering</li>
            </ul>`;
        //modal.btnType = 'tel';
        modal.btnType = undefined; //'link';
        modal.btnStyle = 'btn-solid-secondary';
        modal.btnLabel = 'Gå til kvittering';
        modal.btnIcon = undefined;
        modal.btnAction = '/receipt-page?id=123';
        modal.btnSecondStyle = 'btn-outline-secondary';
        modal.btnSecondLabel = 'Fortryd';
        modal.btnInlineLabel = undefined;
        modal.btnInlineAction = undefined;
        modal.btnInlinestyle = undefined; //'btn-solid-secondary';
        modal.title = 'Underskrift hos Signicat';
        modal.redirect = undefined; // '/'
        return modal;

    }

    public scrollMeTo(step: any) {
        if (!step?.id) {
            return;
        }
        setTimeout(() => {
            VueScrollTo.scrollTo(`#_${step.id}`, 300, { easing: 'ease-out', offset: -25 });
            // console.log('scrollto:', '_' + step.id);
            // Hack to Fix scrollbar missing
            // (document.body as HTMLElement).style.overflow = 'initial';
        }, 1500);
    }

    private reloadLoop() {
        this.renderPage = !this.renderPage;
        this.renderPage = !this.renderPage;
    }

}
