import BuyTravel from './BuyTravelComponent';
import { BuyInsuranceHelper, Formatter, monthYearArray, ValuePair } from '../BuyInsuranceHelper';
import { cloneDeep } from 'lodash';
import { defaultConfig } from '@/cms/api/ApiConfig';
import store from '@/store/store';
import AxiosService from '@/services/axiosService';
// import { getTestDataTravelProductInfo } from '@/views/TestData/calculators/apiTestData';
// import travelTestCalculation from '@/views/TestData/calculators/TravelCalculation.json';

export default class TravelCalculator {
    private buyTravel: BuyTravel; // component using calculator
    private model: any; // the data structure for state and properties
    private cms: any; // settings from EPI
    private calcConfig: any;
    private succesCalc = true;
    private axiosService: AxiosService;
    private calculatorUrl: string;
    private calculatorInfo: Array<any>;

    constructor(buyTravel: BuyTravel) {
        this.buyTravel = buyTravel;
        this.model = buyTravel.model;
        this.cms = buyTravel.cms;
        this.calculatorInfo = JSON.parse(this.buyTravel.calculatorInfoBlock.markup);


        // this.calculatorUrl =  defaultConfig.baseUrl + store.getters.getConfigEpi.openServiceUrl + '/insurance/travel/quotes';
        // this.abAxiosCalculator = new AbAxiosCalculators(this.calculatorUrl);
        // // this.abAxiosCalculator.get('https://localhost:40100/api/v1/openserviceproxy/insurance/travel/quotes/?customer_age=45&family_member_count=1&destination=Europa&post_code=1900&cancel_sum=30000&payments_per_year=12&plus_customer=PK0&selected=8,30,33');
        // // this.abAxiosCalculator.get('https://localhost:40100/api/v1/openserviceproxy/insurance/travel/quotes/?customer_age=45&post_code=1900&destination=verden&cancel_sum=30000&family_member_count=1&plus_customer=PK0,PK3&payments_per_year=1&selected=1,5,8,9,10,11,12,13,14,15,16,17,18,19,30,31,32,33,34,35&optional=2,3,36,37,38,41,42');
        this.calculatorUrl =  defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/travel/quotes';
        this.axiosService = new AxiosService(this.calculatorUrl);

        this.setUpConfig();
    }

    private mapCoveragesFromCalc() {
        this.buyTravel.helper.mapCoveragesFromProductConfig();
        // change sum on afbestilling if other sum present in package
        this.model.calculation.abCalc.packages.forEach( (pack, packageInx) => {
            try {
                let cancelSum = parseInt(this.cms.cancelSum);
                let coverageId = this.cms.cancelSumId;
                if (pack.sums) {
                        const sums = JSON.parse(pack.sums);
                        const [covId, sum] = Object.entries(sums[0])[0];
                        cancelSum = sum as number;
                        coverageId = covId;
                }
                // only default excess
                const res =  this.model.calculation.allCoverages.values().next().value.get(packageInx);
                res.forEach(cov => {
                    if (cov.id === coverageId) { // '3' - afbestilling
                        const sumFormatted = Formatter.format(cancelSum);
                        cov.description = cov.description.replace('30.000',sumFormatted).replace('60.000',sumFormatted).replace('100.000',sumFormatted).replace('150.000',sumFormatted);
                    }
                });
            } catch (e) {
                console.error(e);
                // wrong config
            }
        });
    }

    public async setUpConfig() {
        if (!this.calcConfig) {
            if (this.model.campaign.valid) {
                this.cms.calculatorConfigId = this.cms.campaigns.get(this.model.campaign.ID).blockId;
            }
            
            const calculatorInfo = this.calculatorInfo.find(cfg => cfg.calculatorConfigId === this.cms.calculatorConfigId);
            
            this.calcConfig = calculatorInfo.insuranceConfiguration;
            this.calcConfig.familyMemberCountOptions = calculatorInfo.familyMemberCountOptions;
            this.cms.mapCoverages(calculatorInfo.textMappingsOuter);
        }

        this.model.calculation.abCalc = {
            excessIdDefault: this.calcConfig.excessIdDefault,
            packageIds: this.calcConfig.packageIds,
            packages: cloneDeep(this.calcConfig.packages),
            coveragesDetails: this.calcConfig.coverages,
            segmentedProductProperties: ['Europa', 'Verden'], // destinaions
            familyMemberCountOptions: this.calcConfig.familyMemberCountOptions
        };

        this.setupExcessList();

        this.model.calculation.abCalc.packages.forEach(pack => {
            pack.coverages = [];
            if (this.cms.extendCoverages) {
                pack.includedCoverageIds.forEach(riskId => {
                    const coverage = this.calcConfig.coverages.find( x => riskId === x.riskId);
                    pack.coverages.push(coverage);
                });
                pack.expandedCoverages = [];
                pack.includedCoveragesExpandedIds.forEach(riskId => {
                    const coverage = this.calcConfig.coverages.find( x => riskId === x.riskId);
                    pack.expandedCoverages.push(this.cms.getCoverageName(coverage.name));
                });
            } else {
                pack.includedCoveragesExpandedIds.forEach(riskId => {
                    const coverage = this.calcConfig.coverages.find( x => riskId === x.riskId);
                    pack.coverages.push(coverage);
                });
            }
        });
        this.model.calculation.abCalc.packs = new Map<number, Object>();
        this.model.calculation.abCalc.excesses.forEach(excess => {
            const calcs = [];
            this.model.calculation.abCalc.packs.set(excess.id, calcs);
            this.model.calculation.abCalc.packages.forEach( (pack) => {
                calcs.push(this.buyTravel.helper.reducePack(cloneDeep(pack)));
            });
        });
        this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'

        this.mapCoveragesFromCalc();
        this.updateCalcKeys();
    }

    public setupExcessList() {
        //this.data.calculation.abCalc.excesses = this.oldCalcConfig.excessList;
        // hardcode dummy excess
        this.model.calculation.abCalc.excesses = [
            {
                // "amount": 3229,
                'id': -1,
                'minCustomerAge': 18,
                'useAsDefault': true
            }
        ];
        this.model.ownRiskOptions = [];
        this.model.ownRiskLabels = [];
    }

    private checkFirstPackagePrices(excessId: number) {
        let ok = true;
        const packages = this.model.calculation.abCalc.packs.get(excessId);
        packages.forEach(calc => {
            if (!calc.totalPrice) {
                ok = false;
            }
        });
        return ok;
    }

    private async calculatePackagesEpi(monthly: boolean, excessId: number): Promise<boolean> {
        this.succesCalc = true;

        const rebate = this.buyTravel.helper.getDiscount();
        this.model.calculation.discount = rebate.discount;
        this.model.calculation.discountDisplay = rebate.discountDisplay;

        let promises = [];
        for (let index = 0; index < this.model.calculation.abCalc.packageIds.length; index++) {
            const packageId = this.model.calculation.abCalc.packageIds[index];
            promises.push(this.onePackageCalcEpi(monthly, excessId, packageId));
        }
        await Promise.all(promises);
        return Promise.resolve(this.succesCalc);
    }

    public async getCalculations() : Promise<boolean> {
        if (!this.model.calculation.isCalculated) {
            this.model.showSpinner = true;
            this.model.calculating = true;
            // handle "eternal" spinning
            setTimeout(() => {
                if(this.model.showSpinner) {
                    this.model.showSpinner = false;
                    this.model.calculating = false;
                }
            }, this.cms.calculationSpinnerTimeout);
        }

        // reset prices
        const pack = this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault);
        pack.totalPriceDisplay = undefined;
        return this.getEpiCalculations();
    }

    private async getEpiCalculations(): Promise<boolean> {
        // reset calculation
        await this.setUpConfig();

        let success = true;

        success = await this.calculatePackagesEpi(true, this.model.calculation.abCalc.excessIdDefault);
        // check if has got prices
        if(!success) {
            success = this.checkFirstPackagePrices(this.model.calculation.abCalc.excessIdDefault);
        }
        if (success) {
            this.calculatePackagesEpi(false, this.model.calculation.abCalc.excessIdDefault);
        }
        this.model.calculation.isCalculated = success;
        this.model.showSpinner = false;
        this.model.calculating = false;
        return success;
    }

    private async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number) {

        const response = await this.axiosService.getRetryDefaultUrl(this.createParamsEpi(excessId, packageId, monthly ? 1 : 0));

        if (response.status !== 200) {
            this.succesCalc = false;
            // this.model.modal.content = response.data.warningMessage ? response.data.warningMessage : this.cms.defaultCalcErrorContent;
            console.error('response.data.warningMessage');
            const popup = {
                content: this.cms.defaultCalcErrorContent,
                track: false,
                btnSecondLabel: 'Ok',
                id: 'calcWarning',
                show: true,
            } as any;
            if (response.status === 250) {
                    popup.title = this.cms.defaultCalcErrorTitle;
                    popup.content = this.cms.defaultCalcErrorContent;
            }
            else {
                popup.title = 'Der er desværre sket en fejl';
                popup.id = 'calcError';
            }
            BuyInsuranceHelper.togglePopup(this.cms, popup);
            return Promise.resolve(false);
        }


        const pack = this.model.calculation.abCalc.packs.get(excessId);
        BuyInsuranceHelper.mapPriceResult(response);
        this.buyTravel.helper.setPrices(packageId, response, monthly, pack);

        this.updateCalcKeys();
        return Promise.resolve(true);
    }

    private createParamsEpi( excessId: number, packageId: number, monthlyPrices: number ) {

        //  https://preprod-api.almbrand.dk/insurance/travel/quotes/?
        // customer_age=34&
        // post_code=3480&
        // destination=europa&
        // cancel_sum=30000&
        // family_member_count=2&
        // plus_customer=PK0&
        // payments_per_year=1&
        // selected=1,2,3,5,8,9,10,11,12,13,14,15,16,17,18,19,30,31,32,33,34,35,36,37,38,42&
        // optional=41
        const customerAge  = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g,''));
        let cancelSum = this.cms.cancelSum; // afbestillingssum

        // change sum on afbestilling if sum present in package
        this.model.calculation.abCalc.packages.forEach( (pack) => {
            if (pack.id === packageId) {
                if (pack.sums) {
                    try {
                        const sums = JSON.parse(pack.sums);
                        const [coverageId, sum] = Object.entries(sums[0])[0];
                        //coverageId === '3'
                        cancelSum = sum;
                    } catch (e) {
                        console.error(e);
                        // wrong config
                    }
                }
            }
        });

        let groupAgreement = 0;
        if (this.model.campaign.valid) {
            groupAgreement = this.cms.campaigns.get(this.model.campaign.ID).groupId;
        }

        // create params
        const params = {
            plus_customer: 'PK0',
            customer_age: customerAge,
            family_member_count: this.model.personInfo.familyMemberCountId,
            destination: this.model.choosePackage.segmentedProductProperty,
            post_code: `${this.model.personInfo.zipCode}`,
            group_agreement: groupAgreement < 1 ? groupAgreement : undefined,
            cancel_sum: cancelSum,
            payments_per_year: monthlyPrices === 1 ? 12 : 1,
            selected: this.buyTravel.helper.getRisksForPackage(packageId),
        }
        return params;
    }

    public updateCalcKeys() {
        this.model.calculation.updatePrices = this.model.calculation.updatePrices.map( (elem) => {
            return elem += '1';
        });
    }

}
