import { Log } from "@/appinsights/logging/log";
import UrlUtilService from '@/services/urlUtilService';
import store from "@/store/store";


export const loadRecaptchaScript = () => {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=' + resolveReCaptchaToken());
    recaptchaScript.setAttribute('type', 'text/javascript');
    recaptchaScript.setAttribute('async', 'async'); // TODO KLK sometimes race condition on load
    document.head.appendChild(recaptchaScript);
  }
  
  const resolveReCaptchaToken = () => {
      let recaptchaSiteKey = document.querySelector("#app")?.getAttribute("data-SiteKey")?.trim() || '';
      if (UrlUtilService.isDevelop() && recaptchaSiteKey === '') {
          recaptchaSiteKey = process.env.VUE_APP_DEV_RECAPTCHA;
          Log.warning('recaptchaSiteKey fallback', {'siteKey': recaptchaSiteKey});
      }
      store.dispatch('setRecaptchaSiteKey', recaptchaSiteKey);
      return recaptchaSiteKey;
  }
