import { Vue, Options } from "vue-class-component";
import { mapState } from "vuex";
import getComponentTypeForContent from '@/cms/api/contentComponentSelector';

@Options({
    name: 'PageComponentSelector',
    computed: mapState<any>({
        model: state => state.epiContent.model,
        modelLoaded: state => state.epiContent.modelLoaded
    }),
})

export default class PageComponentSelector extends Vue {
    modelLoaded: boolean;
    model: any;

    getComponentTypeForPage(model) {
        return getComponentTypeForContent(model);
    }  
}
