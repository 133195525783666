import { TileBlock } from "@/cms/definitions/content-types";
import { SELECTABLE_UPDATED } from "@/store/modules/selectableContext";
import store from '@/store/store';
// import { AbLink } from "@/views/sharedcomponents/baseComponents/components";
import { EnvironmentService } from "@/services/environmentService";
import GtmService from "@/services/gtmService";
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";


@Options({
  name: "TileButton",
  components: {
    // AbLink,
  },
  computed: mapState<any>({
    isEditable: (state) => state.epiContext.isEditable,
    parentModel: (state) => state.epiContent.model,
  }),
  props: {
    model: Object as PropType<TileBlock>,
  },
})
export default class TileButton extends Vue {
  model: TileBlock;

  trackingUrl: string;
  public disabled = false;
  private unsubscribe: any;

  beforeMount() {
    this.trackingUrl = GtmService.buildTrackingUrl(store.getters.getCustomer, this.$route.fullPath);
    if (!this.model.buttonClass) {
      this.model.buttonClass = "btn-solid-secondary";
    }

    if (this.model.selectGroupBtn) {
      this.unsubscribe = store.subscribeAction((action, state) => {
        if (action.type === SELECTABLE_UPDATED && action.payload?.group === this.model.selectGroupBtn) {
          this.toggleDisabled();
        }
      });
      // run on mount
      this.toggleDisabled();
    }
  }

  public beforeUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  private toggleDisabled() {
    const selectGroupBtn = store.getters.getSelectableGroup(this.model.selectGroupBtn); // selected in store
    if (!selectGroupBtn || selectGroupBtn.length === 0) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }

  public get link(): string {
    if (this.disabled) {
      return "";
    }
    let query = "";
    if (this.model.addQuery) {
      try {
        const inx = this.$route.fullPath.indexOf("?");
        if (inx > -1) {
          query = this.$route.fullPath.substr(inx, this.$route.fullPath.length);
        }
      } catch (error) {
        // too bad
      }
    }

    if (this.model.link) {
      return this.model.link + query;
    } else if (this.model.relativeURL) {
      if (EnvironmentService.isWeb()) {
        if (this.$route.path.includes("/mitalmbrand/")) {
          if (!this.model.relativeURL.includes("mitalmbrand/")) {
            return "/mitalmbrand" + this.model.relativeURL + query;
          }
        }
      }
      return this.model.relativeURL + query;
    }
    return "/"; // (What to do?)
  }
}
