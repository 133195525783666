import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pb-3"
}
const _hoisted_2 = {
  key: "padding",
  class: "pb-3"
}
const _hoisted_3 = {
  key: "otherpadding",
  class: "pb-3"
}
const _hoisted_4 = { class: "button-container text-center" }
const _hoisted_5 = ["textContent", "data-gtm", "data-gtm-click-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddressSearchComponent = _resolveComponent("AddressSearchComponent")!
  const _component_inputFieldComponent = _resolveComponent("inputFieldComponent")!
  const _component_SearchComponent = _resolveComponent("SearchComponent")!
  const _component_ValidationErrorComponent = _resolveComponent("ValidationErrorComponent")!
  const _component_ExistingAbProductsComponent = _resolveComponent("ExistingAbProductsComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.otherPerson || !_ctx.model.personInfo.zipCode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_AddressSearchComponent, {
            key: "zipCode",
            id: "zipCode",
            autocompleteId: "zipCode",
            isOnlyZipSearch: true,
            label: "Postnummer by",
            placeholder: "F.eks 8000 Århus C",
            defaultValue: _ctx.model.personInfo.zipCode ? (_ctx.model.personInfo.zipCode + ' ' + _ctx.model.personInfo.zipName) : _ctx.defaultZip,
            isValid: _ctx.model.personInfo.zipCode !== undefined,
            readonly: _ctx.readonlyZip,
            onAddressSearch: _ctx.zipSearch
          }, null, 8, ["defaultValue", "isValid", "readonly", "onAddressSearch"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_TransitionGroup, { name: "slide-simple" }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_inputFieldComponent, {
          class: "text-left pb-3",
          key: "customerAge",
          id: "customerAge",
          name: "customerAge",
          placeholder: "F.eks. 42 ",
          label: "Din alder",
          readonly: _ctx.readonlyAge,
          validationClass: _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge) ? 'input--valid' : undefined,
          validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
          innerData: _ctx.model.personInfo,
          required: true
        }, null, 8, ["readonly", "validationClass", "validateOnLoad", "innerData"]), [
          [_vShow, !_ctx.otherPerson && ((_ctx.model.personInfo.zipCode && _ctx.model.personInfo.zipName) || _ctx.readonlyAge)]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SearchComponent, {
            defaultValue: _ctx.model.personInfo.work,
            label: "Hvad arbejder du med?",
            placeholder: "Indtast f.eks Maskintekniker...",
            searchByCleanedInput: true,
            autocompleteId: "work",
            resultList: _ctx.cms.work,
            isValid: _ctx.model.personInfo.workId !== undefined,
            onSearch: _ctx.workSelected,
            onSearchError: _ctx.workSearchError
          }, null, 8, ["defaultValue", "resultList", "isValid", "onSearch", "onSearchError"])
        ], 512), [
          [_vShow, !_ctx.otherPerson && _ctx.model.personInfo.zipCode && _ctx.model.personInfo.zipName && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge)]
        ]),
        (!_ctx.otherPerson && !_ctx.model.pristineStep.get(_ctx.card.name) && _ctx.model.personInfo.work === undefined)
          ? _withDirectives((_openBlock(), _createBlock(_component_ValidationErrorComponent, {
              key: "workError",
              class: "input-error--show mt-n3",
              text: 'Du skal vælge hvad du arbejder med'
            }, null, 512)), [
              [_vShow, _ctx.model.personInfo.zipCode && _ctx.model.personInfo.zipName && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge)]
            ])
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_inputFieldComponent, {
          class: "text-left pb-2",
          key: "otherName",
          id: "otherName",
          name: "otherName",
          placeholder: "Indtast fulde navn",
          label: "Navn",
          validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
          innerData: _ctx.model.personInfo,
          fieldValidatorType: "name",
          validationClass: _ctx.fieldValidator.isValidName(_ctx.model.personInfo.otherName) ? 'input--valid' : undefined,
          required: true,
          autocomplete: "off"
        }, null, 8, ["validateOnLoad", "innerData", "validationClass"]), [
          [_vShow, _ctx.otherPerson && (_ctx.model.personInfo.zipCode && _ctx.model.personInfo.zipName)]
        ]),
        _withDirectives(_createVNode(_component_inputFieldComponent, {
          class: "text-left pb-3",
          key: "otherCustomerAge",
          id: "otherCustomerAge",
          name: "otherCustomerAge",
          placeholder: "F.eks. 42 ",
          label: "Alder",
          fieldValidatorType: "customerAge",
          validationClass: _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.otherCustomerAge) ? 'input--valid' : undefined,
          validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
          innerData: _ctx.model.personInfo,
          required: true
        }, null, 8, ["validationClass", "validateOnLoad", "innerData"]), [
          [_vShow, _ctx.otherPerson && (_ctx.model.personInfo.zipCode && _ctx.model.personInfo.zipName) && _ctx.fieldValidator.isValidName(_ctx.model.personInfo.otherName)]
        ]),
        (_ctx.otherPerson && _ctx.model.personInfo.zipCode && _ctx.model.personInfo.zipName && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.otherCustomerAge))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_SearchComponent, {
                defaultValue: _ctx.model.personInfo.work,
                label: _ctx.workLabel,
                placeholder: "Indtast f.eks Maskintekniker...",
                searchByCleanedInput: true,
                autocompleteId: "work_other",
                resultList: _ctx.cms.work,
                isValid: _ctx.model.personInfo.workId !== undefined,
                onSearch: _ctx.workSelected,
                onSearchError: _ctx.workSearchError
              }, null, 8, ["defaultValue", "label", "resultList", "isValid", "onSearch", "onSearchError"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.otherPerson && !_ctx.model.pristineStep.get(_ctx.card.name) && _ctx.model.personInfo.work === undefined)
          ? _withDirectives((_openBlock(), _createBlock(_component_ValidationErrorComponent, {
              key: "otherworkError",
              class: "input-error--show mt-n3",
              text: 'Vælg arbejde'
            }, null, 512)), [
              [_vShow, _ctx.model.personInfo.zipCode && _ctx.model.personInfo.zipName && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.otherCustomerAge)]
            ])
          : _createCommentVNode("", true),
        (_ctx.model.showExistingAlmBrandProducts && _ctx.model.personInfo.work)
          ? (_openBlock(), _createBlock(_component_ExistingAbProductsComponent, {
              key: "ExistingAbProductsComponent",
              card: _ctx.card
            }, null, 8, ["card"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        class: _normalizeClass([_ctx.card.buttonStyle, 'mt-3', {'btn-disabled': !_ctx.isValid}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextStep())),
        textContent: _toDisplayString(_ctx.card.linkText),
        "data-gtm": _ctx.cms.dataGtm,
        "data-gtm-click-text": _ctx.card.routePath
      }, null, 10, _hoisted_5)
    ])
  ]))
}