export class EnvironmentService {
    /**
     * @returns true if open in App, else false
     */
    static isApp(): boolean {
        if (window.location.href.indexOf("client=app") > -1) {
            return true;
        }

        if((<any>window).nsWebViewBridge !== undefined) {
            return true;
        }
        return false;
    }

    /**
     * @returns true if open in Web, else false
     */
    static isWeb(): boolean {
        return !this.isApp();
    }

    static AppVersion(): Versions {
        if(this.isApp()) {
            return "4.0.3"
        }
        return "1.0.0"
    }
}

export type Versions = "1.0.0" | "4.0.3"