import { Vue, Options} from "vue-class-component";

@Options({
	name: 'PopoverComponent',
	props: {
		innerData: {type: String, required: true },
		size: {type: String, default: 'default' }, // default | large
		type: {type: String, default: 'text' }, // text | icon (for vertical alignment)
		trackingEnabled!: {type: Boolean, default: true }, // tracks to gtm, by default sends event action: popover, label: trackingLabel or data truncated to max 50 chars
		trackingLabel!: String,
		preventCloseInsideClick!: Boolean,
		preferPositionBottom!: Boolean,
		buttonClass: {type: String, default: ''}
	}
})
export default class PopoverComponent extends Vue {
	innerData: string;
	size: string; // default | large
    type: string; // text | icon (for vertical alignment)
    trackingEnabled!: boolean; // tracks to gtm, by default sends event action: popover, label: trackingLabel or data truncated to max 50 chars
    trackingLabel?: string;
	preventCloseInsideClick?: boolean;
	preferPositionBottom?: boolean;
	buttonClass: string;



	isOpen = false;

	public toggle() {
		this.isOpen = !this.isOpen;

		setTimeout(() => {
			if(this.isOpen) {
				this.positionPopoverBox();
			}
		}, 10)
	}

	private close(e) {
		if (!this.isPopoverElement(e.target)) {
    		this.isOpen = false;
		}
	}

	private isPopoverElement(elem: HTMLElement) {
		if (!elem) {
			return false;
		}
		// prevent close if clicked inside popover
		if (this.preventCloseInsideClick) {
			if (elem.classList.contains('close')) {			
				return false;
			}
			return elem.closest('.popover') ? true : false;
		}
		let hit = false;
		elem.classList.forEach( class_ => {
			if (!hit) {
				hit = /popover.*/.test(class_);	
			}
		});
		return hit;
	}

	private positionPopoverBox() {
		const popOverElem = (this.$refs.popover as HTMLElement);
		const popOverArrow = (this.$refs.arrow as HTMLElement);
		const margin = 20;
		const { width, height, top, right, bottom, left } = (this.$refs.popover as Element).getBoundingClientRect();

		const arrowPosition = window.getComputedStyle(popOverArrow).left;
        let newLeftPosition;

        if (right > document.documentElement.clientWidth) { // overflow right
            newLeftPosition = document.documentElement.clientWidth - right - margin;
        } else if (left <= 0) { // overflow left
			newLeftPosition = margin + (left * -1);
		}

		popOverElem.style.left =  `calc(50% + ${newLeftPosition}px)`;
		popOverArrow.style.left = `${+arrowPosition.replace('px', '') - newLeftPosition}px`;
		
		if (this.preferPositionBottom && top - height < 20) {
			popOverElem.classList.add('popover__box--bottom');
			return;
		}
		if (top <= 0) { // overflow top
			popOverElem.classList.add('popover__box--bottom');
		}
	}

	created() {
        window.addEventListener('click', this.close);
	}

	beforeUnmount() {
		window.removeEventListener('click', this.close);
    }

    get trackingAttributes() {
        return this.trackingEnabled ? {
            'data-gtm-action': 'popover',
            'data-gtm-label':  (this.trackingLabel ? this.trackingLabel : this.innerData?.length > 50 ? this.innerData.substring(0, 50) + '...' : this.innerData ),
        } : null;
    }
}

