export default {
  getEpiRouting: (state) => {
    return state.epiRouting;
  },
  getRecaptchaSiteKey: (state) => {
    return state.recaptchaSiteKey;
  },

  getUsabillaState: (state) => {
    return state.usabilla;
  },

  getThemeContext: (state) => {
    const themeContext = state.epiContent?.model?.theme;
    return themeContext ? themeContext : "almbrand";
  },


  /** Digital Accept */
  getActiveProposalStep: (state) => {
    let active = undefined;
    if (state.ProposalContext?.proposalSteps?.size > 0) {
      state.ProposalContext.proposalSteps.forEach((step /*, key*/) => {
        if (!active && !step.isValid) {
          active = step;
        }
      });
      return active;
    }
    console.warn("did not found activeProposalStep", state.ProposalContext);
    return undefined;
  },
  getIsPreviousProposalStepsValid: (state) => (id: string) => {
    let previousValid = true;
    let stop = false;
    if (state.ProposalContext?.proposalSteps?.size > 0) {
      state.ProposalContext.proposalSteps.forEach((step /*, key*/) => {
        if (!stop) {
          if (step.id !== id) {
            if (previousValid && !step.isValid) {
              previousValid = false;
              stop = true;
            }
          } else {
            stop = true;
          }
        }
      });
    }
    return previousValid;
  },

  /** Calculators */

  getActiveCalculator: (state) => {
    return state.calculatorContext[state.calculatorContext.active];
  },
  getCalculator: (state) => (name: string) => {
    return state.calculatorContext[name];
  },
  getActiveCalculatorInx: (state) => {
    return state.calculatorContext.activeInx;
  },
  getCalculatorCommonModel: (state) => {
    return state.calculatorContext.commonModel;
  },
  getBasket: (state) => {
    return state.calculatorContext.basket;
  },
  getBasketHistory: (state) => {
    return state.calculatorContext.basketHistory;
  },
  getConsentSetInApp: (state) => {
    return state.consentSetInApp;
  },
  getOldAppVersionAlerted: (state) => {
    return state.oldAppVersionAlerted;
  },
  getSelectableGroup: (state) => (groupId: string) => {
    return state.selectableContext.selectGroups.get(groupId) ? state.selectableContext.selectGroups.get(groupId) : [];
  },
  getSelectableGroupUnique: (state) => (groupId: string) => {
    const group = state.selectableContext.selectGroups.get(groupId)
      ? state.selectableContext.selectGroups.get(groupId)
      : [];
    const uniqueGroup = [];
    group.forEach((name) => {
      const cleanedName = name.replace(/[0-9]/g, "");
      if (!uniqueGroup.includes(cleanedName)) {
        uniqueGroup.push(cleanedName);
      }
    });
    return uniqueGroup;
  },
  getCustomerCalculation: (state) => {
    return state.calculatorContext.customer;
  },

};
