
import { mapState } from 'vuex';
import ButtonComponent from '@/sharedcomponents/baseComponents/buttonComponent/buttonComponent.vue';
import { Options, Vue } from 'vue-class-component';
import { DogSettings } from '@/views/calculators/dog/DogSettings';
import { BasketSettings } from '@/views/calculators/basket/BasketSettings';

@Options({
    name: 'MessageComponent',
    components: {
        ButtonComponent,
    },
    computed: mapState<any>({
        productCms: state => state.calculatorContext[state.calculatorContext.active].cms,
        basketCms: state => state.calculatorContext.basket.cms,
    }),
    props: {
        useBasketModel: {type: Boolean, default: false}
    }
})

export default class MessageComponent extends Vue {

    public productCms: any; // datastructure for user input (store)
    public basketCms: any; // datastructure for basket
    public useBasketModel: boolean;

    public cms: DogSettings | BasketSettings = null; // settings from CMS (store)
    public static showMessageAll = true;
    public showMessage = false;

    public created() {
        this.cms = this.useBasketModel ? this.basketCms : this.productCms;
        if(!this.cms) {
            return;
        }
        if (!this.hasActiveMessage()) {
            MessageComponent.showMessageAll = false;
            this.showMessage = false;

        } else {
            setTimeout(() => {
                this.showMessage = MessageComponent.showMessageAll;
            }, this.cms.globalMessageTimeout);
        }
        window.addEventListener('click' , this.closeMessage );
        window.addEventListener('keyup' , this.closeMessage );
    }

    public beforeUnmount() {
        this.removeListeners();
    }

    private removeListeners() {
        window.removeEventListener('click', this.closeMessage);
        window.removeEventListener('keyup', this.closeMessage);

    }
    public closeMessage() {
        this.removeListeners();
        MessageComponent.showMessageAll = false;
        this.showMessage = false;
    }

    private hasActiveMessage() {
        if (this.cms.hasGlobalMessage) {
            try {
                const now = new Date();
                // check for multiple dates
                let match = false;
                if (this.cms.globalMessageMultipleDates.length > 0) {
                    match = this.cms.globalMessageMultipleDates.some(date => {
                        const showParsed = date.split('-').map( x => parseInt(x));
                        const show = new Date();
                        show.setDate(showParsed[0]);
                        show.setMonth(showParsed[1] - 1);
                        return (now.getMonth() ===  show.getMonth() && now.getDate() === show.getDate());
                    });
                }

                if (match) {
                    return true;
                }

                // check for single day with timeinterval
                const showDate = this.cms.globalMessageDate.split('-').map( x => parseInt(x));
                const showFrom = this.cms.globalMessageFrom.split(':').map( x => parseInt(x));
                const showTo = this.cms.globalMessageTo.split(':').map( x => parseInt(x));
                const from = new Date();
                from.setDate(showDate[0]);
                from.setMonth(showDate[1] - 1);
                from.setHours(showFrom[0]);
                from.setMinutes(showFrom[1]);

                const to = new Date();
                to.setDate(showDate[0]);
                to.setMonth(showDate[1] - 1);
                to.setHours(showTo[0]);
                to.setMinutes(showTo[1]);

                return (now.getTime() > from.getTime() && now.getTime() < to.getTime());
            } catch (error) {
                // cms err
            }
        }
        return false;
    }
}
