import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'ValidationErrorComponent',
    components: {},
    props: {
        id: String,
        text: String,
        showValidationError: Boolean,
    }
})

export default class ValidationErrorComponent extends Vue {
    id: string;
    text: string;
    showValidationError: boolean;
}
