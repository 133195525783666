import FieldValidator from '@/services/fieldValidatorService';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'VerticalStepComponent',
    components: {},
    props: {
        step: Object,
    },
})
export default class VerticalStepComponent extends Vue {
    public step: any

    public renderPage = false;

    public async mounted() {
        this.setVisible(true, 1500);
        this.renderPage = true;
    }

    private setVisible(focusNext: boolean = false, focusDelay: number = 300): void {
        //this.step.model.isValid = false;
        let focusId: string;
        let isAllValid = true;
        this.step.policyFields.forEach((field, inx) => {
            // check if field is dependant on another field (and value)
            if (field.isDependent) {
                const other = this.step.policyFields.find(other => other.fieldId === field.dependentId);
                if (other?.model.value && other?.model.value !== field.dependentValue) {
                    if(other?.model.value && other?.model.value !== '' && other.isVisible && field.dependentValue === '*') {
                        field.isVisible = true;
                        // todo handle isValid
                        return;                    
                    }
    
                    field.isVisible = false;
                    // todo handle isValid
                    return;
                }

            }
            // normal check
            if (inx > 0) {
                if (this.step.policyFields[inx-1].model.isValid && isAllValid) { // if previous field isValid
                    field.isVisible = true;
                    if (!field.model.isValid) {
                        focusId = field.model.id;
                    }
                } else {
                    if (field.type !== 'Text only') {
                        field.isVisible = false;
                    }
                }
            } else {
                // first field is always visible
                field.isVisible = true;
                if (!field.isValid) {
                    focusId = field.id;
                }
            }
            if (!field.model.isValid) {
                isAllValid = false;
            }
        });

        this.step.isValid = isAllValid;

        if (focusNext && focusId) {
            setTimeout(() => {
                const input = document.getElementById(focusId);
                if (input) {
                    document.getElementById(focusId).focus();    
                }
            }, focusDelay);
        }
        // console.log('state', this.step.policyFields);
        
    }

    public inputChange(field, evt) {
        // console.log('inputchange', field.model.id, evt);
        const fv: FieldValidator = new FieldValidator('default');

        if (fv.isValid(field.model.value)) {
            field.model.isValid = true;
        } else {
            field.model.isValid = false;
        }
        this.setVisible(false);
    }

    public radioClick(field, evt) {
        // console.log('radioClick', field.model.id, evt);
        const fv: FieldValidator = new FieldValidator('default');
        if (fv.isValid(evt?.value)) {
            field.model.value = evt.value;
            field.model.isValid = true;
        } else {
            field.model.isValid = false;
        }
        this.setVisible(true);
    }
    public selectGroup(field, evt) {
        // console.log('selectGroup', field.model.id, evt);
        const fv: FieldValidator = new FieldValidator('default');
        if (fv.isValid(evt?.value)) {
            field.model.value = evt.value;
            field.model.isValid = true;
        } else {
            field.model.isValid = false;
        }
        this.setVisible(true);

    }

    public checkboxEvent(field, evt) {
        // console.log('checkboxEvent', field, evt);
        field.model.value = evt?.checked;
        field.model.isValid = evt?.checked;
        this.setVisible(true);
    }

    public dateSelected(field, value) {
        console.warn('dateSelected TODO validation dates on exit', field.model.id, value);
        if (value) {
            field.model.value = value;
            field.model.isValid = true;
        } else {
            field.model.value = undefined;
            field.model.isValid = false;
        }
        this.setVisible(true);
    }

    public searchSelected(field, evt) {
        // console.log('searchSelected', field, evt);
        if(evt?.matchText) {
            const inx = field.uiList.indexOf(evt.matchText);
            if (inx > -1) {
                const id = field.listIds[inx];
                // console.log('found id', id, 'for', evt.matchText);
                field.model.valueId = id;
                field.model.value = evt.matchText;
                field.model.isValid = true;
                this.setVisible(true);
                return;
            }
        }
        field.model.value = undefined;
        field.model.valueId = undefined;
        field.model.isValid = false;
    }

    public modalSelected(field, evt) {
        // console.log('modalSelected', field, evt);
        if (evt.selectedValue) {
            field.model.value = evt.selectedValue;
            field.model.isValid = true;
            this.setVisible(true);
            return;
        }
        field.model.value = undefined;
        field.model.isValid = false;
    }
}
