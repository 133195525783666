import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutocompleteComponent = _resolveComponent("AutocompleteComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["autocomplete__container text-left", [{'input--valid' : _ctx.isValid && !_ctx.searching}, {'input--searching' : _ctx.searching}]])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "input__label",
          innerHTML: _ctx.label
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_AutocompleteComponent, {
      ref: "AbAutoComplete",
      autocompleteName: "address",
      autocompleteNative: "off",
      onInput: _ctx.search,
      onOnSelect: _ctx.handleSubmit,
      onBlur: _ctx.blur,
      onFocus: _ctx.onFocus,
      results: _ctx.results,
      acRef: "brand",
      autocompleteId: "brand",
      placeholder: "Indtast f.eks Opel Zafira...",
      "aria-label": "Indtast f.eks Opel Zafira...",
      max: 200,
      "input-class": ['autocomplete-input','input__field','autocomplete'],
      "display-item": _ctx.displayItem,
      onAutoCompleteField: _ctx.setAutoCompleteField
    }, null, 8, ["onInput", "onOnSelect", "onBlur", "onFocus", "results", "display-item", "onAutoCompleteField"])
  ], 2))
}