import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = ["data-gtm-url", "data-gtm-label", "href", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.model.newTab)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          "data-gtm-url": _ctx.trackingUrl,
          "data-gtm-action": "tile click",
          "data-gtm-label": _ctx.model.title,
          class: _normalizeClass([_ctx.model.buttonClass, { 'btn-disabled-alt': _ctx.disabled }]),
          href: _ctx.model.relativeURL,
          target: "_blank",
          innerHTML: _ctx.model.title
        }, null, 10, _hoisted_2))
      : (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: _ctx.model.relativeURL,
          "data-gtm-url": _ctx.trackingUrl,
          "data-gtm-action": "tile click",
          "data-gtm-label": _ctx.model.title,
          class: _normalizeClass([_ctx.model.buttonClass, { 'btn-disabled-alt': _ctx.disabled }]),
          innerHTML: _ctx.model.title
        }, null, 8, ["to", "data-gtm-url", "data-gtm-label", "class", "innerHTML"]))
  ]))
}