import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex text-left mt-3 mb-3" }
const _hoisted_2 = { class: "grow strong" }
const _hoisted_3 = { class: "strong" }
const _hoisted_4 = {
  key: 0,
  class: "button-container text-center"
}
const _hoisted_5 = {
  key: "updateSuggestKey",
  class: "highlights fill mb-4 mt-5"
}
const _hoisted_6 = { class: "text-left" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OverviewCalculatorStepComponent = _resolveComponent("OverviewCalculatorStepComponent")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PopoverComponent = _resolveComponent("PopoverComponent")!

  return (_ctx.cms)
    ? (_openBlock(), _createElementBlock("div", {
        class: "vertical-app vertical-flow vertical-flow__form__step__content mt-n3",
        key: _ctx.updateProdKey,
        focus: ""
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productNames, (productName, inx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'productName' + inx + _ctx.updateProdKey
          }, [
            _createVNode(_component_OverviewCalculatorStepComponent, { productName: productName }, null, 8, ["productName"])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createElementVNode("div", _hoisted_2, "Samlet pris", 512), [
            [_vShow, _ctx.hasPrices]
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getMonthlyTotalPrice(true)), 1)
        ]),
        (_ctx.showButtons)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_router_link, {
                style: {"border-color":"initial"},
                class: "text-center btn-link-secondary mt-3",
                to: _ctx.forsikringerLink,
                textContent: _toDisplayString(_ctx.cms.addProductsTxt),
                "data-gtm": _ctx.cms.dataGtm,
                "data-gtm-click-text": _ctx.cms.addProductsTxt
              }, null, 8, ["to", "textContent", "data-gtm", "data-gtm-click-text"]),
              (_ctx.productNames.length > 1)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: "text-center btn-solid-secondary mt-3",
                    to: _ctx.basketLink,
                    textContent: 'Gå til kurv',
                    "data-gtm": _ctx.cms.dataGtm,
                    "data-gtm-click-text": 'basket'
                  }, null, 8, ["to", "data-gtm"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.suggestText)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", { innerHTML: _ctx.suggestText }, null, 8, _hoisted_7),
                (_ctx.cms.plusCustomerPopover)
                  ? (_openBlock(), _createBlock(_component_PopoverComponent, {
                      key: 0,
                      type: "icon",
                      class: "page-component__popover text-left",
                      buttonClass: "popover__icon popover__icon__questionmark",
                      preferPositionBottom: true,
                      innerData: _ctx.cms.plusCustomerPopover
                    }, null, 8, ["innerData"]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", { innerHTML: _ctx.suggestText1 }, null, 8, _hoisted_8)
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}