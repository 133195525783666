import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "validatable" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["type", "id", "name", "placeholder", "data-t-input", "required", "value", "readonly"]
const _hoisted_4 = {
  key: 1,
  class: "validatable"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "flex input__field disabled input--valid" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopoverComponent = _resolveComponent("PopoverComponent")!
  const _component_ValidationErrorComponent = _resolveComponent("ValidationErrorComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.readonlyValue)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.validationClass)
        }, [
          (_ctx.type !== 'hidden')
            ? (_openBlock(), _createElementBlock("label", {
                key: 0,
                for: _ctx.id,
                class: "input__label"
              }, _toDisplayString(_ctx.label), 9, _hoisted_2))
            : _createCommentVNode("", true),
          (_ctx.type !== 'hidden' && _ctx.labelPopover)
            ? (_openBlock(), _createBlock(_component_PopoverComponent, {
                key: 1,
                type: "icon",
                class: "page-component__popover text-left",
                buttonClass: "popover__icon popover__icon__questionmark",
                innerData: _ctx.labelPopover
              }, null, 8, ["innerData"]))
            : _createCommentVNode("", true),
          _createElementVNode("input", {
            class: _normalizeClass(["input__field", _ctx.hasError && 'error']),
            type: _ctx.type,
            id: _ctx.id,
            name: _ctx.name,
            ref: _ctx.name,
            placeholder: _ctx.placeholder,
            "___:inputmode": "getInputMode()",
            "data-t-input": _ctx.name,
            required: _ctx.required,
            value: _ctx.htmlInputValue,
            readonly: _ctx.readonly,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
            onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
            onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
          }, null, 42, _hoisted_3)
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("label", {
                key: 0,
                class: "input__label",
                innerHTML: _ctx.label
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", { innerHTML: _ctx.readonlyValue }, null, 8, _hoisted_7)
          ])
        ])),
    _createVNode(_component_ValidationErrorComponent, {
      text: _ctx.validationErrorFormatText,
      showValidationError: _ctx.hasError
    }, null, 8, ["text", "showValidationError"])
  ]))
}