
export default class Adapter {
    public id: string;
    public title: string;
    public description: string;

    public price?: number;
    public selected?: boolean;
    public included?: boolean;

    public constructor(id: string, title: string, description: string, price = -1, selected = false) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.price = price;
        this.selected = selected;
    }

}