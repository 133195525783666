import { Vue, Options} from "vue-class-component";
import UrlUtilService from '@/services/urlUtilService';
import { mapState } from "vuex";

@Options({
    name: 'LinkComponent',
    components: {},
    inheritAttrs: false,
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable
    }),
    props: {
        to: {type: String, required: true },
        target: {type: String, default: '_blank' }
    }
})

export default class LinkComponent extends Vue {
    to: string;
    isEditable!: boolean;
    /**
        * The target on the link, only used if the link is external
        * */
    target: string;
        
    public getPath(): string {
        return UrlUtilService.ensureCorrectLink(this.to);
    }

    public isExternalLink() {
        //when in edit mode we dont have access to a router and router-view wont work so we treat all links as external in that case
        return UrlUtilService.isExternalLink(this.to) || !this.$router;
    }
        
}
