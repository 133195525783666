import { ActionContext, Dispatch } from "vuex";

export const UPDATE_AUTH = "authentication/UPDATE_AUTH";

const UPDATE_AUTHENTICATION = "authentication/UPDATE_AUTHENTICATION";

export interface AuthenticationState {
    isLoaded: boolean,
    authenticated: boolean,
    expire: number
}

const state: AuthenticationState = {
    isLoaded: false,
    authenticated: false,
    expire: 0
}

const mutations = {
    [UPDATE_AUTHENTICATION](state: AuthenticationState, resp: any) {
        const expire: Date = new Date(resp.expire);
        const expireNumber = expire.getTime()
        state.isLoaded = true;
        state.authenticated = resp.authenticated;
        state.expire = expireNumber;
    },
}

const actions = {
    async [UPDATE_AUTH]({ commit, state, dispatch }: ActionContext<AuthenticationState, any>) {
        if (!state.isLoaded) {
            const url = window.location.protocol + "//" + window.location.host + "/api/v1/auth/tokenexpire";
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status == 200) {
                const data = await response.json();
                commit(UPDATE_AUTHENTICATION, data);
            }
            
        }

    }
}

export default {
    state,
    mutations,
    actions
};
