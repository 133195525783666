import { SelectableData, UPDATE_SELECTABLE, SELECTABLE_UPDATED } from '@/store/modules/selectableContext';
import { BASKET_CURRENT, DISCOUNT_UPDATE, RESET_CALCULATORS } from '@/store/modules/calculatorContext';
import OverviewBasketMiniStepComponent from '../steps/basketMini/OverviewBasketMiniStepComponent.vue';
import { BuyInsuranceHelper, Formatter } from '../BuyInsuranceHelper';
import UrlUtilService from '@/services/urlUtilService';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import UrlHandler from '../UrlHandler';
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import GtmService from '@/services/gtmService';

@Options({
    name: 'BasketWidgetComponent',
    components: {
        OverviewBasketMiniStepComponent
    },
    props: {
        productName: {type: String, default: undefined}, // productname for tracking and Basket
        showBasket: {type: Boolean, default: true},
        forceOpen: {type: Boolean, default: false},
    }
})

export default class BasketWidgetComponent extends Vue {
    productName?: string; // productname for tracking and Basket
    showBasket?: boolean;
    forceOpen?: boolean;

    public basket: Array<string> = [];
    public productsInBasket: number = 0;
    private unsubscribe: Function;
    public discount: string = '';

    public beforeUnmount() {
        this.unsubscribe();
    }

    public async mounted() {

        // make sure current product is in basket
        const uniqueBasket = store.getters.getSelectableGroupUnique('basket');
        if (this.productName && !uniqueBasket.includes(this.productName)) {
            const toggle: SelectableData = {
                group: 'basket',
                id: this.productName + '0',
                isSelected: true,
                sortMultiple: true,
            }
            await store.dispatch(UPDATE_SELECTABLE, toggle);
        }

        this.setBasket();

        this.unsubscribe = store.subscribeAction((action, state) => {
            if (action.type === SELECTABLE_UPDATED && action.payload?.group === 'basket') {
                this.setBasket();
                this.setDicountUi();
            } else if (action.type === RESET_CALCULATORS) {
                this.discount = '';
            } else if(action.type === DISCOUNT_UPDATE) {
                this.setDicountUi();
            }
        });
        this.setDicountUi();
    }

    public get showContent(): boolean {
        return this.forceOpen || this.productName !== undefined;
    }

    public setDicountUi() {
        this.discount = '';
        const cms = store.getters.getActiveCalculator?.cms || store.getters.getBasket?.cms;
        if (cms) {
            const discount = BuyInsuranceHelper.getDiscount(store, cms);
            if (discount.discountDisplay) {
                this.discount = cms.miniBasketDiscountPreTxt + discount.discountDisplay + cms.miniBasketDiscountPostTxt;
            }
        }
        this.handleDiscountChange();
    }

    private setBasket(): void {
        const previousBasket = store.getters.getBasketHistory;

        this.basket = store.getters.getSelectableGroup('basket');

        this.productsInBasket = this.basket.length;

        new Promise((resolve) => {
            // find add
            const addToTracking = [];
            this.basket.forEach(name => {
                if (!previousBasket.includes(name)) {
                    addToTracking.push(name);
                }
            });

            // find remove
            const removeFromTracking = [];
            previousBasket.forEach(name => {
                if (!this.basket.includes(name)) {
                    removeFromTracking.push(name);
                }
            });
            if (addToTracking.length > 0 || removeFromTracking.length > 0) {
                this.handleTracking(addToTracking, 'addToCart');
                this.handleTracking(removeFromTracking, 'removeFromCart');
                store.dispatch(BASKET_CURRENT, this.basket);
            }
            Promise.resolve();
        });
    }

    private handleTracking(products: Array<string>, addRemove: string): void {
        if (products.length > 0) {
            const prods: Array<any> = [];
            products.forEach(name => {
                prods.push({
                    name,
                    id: name,
                    brand: 'Fakedoor flow',
                    category: 'Online Salg',
                    quantity: 1
                });
            });
            this.pushToEcommerce(prods, addRemove);
        }
    }

    private pushToEcommerce(products: Array<any>, addRemove: string): void{
        GtmService.triggerCustomGtmEvent({ ecommerce: null });  // Clear the previous ecommerce object.
        GtmService.triggerCustomGtmEvent({
          event: addRemove,
          ecommerce: {
            currencyCode: 'DKK',
            add: {
              products,
            }
          }
        });
    }
    public get forsikringerLink() {
        const url = UrlUtilService.ensureCorrectLink(UrlHandler.path + 'forsikringer');
        return url;
    }

    private handleDiscountChange() {
        const cms = store.getters.getActiveCalculator?.cms || store.getters.getBasket?.cms;
        const rebate = BuyInsuranceHelper.getDiscount(store, cms);
        const basket = store.getters.getSelectableGroup('basket');

        if (store.getters.getBasket?.model) {
            const model = store.getters.getBasket?.model;
            if (rebate.discount !== model.calculation.discount) {
                model.calculation.discount = rebate.discount;
                model.calculation.discountDisplay = rebate.discountDisplay;
            }
        }

        basket.forEach( (product) => {
            const calc = store.getters.getCalculator(product);
            if(calc) {
                let update = false;
                if (calc.model.campaign.valid && calc.model.campaign.productStepDiscount) {
                    // possible change of campaign discount
                    const calculatedDiscount = BuyInsuranceHelper.getDiscountForAmountProducts(calc.model.campaign)
                    if (calc.model.campaign.discount !== calculatedDiscount) {
                        calc.model.campaign.discount = calculatedDiscount;
                        calc.model.campaign.originalDiscount = calc.model.campaign.discount;
                        calc.model.campaign.discountDisplay = Formatter.format(100 * calc.model.campaign.discount) + ' %';
                        update = true;
                    }
                }

                if (calc.model.choosePackage.selectedPackage) {
                    if (update || calc.model.calculation.discount !== rebate.discount) {
                        calc.model.overview.highlights = undefined;
                        this.changeDiscountOncalculation(calc, rebate);
                    }

                }
            }
        });
    }

    private changeDiscountOncalculation(calculation: any, rebate: {discount: number, discountDisplay: string}) {
        const selectedPackage = calculation.model.choosePackage.selectedPackage;

        if (calculation.model.productName === InsurancesProductEnum.HUNDE_FORSIKRING &&
            selectedPackage.id === calculation.cms.sickAccidentpackageId) {
            if (rebate.discount === 1) { // not pluscustomer - remove package
                BuyInsuranceHelper.resetSelectedCalculation(calculation);
                // TODO KLK en popup, der forklare at dækningen ikke kan købes
                return;
            }

            if (calculation.model.calculation.discount === 0.95 && rebate.discount !== 0.95) { // .9 | .85
                selectedPackage.basePrice = selectedPackage.basePrice - selectedPackage.specialFee;
                selectedPackage.yearlyBasePrice = selectedPackage.yearlyBasePrice - selectedPackage.yearlySpecialFee;
            }

            if (rebate.discount === 0.95 && calculation.model.calculation.discount !== 0.95) { // .9 | .85
                selectedPackage.basePrice = selectedPackage.basePrice + selectedPackage.specialFee;
                selectedPackage.yearlyBasePrice = selectedPackage.yearlyBasePrice + selectedPackage.yearlySpecialFee;
            }
        }

        calculation.model.calculation.discount = rebate.discount;
        calculation.model.calculation.discountDisplay = rebate.discountDisplay;

        // handle campaigns including pluscustomer discount
        this.handleIncludeDiscountPlusCustomer(calculation);

        const special = BuyInsuranceHelper.handleSpecialCarDiscounts(calculation);
        selectedPackage.specialDiscount = special.specialDiscount;

        // monthly
        let totalPrice;
        let statutoryFee;
        if (calculation.model.productName === InsurancesProductEnum.BOERNEULYKKES_FORSIKRING) {
            statutoryFee = selectedPackage.summedStatutoryFee;
            totalPrice = selectedPackage.summedBasePrice - statutoryFee;
        } else {
            statutoryFee = selectedPackage.statutoryFee;
            totalPrice = selectedPackage.basePrice - statutoryFee;

        }

        // pluscustomer discount
        totalPrice *= calculation.model.calculation.discount;

        // special discount
        if (selectedPackage.specialDiscount)  {
            totalPrice -= (totalPrice * selectedPackage.specialDiscount);
        }
        // campaign discount
        if (calculation.model.campaign.valid)  {
            totalPrice -= (totalPrice * calculation.model.campaign.discount);
        }

        selectedPackage.totalPrice = Math.round( totalPrice + statutoryFee);
        selectedPackage.totalPriceDisplay = Formatter.format(selectedPackage.totalPrice) + ' kr.';


        // Yearly
        if (calculation.model.productName === InsurancesProductEnum.BOERNEULYKKES_FORSIKRING) {
            statutoryFee = selectedPackage.summedYearlyStatutoryFee;
            totalPrice = selectedPackage.summedYearlyBasePrice - statutoryFee;
        } else {
            statutoryFee = selectedPackage.yearlyStatutoryFee;
            totalPrice = selectedPackage.yearlyBasePrice - statutoryFee;
        }

        // plusCustomer discount
        totalPrice *= calculation.model.calculation.discount;

        // special discount
        if (selectedPackage.specialDiscount)  {
            totalPrice -= (totalPrice * selectedPackage.specialDiscount);
        }
        // campaign discount
        if (calculation.model.campaign.valid)  {
            totalPrice -= (totalPrice * calculation.model.campaign.discount);
        }

        selectedPackage.yearlyPriceTotal = Math.round( totalPrice + statutoryFee);
        selectedPackage.yearlyPriceTotalDisplay = Formatter.format(selectedPackage.yearlyPriceTotal) + ' kr.';
        BuyInsuranceHelper.setHighLights_(calculation);
    }

    private handleIncludeDiscountPlusCustomer(calculation) {
        // TODO KLK handle TIA discount
        if (calculation.model.campaign.valid && calculation.model.campaign.includeDiscountPlusCustomer) {
            calculation.model.campaign.discount = calculation.model.campaign.originalDiscount + (calculation.model.calculation.discount - 1);
            calculation.model.campaign.discountDisplay = Formatter.format(100 * calculation.model.campaign.discount) + ' %';
        }
    }


}
