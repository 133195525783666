import AxiosService from '@/services/axiosService';
import store from '@/store/store';
import { defaultConfig } from '@/cms/api/ApiConfig';
export default class EmailService {
    private axiosService = new AxiosService();

    public async sendMailToOssNoLogin(params: Map<string, string>): Promise<any> {
        return await this.sendMail(params,  defaultConfig.baseUrl + store.state.mailOSSNoLogin);
    }

    public async sendMailToTeamNoLogin(params: Map<string, string>): Promise<any> {
        return await this.sendMail(params, defaultConfig.baseUrl + store.state.teamMailOSSNoLogin);
    }

    // til- og fravalg
    // public async sendMailToTeam(topic: string, subject: string, description: string): Promise<any> {
    //     // used to be axiosService.abPostWithToken
    //     return await this.axiosService.post( defaultConfig.baseUrl + store.state.mailTeamUrl,
    //         {
    //             topic,
    //             description,
    //             subject
    //         });
    // }

    // til- og fravalg
    // public async sendMailToOss(params: Map<string, string>): Promise<any> {
    //     return this.sendMail(params, defaultConfig.baseUrl + store.state.mailOSSUrl);
    // }


    // public async sendMailToCustomerService(params: Map<string, string>): Promise<any> {
    //     return this.sendMail(params, store.state.mailCustomerServiceUrl);
    // }

    private async sendMail(params: Map<string, string>, to: string): Promise<any> {
        let obj = Array.from(params).reduce((obj, [key, value]) => (
            Object.assign(obj, { [key]: value })
        ), {});
        return await this.axiosService.post(to, obj);
        // return this.abAxios.abPostWithToken(to,
        //     obj,
        //     "application/json",
        //     false,
        //     () => { },
        //     () => { },
        //     '').then((response) => {
        //         // console.log(response)
        //         return Promise.resolve();
        //     }, (err) => {
        //         console.error('error', err);
        //         return Promise.reject();
        //     });

    }
}
