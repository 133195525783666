import { Options, Vue } from 'vue-class-component';
import PageTitleComponent from '@/sharedcomponents/pageComponents/pageTitle/PageTitleComponent.vue';
import { ReceiptPage} from '@/cms/definitions/content-types';
import store from '@/store/store';
import CookieFooter from "@/sharedcomponents/pageComponents/cookieFooter/cookieFooter.vue";
import LogoutService from '@/services/logoutService';
import TestConfig from './TestData/TestData';
import VerticalStepComponent from './VerticalStepComponent.vue'
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
import { UsabillaInPageBlock } from '@/cms/definitions/content-types';

import ModelMapper from './ModelMapper';

@Options({
    name: 'ReceiptComponent',
    components: {
        PageTitleComponent,
        // ContactComponent,
        VerticalStepComponent,
        UsabillaInPageComponent,
        CookieFooter,
    },
    props: {
        model: Object,
    }
})

export default class ReceiptComponent extends Vue {

    public model: ReceiptPage; // epi contentModel
    public usabillaBlock: UsabillaInPageBlock = null; // epi contentModel
    public usabillaBlockLeaveIntent: any = null;
    public renderPage = false;
    public headline: string = '...';
    private receipt: any;
    public policy: any;
    public proposalIdText: string;

    public beforeCreate() {
        LogoutService.handleIdleLogout('/logout', 30); // TODO KLK - remove data from session(?)
    }

    public async beforeMount() {
        
        store.state.showSpinner = true;
        this.handlePreTitle();
        window.addEventListener('resize', this.handlePreTitle);
        this.receipt = TestConfig.getReceiptHardCoded();        
        const modelMapper: ModelMapper = new ModelMapper(this.receipt, {});
        this.policy = modelMapper.getReceiptPolicyDetails();
        this.proposalIdText = "Kvittering for tilbudsnummer: " + store.state.ProposalContext.offerId;
        this.setupUsabilla();
        

        // TODO avoid timeout
        setTimeout(() => {
            this.renderPage = true;
            store.state.showSpinner = false;
        }, 500);

    }

    public beforeUnmount() {
        // Todo klk proper Vue event
		window.removeEventListener('resize', this.handlePreTitle);
	}

    private setupUsabilla(): void {
        const items = this.model.contentArea?.items;
        if (items?.length > 0) {
            items.forEach(block => {
                if (block?.content.contentType === "UsabillaInPageBlock") {
                    this.usabillaBlock = block.content;
                }
            });
        }
    }

    public get showSpinner() {
        return store.state.showSpinner;
    }
    private handlePreTitle() {
        this.headline = this.model.headline;
        // screen.width < 768 ? this.preTitle = this.cms.shortTitle : this.preTitle = this.model.preTitle;
    }

    public nextStep() {
        location.href="https://www.almbrand.dk";
    }

}
