import { mapState } from 'vuex';
import FieldValidator from '@/services/fieldValidatorService';
import { Validator } from '../../BuyInsuranceHelper';
import { CarSettings } from '../../car/CarSettings';
import ExistingAbProductsComponent from '../../components/ExistingABProductsComponent.vue'
import { Options, Vue } from 'vue-class-component';
import { nextTick, PropType } from 'vue';
import { CardBlock } from '@/cms/definitions/content-types';
import { CustomerCalculation } from '@/store/modules/calculatorContext';
import store from '@/store/store';


@Options({
    name: 'PersonInfoStepComponent',
    props: {
        card: Object as PropType<CardBlock>, // Card from cms
        isNewDriver: Boolean, // wether person has any driving experience
        isValid: Boolean, // wether card is complete
    },
    components: {
        ExistingAbProductsComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
})

export default class PersonInfoStepComponent extends Vue {
    card: CardBlock; // Card from cms
    isNewDriver: boolean; // wether person has any driving experience
    isValid: boolean; // wether card is complete

    public model!: any; // datastructure for user input (store)
    public cms!: CarSettings; // settings from CMS (store)

    public updateCarOwnerYears = 'updateCarOwnerYears';
    public fieldValidator: FieldValidator = Validator;
    public readonlyAge: string = null;
    public defaultAddress: string = null;
    public readonlyAddress: string = null;
    public readonlyCarOwnerYears: string = null;


    public created () {
        const addressParam = this.$route.query.address as string;
        if (addressParam) {
            this.defaultAddress = addressParam;
        }
    }

    public mounted() {
        const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
        if (!this.model.personInfo.address || customerCalculation.hasCustomerData) {
            // wait for transition to begin showing address field
            setTimeout(() => {
                const input = (document.querySelector('#person_info .autocomplete-input') as HTMLInputElement);
                if (input) {
                    input.focus();
                }

            }, this.cms.animationTimeout);
        }
        if (this.model.personInfo.customerAge) {
            this.setCarOwnerArray();
        }

        if (this.model.multipleProducts) {
            const commonModel = store.getters.getCalculatorCommonModel;
            if (commonModel.personInfo.address) {
                this.readonlyAddress = this.model.personInfo.address;
            }
            if (this.fieldValidator.isValidAge(commonModel.personInfo.customerAge)) {
                this.readonlyAge = this.model.personInfo.customerAge;
            }

            const basket = store.getters.getSelectableGroup('basket');
            const activeIndex = store.getters.getActiveCalculatorInx;

            basket.forEach(product => {
                if (product.includes(this.model.productName) && product !== this.model.productName + activeIndex) {
                    const calc = store.getters.getCalculator(product);
                    const carOwnerYears = calc?.model?.personInfo.carOwnerYears;
                    if (calc?.model?.personInfo) {
                        if (calc.model.personInfo.carOwnerYears) {
                            this.model.personInfo.carOwnerYears = carOwnerYears;
                            this.readonlyCarOwnerYears = carOwnerYears;
                            this.updateCarOwnerYears += 1;
                        }
                        if (!this.readonlyAddress && calc.model.personInfo.address) {
                            this.model.personInfo.address = calc.model.personInfo.address
                            this.readonlyAddress = this.model.personInfo.address;
                        }
                        if (!this.readonlyAge && this.fieldValidator.isValidAge(calc.model.personInfo.customerAge)) {
                            this.model.personInfo.customerAge = calc.model.personInfo.customerAge;
                            this.readonlyAge = this.model.personInfo.customerAge;
                        }

                    }
                }
            });
        }
        window.addEventListener('keyup' , this.moveOn );
    }

    public beforeUnmount() {
        window.removeEventListener('keyup', this.moveOn);
    }

    public moveOn(evt) {
        if (evt.keyCode === 13 && this.isValid) {
            this.nextStep();
        }
    }

    public addressSearch(address) {
        if (address.matchText) {
            this.model.personInfo.address = address.matchText;
            this.model.personInfo.zipCode = address.data.postnr;
            this.model.personInfo.zipName = address.data.postnrnavn;
            this.model.personInfo.streetName = address.data.vejnavn;
            this.model.personInfo.houseNr = address.data.husnr;
            this.model.personInfo.floorNr = address.data.etage;
            this.model.personInfo.doorNr = address.data.dør;
            this.model.personInfo.kvhxValue = address.data.kvhx;
            this.model.pristineStep.set(this.card.name, true);
            if (!this.model.personInfo.customerAge) {
                nextTick( () => {
                    // wait for transition to begin showing customerAge field
                    setTimeout(() => {
                        const input = document.getElementById('customerAge');
                        if (input) {
                            input.focus();
                        }

                    }, 300);
                });
            } else {
                this.setCarOwnerArray();
                nextTick( () => {
                    setTimeout(() => {
                            (document.activeElement as any).blur();
                    }, 300);
                });
            }
            return;
        }

        this.model.personInfo.address = undefined;
        this.model.personInfo.zipCode = undefined;
        this.model.personInfo.zipName = undefined;
        this.model.personInfo.streetName = undefined;
        this.model.personInfo.houseNr = undefined;
        this.model.personInfo.floorNr = undefined;
        this.model.personInfo.doorNr = undefined;
        this.model.personInfo.kvhxValue = undefined;
    }


    public setCarOwnerArray() {
        if (this.fieldValidator.isValidAge(this.model.personInfo.customerAge)) {
            this.model.personInfo.customerAge = this.model.personInfo.customerAge.trim().replace(/\D/g,''); // clean customerAge input
            const maxCarOwnerYears = this.model.personInfo.customerAge - 17; // if customerAge = 18, then user could have 1 year experience
            this.model.personInfo.carOwnerYearsByAgeArray = this.cms.carOwnerYears.slice(0, maxCarOwnerYears + 1); // since first choice is no experience driving
            if (this.model.personInfo.carOwnerYears && !this.model.personInfo.carOwnerYearsByAgeArray.includes(this.model.personInfo.carOwnerYears)) {
                this.model.personInfo.carOwnerYears = undefined;
                this.updateCarOwnerYears += 1;
            }
        }
    }

    public selectCarOwnerYears(evt) {
        this.model.personInfo.carOwnerYears = evt.selectedValue;
        if (this.isNewDriver) {
            this.model.additionalInfo.existFormerInsurance === undefined;
            this.model.additionalInfo.existInsurance === undefined;
        }
    }

    public nextStep() {
        this.$emit('nextStep', this.card.routePath);
    }


}
