import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "vertical-flow" }
const _hoisted_2 = {
  key: 1,
  class: "row no-gutters"
}
const _hoisted_3 = { class: "vertical-app badge" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["id"]
const _hoisted_6 = ["onClick", "data-gtm", "data-gtm-click-text"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = { class: "vertical-flow__form__step__text" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "vertical-flow__form__step__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleModalComponent = _resolveComponent("ToggleModalComponent")!
  const _component_CampaignStepComponent = _resolveComponent("CampaignStepComponent")!
  const _component_FamilyInfoStepComponent = _resolveComponent("FamilyInfoStepComponent")!
  const _component_PersonInfoStepComponent = _resolveComponent("PersonInfoStepComponent")!
  const _component_ChoosePackageStepComponent = _resolveComponent("ChoosePackageStepComponent")!
  const _component_AdditionalInfoStepComponent = _resolveComponent("AdditionalInfoStepComponent")!
  const _component_OverviewStepComponent = _resolveComponent("OverviewStepComponent")!
  const _component_ContactInformationStepComponent = _resolveComponent("ContactInformationStepComponent")!
  const _component_PaymentStepComponent = _resolveComponent("PaymentStepComponent")!
  const _component_ReceiptStepComponent = _resolveComponent("ReceiptStepComponent")!
  const _component_usabillaInPageComponent = _resolveComponent("usabillaInPageComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.model?.showSpinner)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(['spinner-full-page', { 'spinner-full-page__subtext' : _ctx.model?.calculating}])
        }, null, 2))
      : _createCommentVNode("", true),
    _createVNode(_component_ToggleModalComponent),
    (_ctx.model?.showHeaderBadge && _ctx.componentInit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              innerHTML: _ctx.headerBadgeTxt,
              class: "calculator-badge"
            }, null, 8, _hoisted_4)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.componentInit && !_ctx.model?.showReceipt && !_ctx.checkout)
      ? (_openBlock(), _createElementBlock("div", {
          key: _ctx.model.updateAll
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentBlocks, (card, inx) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'step' + inx
            }, [
              _createElementVNode("div", {
                id: card.name,
                class: _normalizeClass(['vertical-flow__form__step',
                            _ctx.isActiveCard(card.name) ? 'vertical-flow__form__step--active' : 'vertical-flow__form__step--inactive',
                            _ctx.isValid(card.name) && !_ctx.isActiveCard(card.name)? 'vertical-flow__form__step--validated' : ''])
              }, [
                _createElementVNode("div", {
                  class: "vertical-flow__form__step__title",
                  onClick: ($event: any) => (_ctx.nextStep(card.name)),
                  "data-gtm": _ctx.cms.dataGtm,
                  "data-gtm-click-text": card.name
                }, [
                  _createElementVNode("div", {
                    class: "vertical-flow__form__step__number",
                    textContent: _toDisplayString((inx + 1))
                  }, null, 8, _hoisted_7),
                  _createElementVNode("div", _hoisted_8, [
                    (card.title)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "main",
                          innerHTML: _ctx.getCardTitle(card)
                        }, null, 8, _hoisted_9))
                      : _createCommentVNode("", true),
                    (_ctx.getSubtitle(card.name) && !_ctx.isActiveCard(card.name) || _ctx.getSubtitle(card.name))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "validation-message",
                          innerHTML: _ctx.getSubtitle(card.name)
                        }, null, 8, _hoisted_10))
                      : _createCommentVNode("", true)
                  ])
                ], 8, _hoisted_6),
                _createVNode(_Transition, { name: "slide-vertical" }, {
                  default: _withCtx(() => [
                    (_ctx.isActiveCard(card.name))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(['vertical-flow__form__step__accordion', card.textCenter ? 'text-center' : ''])
                        }, [
                          _createElementVNode("div", _hoisted_11, [
                            (card.name === _ctx.model.campaign.ID)
                              ? (_openBlock(), _createBlock(_component_CampaignStepComponent, {
                                  key: 0,
                                  card: card,
                                  onNextStep: _ctx.nextStep
                                }, null, 8, ["card", "onNextStep"]))
                              : (card.name === 'children_info')
                                ? (_openBlock(), _createBlock(_component_FamilyInfoStepComponent, {
                                    key: 1,
                                    card: card,
                                    isValid: _ctx.isValid(card.name),
                                    onNextStep: _ctx.nextStep
                                  }, null, 8, ["card", "isValid", "onNextStep"]))
                                : (card.name === 'person_info')
                                  ? (_openBlock(), _createBlock(_component_PersonInfoStepComponent, {
                                      key: 2,
                                      card: card,
                                      isValid: _ctx.isValid(card.name),
                                      onNextStep: _ctx.nextStep
                                    }, null, 8, ["card", "isValid", "onNextStep"]))
                                  : (card.name === 'choose_package')
                                    ? (_openBlock(), _createBlock(_component_ChoosePackageStepComponent, {
                                        key: 3,
                                        card: card,
                                        isValid: _ctx.isValid(card.name),
                                        showExcessChoice: false,
                                        showSums: false,
                                        calculator: _ctx.calculator,
                                        onNextStep: _ctx.nextStep
                                      }, null, 8, ["card", "isValid", "calculator", "onNextStep"]))
                                    : (card.name === 'additional_info')
                                      ? (_openBlock(), _createBlock(_component_AdditionalInfoStepComponent, {
                                          key: 4,
                                          card: card,
                                          isValid: _ctx.isValid(card.name),
                                          showInsuranceChoices: true,
                                          existingInsuranceLabel: _ctx.cms.existingInsuranceText,
                                          showValidNowOption: _ctx.showValidNowOption,
                                          onNextStep: _ctx.nextStep
                                        }, null, 8, ["card", "isValid", "existingInsuranceLabel", "showValidNowOption", "onNextStep"]))
                                      : (card.name === 'overview')
                                        ? (_openBlock(), _createBlock(_component_OverviewStepComponent, {
                                            key: 5,
                                            card: card,
                                            isValid: _ctx.isValid(card.name),
                                            onGotoAddInsurances: _ctx.gotoAddInsurances,
                                            onNextStep: _ctx.nextStep
                                          }, null, 8, ["card", "isValid", "onGotoAddInsurances", "onNextStep"]))
                                        : (card.name === 'contact_information')
                                          ? (_openBlock(), _createBlock(_component_ContactInformationStepComponent, {
                                              key: 6,
                                              card: card,
                                              isValid: _ctx.isValid(card.name),
                                              onNextStep: _ctx.nextStep
                                            }, null, 8, ["card", "isValid", "onNextStep"]))
                                          : (card.name === 'payment')
                                            ? (_openBlock(), _createBlock(_component_PaymentStepComponent, {
                                                key: 7,
                                                card: card,
                                                isValid: _ctx.isValid(card.name),
                                                onNextStep: _ctx.nextStep
                                              }, null, 8, ["card", "isValid", "onNextStep"]))
                                            : _createCommentVNode("", true)
                          ])
                        ], 2))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ], 10, _hoisted_5)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.showReceipt)
      ? (_openBlock(), _createBlock(_component_ReceiptStepComponent, {
          key: 3,
          card: _ctx.cardReceipt,
          usabillaBlock: _ctx.usabillaBlock
        }, null, 8, ["card", "usabillaBlock"]))
      : _createCommentVNode("", true),
    (!_ctx.showReceipt && _ctx.usabillaBlockLeaveIntent)
      ? (_openBlock(), _createBlock(_component_usabillaInPageComponent, {
          key: 4,
          style: {"display":"none"},
          model: _ctx.usabillaBlockLeaveIntent
        }, null, 8, ["model"]))
      : _createCommentVNode("", true)
  ]))
}