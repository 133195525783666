import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, TransitionGroup as _TransitionGroup, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: "childrenCpr" }
const _hoisted_2 = { class: "text-left font--small" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["textContent", "data-gtm", "data-gtm-click-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inputFieldComponent = _resolveComponent("inputFieldComponent")!
  const _component_checkboxComponent = _resolveComponent("checkboxComponent")!
  const _component_ValidationErrorComponent = _resolveComponent("ValidationErrorComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_inputFieldComponent, {
      id: "frmNameA",
      name: "name",
      placeholder: "F.eks. Peter Petersen",
      label: "Navn",
      validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
      innerData: _ctx.model.contact_information,
      required: true,
      onInputFieldComponent: _ctx.setSubHeader,
      validationClass: _ctx.fieldValidator.isValidName(_ctx.model.contact_information.name) ? 'input--valid' : undefined,
      class: "text-left pb-3"
    }, null, 8, ["validateOnLoad", "innerData", "onInputFieldComponent", "validationClass"]), [
      [_vShow, !_ctx.model.contact_information.customerNo]
    ]),
    _createVNode(_component_inputFieldComponent, {
      id: "frmPhoneNumA",
      name: "phone",
      type: "tel",
      innerData: _ctx.model.contact_information,
      placeholder: " F.eks. 12 34 56 78",
      labelPopover: _ctx.cms.phoneNoPopOver,
      label: "Telefonnummer",
      validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
      required: true,
      validationClass: _ctx.fieldValidator.isValidPhone(_ctx.model.contact_information.phone) ? 'input--valid' : undefined,
      class: "text-left pb-3"
    }, null, 8, ["innerData", "labelPopover", "validateOnLoad", "validationClass"]),
    _withDirectives(_createVNode(_component_inputFieldComponent, {
      id: "frmEmailC",
      name: "email",
      type: "email",
      placeholder: "F.eks. peter@mailtest.dk",
      label: "E-mail",
      validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
      required: true,
      innerData: _ctx.model.contact_information,
      validationClass: _ctx.fieldValidator.isValidEmail(_ctx.model.contact_information.email) ? 'input--valid' : undefined,
      class: "text-left pb-3"
    }, null, 8, ["validateOnLoad", "innerData", "validationClass"]), [
      [_vShow, _ctx.showEmail]
    ]),
    _createVNode(_TransitionGroup, { name: "slide.simple" }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_inputFieldComponent, {
          key: "cpr",
          id: "cpr",
          name: "cpr",
          innerData: _ctx.model.contact_information,
          placeholder: "10 cifre",
          label: "CPR-nummer",
          validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
          validationClass: _ctx.fieldValidator.isValidCpr(_ctx.model.contact_information.cpr) ? 'input--valid' : undefined,
          required: true,
          class: "text-left pb-3"
        }, null, 8, ["innerData", "validateOnLoad", "validationClass"]), [
          [_vShow, _ctx.fieldValidator.isValidEmail(_ctx.model.contact_information.email) && !_ctx.model.contact_information.customerNo]
        ]),
        (_ctx.hasChildrenCpr && _ctx.fieldValidator.isValidEmail(_ctx.model.contact_information.email) && (_ctx.model.contact_information.customerNo || _ctx.fieldValidator.isValidCpr(_ctx.model.contact_information.cpr)))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.model.familyInfo.personCountId + 1), (inx) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'ch' + inx 
                }, [
                  (_openBlock(), _createBlock(_component_inputFieldComponent, {
                    key: 'cc' + (inx-1),
                    id: 'cpr' + (inx-1),
                    name: 'cpr' + (inx-1),
                    innerData: _ctx.model.familyInfo,
                    placeholder: "10 cifre",
                    label: 'CPR-nummer for ' + _ctx.model.familyInfo['name' + (inx-1)],
                    validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
                    required: true,
                    fieldValidatorType: "cprUnderEighteen",
                    validationClass: _ctx.isValidChildCpr(inx-1),
                    class: "text-left pb-3"
                  }, null, 8, ["id", "name", "innerData", "label", "validateOnLoad", "validationClass"]))
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.hasAdultCpr && _ctx.fieldValidator.isValidEmail(_ctx.model.contact_information.email) && (_ctx.model.contact_information.customerNo || _ctx.fieldValidator.isValidCpr(_ctx.model.contact_information.cpr)))
          ? (_openBlock(), _createBlock(_component_inputFieldComponent, {
              key: "otherCpr",
              id: "otherCpr",
              name: "otherCpr",
              innerData: _ctx.model.personInfo,
              placeholder: "10 cifre",
              label: 'CPR-nummer for ' + _ctx.model.personInfo.otherName,
              validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
              required: true,
              fieldValidatorType: "cpr",
              validationClass: _ctx.isValidAdultCpr,
              class: "text-left pb-3"
            }, null, 8, ["innerData", "label", "validateOnLoad", "validationClass"]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_checkboxComponent, {
          key: "accept",
          id: "accept",
          name: "accept",
          preSelect: _ctx.model.contact_information.accept,
          inputValue: 'samtykke',
          class: "pb-3",
          onCheckboxComponent: _ctx.checkboxEvent
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, [
              _createTextVNode("Jeg giver hermed samtykke til, at Alm. Brand må registrere og behandle mit CPR-nr. Du kan læse mere om, hvordan vi behandler dine personoplysninger "),
              _createElementVNode("a", {
                href: _ctx.cms.personDataLink,
                target: "_blank",
                textContent: 'her'
              }, null, 8, _hoisted_3)
            ])
          ]),
          _: 1
        }, 8, ["preSelect", "onCheckboxComponent"]), [
          [_vShow, _ctx.fieldValidator.isValidEmail(_ctx.model.contact_information.email)]
        ]),
        (!_ctx.model.pristineStep.get(_ctx.card.name) && !_ctx.model.contact_information.accept && (_ctx.model.contact_information.cpr || _ctx.model.contact_information.customerNo))
          ? _withDirectives((_openBlock(), _createBlock(_component_ValidationErrorComponent, {
              key: "acceptVal",
              class: "input-error--show mt-n3",
              text: 'Du mangler at give samtykke'
            }, null, 512)), [
              [_vShow, _ctx.model.contact_information.phone]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("a", {
      class: _normalizeClass([_ctx.card.buttonStyle, 'mt-3', {'btn-disabled': !_ctx.isValid}]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextStep())),
      textContent: _toDisplayString(_ctx.card.linkText),
      "data-gtm": _ctx.cms.dataGtm,
      "data-gtm-click-text": _ctx.card.routePath
    }, null, 10, _hoisted_4)
  ]))
}