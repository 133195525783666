import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pb-3" }
const _hoisted_2 = { class: "button-container text-center" }
const _hoisted_3 = ["textContent", "data-gtm", "data-gtm-click-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddressSearchComponent = _resolveComponent("AddressSearchComponent")!
  const _component_inputFieldComponent = _resolveComponent("inputFieldComponent")!
  const _component_ExistingAbProductsComponent = _resolveComponent("ExistingAbProductsComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AddressSearchComponent, {
        id: "address",
        key: "address",
        elementId: "address",
        isOnlyZipSearch: false,
        label: "Adresse",
        placeholder: "F.eks Gadenavn 29, 2.tv...",
        defaultValue: _ctx.model.personInfo.address || _ctx.defaultAddress,
        readonly: _ctx.readonlyAddress,
        isValid: _ctx.model.personInfo.address !== undefined,
        onAddressSearch: _ctx.addressSearch
      }, null, 8, ["defaultValue", "readonly", "isValid", "onAddressSearch"])
    ]),
    _createVNode(_TransitionGroup, { name: "slide-simple" }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_inputFieldComponent, {
          class: "text-left pb-3",
          key: "customerAge",
          id: "customerAge",
          name: "customerAge",
          placeholder: "F.eks. 42 ",
          label: "Din alder",
          readonly: _ctx.readonlyAge,
          validationClass: _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge) ? 'input--valid' : undefined,
          validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
          innerData: _ctx.model.personInfo,
          required: true
        }, null, 8, ["readonly", "validationClass", "validateOnLoad", "innerData"]), [
          [_vShow, _ctx.model.personInfo.address || _ctx.readonlyAge]
        ]),
        (_ctx.model.showExistingAlmBrandProducts && _ctx.model.personInfo.customerAge && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge))
          ? (_openBlock(), _createBlock(_component_ExistingAbProductsComponent, {
              key: "ExistingAbProductsComponent",
              card: _ctx.card
            }, null, 8, ["card"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        class: _normalizeClass([_ctx.card.buttonStyle, 'mt-3', {'btn-disabled': !_ctx.isValid}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextStep())),
        textContent: _toDisplayString(_ctx.card.linkText),
        "data-gtm": _ctx.cms.dataGtm,
        "data-gtm-click-text": _ctx.card.routePath
      }, null, 10, _hoisted_3)
    ])
  ]))
}