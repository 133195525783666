import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-gtm-label", "data-gtm-url"]
const _hoisted_2 = { class: "tile__item__icon" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.model.appMessage && _ctx.isApp)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sendMessage())),
        class: "tile__item tile__item--link",
        "data-gtm-action": "tile click",
        "data-gtm-label": _ctx.model.title,
        "data-gtm-url": _ctx.trackingUrl
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "tile__item__icon__img",
            src: _ctx.model.icon.url,
            alt: ""
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", {
          class: "tile__item__title",
          innerHTML: _ctx.model.title
        }, null, 8, _hoisted_4)
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}