import VueScrollTo from "vue-scrollto";
import { mapState } from 'vuex';
import CoverageAdapter from '@/views/calculators/model_legacy/CoverageAdapter';
import PackageBlockComponent from '../../components/PackageBlockComponent/PackageBlockComponent.vue';
// import { CarSettings } from '../../car/CarSettings';
import { CardBlock } from '@/cms/definitions/content-types';
import GtmService from "@/services/gtmService";
// import { AccidentSettings } from '../../accident/AccidentSettings';
// import { HomeSettings } from '../../home/HomeSettings';
// import { HouseSettings } from '../../house/HouseSettings';
import DogCalculator from '../../dog/DogCalculator';
import { DogSettings } from '../../dog/DogSettings';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import UrlHandler from '../../UrlHandler';
import { Options, Vue } from "vue-class-component";
// import { TravelSettings } from "../../travel/TravelSettings";

@Options({
    name: 'ChoosePackageStepComponent',
    components: {
        PackageBlockComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
    props: {
        card: Object, // Card from cms
        isValid: Boolean, // wether card is complete
        showExcessChoice: {type: Boolean, default: true},
        showSums:{type: Boolean, default: false},
        showSegmentedProductProperty: {type: Boolean, default: false},
        calculator: Object,
    
    }
})

export default class ChoosePackageStepComponent extends Vue {
    card: CardBlock; // Card from cms
    isValid: boolean; // wether card is complete
    showExcessChoice?: boolean; // show excess choice
    showSums?: boolean; // show sums to be selected
    showSegmentedProductProperty?: boolean; // show productProperty to be selected ie Travel destination(SegmentedControl)
    calculator: DogCalculator | any; // specific product calculator TODO klk

    public model!: any; // datastructure for user input (store)
    
    public cms!: DogSettings /*| CarSettings | AccidentSettings | HomeSettings | HouseSettings | TravelSettings*/; // settings from CMS (store)


    public created() {
        // this.$on('SegmentedComponent', (evt) => {
        //     if (this.showExcessChoice && evt.id === 'ownRiskId') {
        //         this.model.choosePackage.ownRiskId = parseInt(evt.value);
        //         const excess = this.model.calculation.abCalc.excesses.find( x => x.id === this.model.choosePackage.ownRiskId );
        //         GtmService.triggerCustomGtmEvent({
        //             'event': 'track-vp',
        //             'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/selvrisiko/${excess.amount}`
        //         });
        //     }
        //     if (this.showSegmentedProductProperty && evt.id === 'segmentedProductProperty') {
        //         this.model.choosePackage.segmentedProductProperty = evt.value;
        //         GtmService.triggerCustomGtmEvent({
        //             'event': 'track-vp',
        //             'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/destination`,
        //         });
        //     }
        //     if (this.showSums) {
        //         if (this.model.choosePackage.insuredValue) {
        //             this.calculator.getCalculations(this.model.choosePackage.ownRiskId);
        //         }
        //         return;
        //     }
        //     this.calculator.getCalculations();
        // });
    }

    public handleSegmentedProperty(evt) {
        if (this.showExcessChoice && evt.id === 'ownRiskId') {
            this.model.choosePackage.ownRiskId = parseInt(evt.value);
            const excess = this.model.calculation.abCalc.excesses.find( x => x.id === this.model.choosePackage.ownRiskId );
            GtmService.triggerCustomGtmEvent({
                'event': 'track-vp',
                'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/selvrisiko/${excess.amount}`
            });
        }
        if (this.showSegmentedProductProperty && evt.id === 'segmentedProductProperty') {
            this.model.choosePackage.segmentedProductProperty = evt.value;
            GtmService.triggerCustomGtmEvent({
                'event': 'track-vp',
                'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/destination`,
            });
        }
        if (this.showSums) {
            if (this.model.choosePackage.insuredValue) {
                this.calculator.getCalculations(this.model.choosePackage.ownRiskId);
            }
            return;
        }
        this.calculator.getCalculations();

    }
    public mounted() {
        this.model.calculation.isCalculated = false;
        switch(this.model.productName) {
            case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING:
            case InsurancesProductEnum.ULYKKES_FORSIKRING:
            case InsurancesProductEnum.HUNDE_FORSIKRING: this.calculator.getCalculations();
                                                    break;
            case InsurancesProductEnum.BIL_FORSIKRING:
            case InsurancesProductEnum.HUS_FORSIKRING:
            case InsurancesProductEnum.FRITIDSHUS_FORSIKRING: if (this.model.choosePackage.ownRiskId) {
                                                        this.calculator.getCalculations();
                                                    }
                                                    break;
            case InsurancesProductEnum.REJSE_FORSIKRING: if(this.model.choosePackage.segmentedProductProperty) {
                                                        this.calculator.getCalculations();
                                                    }
                                                    break;
            case InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING:
            case InsurancesProductEnum.INDBO_FORSIKRING: if(this.model.choosePackage.ownRiskId && this.model.choosePackage.insuredValue) {
                                                        this.calculator.getCalculations(this.model.choosePackage.ownRiskId);
                                                    }
                                                    break;
            default: break;
        }
    }
    public isSplashIcon(packName: string): boolean {
        if (this.model.campaign.valid) {
            const campaign = this.cms.campaigns.get(this.model.campaign.ID);
            if (campaign && campaign.splashIcons) {
                const splash = campaign.splashIcons.get(packName);
                if (splash) {
                    return true;
                }
            }
        }
        return false;
    }

    public getSplashText(packName: string): string {
        // from campaign
        if (this.model.campaign.valid) {
            const campaign = this.cms.campaigns.get(this.model.campaign.ID);
            if (campaign && campaign.splash) {
                const splash = campaign.splash.get(packName);
                if (splash) {
                    if (campaign.splashIcons?.get(packName)) {
                        return campaign.splashIcons.get(packName)
                    }
                    return splash;
                }
            }
        }
        // from cms
        if (this.cms.recommendationPackage === packName) {
            return this.cms.recommendationTxt;
        }
        return undefined;

    }

    public get ownRiskOptions(): Array<number> {
        return this.model.ownRiskOptions;
    }
    public get ownRiskLabels(): Array<string> {
        return this.model.ownRiskLabels;
    }

    public getCoveragePackage(packageInx: number): Array<CoverageAdapter> {
        const res = this.model.calculation.allCoverages.get(this.model.choosePackage.ownRiskId).get(packageInx);
        return res;
    }

    public getChoosePackageBtnLabel(card: CardBlock, packName: string) {
        const regEx = new RegExp('{{package}}', 'ig');
        return card.linkText.replace(regEx, packName);
    }

    public selectPackage(inx: number) {
        const ownRiskId: number = this.model.choosePackage.ownRiskId;
        const pack = this.model.calculation.abCalc.packs.get(ownRiskId)[inx];
        const price = this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay;
        if (!price) {
            // price is not yet calculated
            return;
        }
        this.model.choosePackage.selectedPackage = pack;
        this.model.campaign.splash = undefined;
        if (this.model.campaign.valid) {
            const campaign = this.cms.campaigns.get(this.model.campaign.ID);
            if (campaign && campaign.splash) {
                const splash = campaign.splash.get(pack.name);
                if (splash) {
                    this.model.campaign.splash = splash;
                }
            }
        }

        this.model.choosePackage.subtitle = `${pack.name} ${price} / Pr. ${this.model.choosePackage.monthYear === 'M' ? 'md.' : 'år'}`;
        GtmService.triggerCustomGtmEvent({
            'event': 'track-vp',
            'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/beregning_valg/${pack.name}`
        });

        this.$emit('nextStep', this.card.routePath);
    }

    public nextStep() {
        this.$emit('nextStep', this.card.routePath);
    }

    public scrollToPack(inx: number) {
        try {
            let id = this.model.calculation.abCalc.packs.get(this.model.choosePackage.ownRiskId)[inx-1].name;
            // hack for car packages
            if (this.model.productName === InsurancesProductEnum.BIL_FORSIKRING && this.cms.extendCoverages) {
                if (id === 'Ansvar+' || id === 'AnsvarPlus') {
                    id = 'Ansvar';
                }
            }
            if (id.includes(' ')) {
                id = id.replace(' ', '\\ ');
            }
            VueScrollTo.scrollTo(`#${id}`, 500, { easing: 'ease-out', offset: -50 });
        } catch(e) {
            // too bad
        }
    }

    public getPrevExtendedPackName(inx: number) {
        const name: string = this.model.calculation.abCalc.packs.get(this.model.choosePackage.ownRiskId)[inx-1].name;
        // hack for car packages
        if (this.model.productName === InsurancesProductEnum.BIL_FORSIKRING && this.cms.extendCoverages) {
            if (name.toLocaleLowerCase() === 'ansvar+' || name.toLocaleLowerCase() === 'ansvarplus') {
                return 'Ansvar';
            }
        }
        return name;
    }
    public get sums(): Array<string> {
        const sumsFormatted = [];
        if (this.showSums) {
            this.model.calculation.abCalc.insuredValueOptions.forEach(element => {
                sumsFormatted.push(element.key);
            });
        }
        return sumsFormatted
    }

    public get selvriskoLabel(): string {
        if (this.cms.selvrisikoLabel) {
            return this.cms.selvrisikoLabel;
        }
        return this.ownRiskOptions.length > 1 ? 'Hvilken selvrisiko ønsker du?' : 'Selvrisiko'
    }
    public sumSelected(result) {
        if (result && result.selectedValue) {
            this.model.choosePackage.insuredValue = result.selectedValue;
            const inx = this.sums.indexOf(result.selectedValue);
            this.model.choosePackage.insuredValueAmount = this.model.calculation.abCalc.insuredValueOptions[inx].value;
            if (this.model.choosePackage.ownRiskId) {
                GtmService.triggerCustomGtmEvent({
                    'event': 'track-vp',
                    'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/sum`,
                });
                this.calculator.getCalculations(this.model.choosePackage.ownRiskId);
            }
            return;
        }
        this.model.choosePackage.insuredValue = undefined;
        this.model.choosePackage.insuredValueAmount = undefined;
    }
}
