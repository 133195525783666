import { Vue, Options } from "vue-class-component";
import getComponentTypeForContent from '@/cms/api/contentComponentSelector';
import { IContent } from '@/cms/definitions/content';
import { PropType } from "vue";

@Options({
    name: 'ContentComponentSelector',
    props: {
        model: Array as PropType<IContent[]>,
    },
})

export default class ContentComponentSelector extends Vue {
    model: IContent | any;
    getComponentTypeForBlock(block: IContent) {
        return getComponentTypeForContent(block) ?? 'GenericContent';
    }
}
