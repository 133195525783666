
// import GtmService from "@/services/gtmService";
import FieldValidator from '@/services/fieldValidatorService';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'InputFieldComponent',
    components: {
    }, 
    props: {
        id: String,
        name: String,
        placeholder: String,
        label: String,
        labelPopover: String,
        type: String,
        validateOnLoad: Boolean,
        required: Boolean,
        validationClass: {type: String, default: ''},
        readonlyValue: {type: String, default: null},
        fieldValidatorType: {type: String, default: null},
        readonly: Boolean, // used by SelectModalComponent
        innerData: {type: Object, default: {}}, // innerData is an object reference with a name that governs HtmlInput value
        inputValue: String, // direct value to HtmlInput value
        // trackingEnabled: {type: Boolean, default: true },
    }
})

export default class InputFieldComponent extends Vue {
    id: string;
    name: string;
    placeholder: string;
    label: string;
    labelPopover?: string;
    type: string;
    validateOnLoad: boolean;
    required: boolean;
    validationClass?: string; // validation success css class
    readonlyValue?: string;
    fieldValidatorType?: string;
    readonly: boolean;
    innerData: any;
    inputValue: string;
    // trackingEnabled: boolean;

    private fieldValidator: FieldValidator;
    public validationErrorFormatText: string = '';
    public htmlInputValue: string = '';    
    public hasError = false;
    // private doTrack = true;

    public mounted() {
        if (this.inputValue) {
            this.htmlInputValue = this.inputValue;
        }
        else if (this.innerData[this.name]) {
            this.htmlInputValue = this.innerData[this.name];
        }
        
        if (this.fieldValidatorType) {
            this.fieldValidator = new FieldValidator(this.fieldValidatorType);
        } else {
            this.fieldValidator = new FieldValidator(this.name);
        }

        if (this.validateOnLoad) {
            this.validateInput("blur", this.htmlInputValue);
        }
    }

    // METHODS
    public handleInput(data) {
        // console.log('handleInput', data);
        
        this.htmlInputValue = data.value;
        this.validateInput(data.event.type, data.value);
        this.innerData[this.name] = data.value;
        if (data.event.type === "focus") {
            this.$emit('InputFocus', data.value);
        }
        if (data.event.type === "input") {
            this.$emit('InputChanged', data.value);
        }
        if (data.event.type === "blur") {
            this.$emit("InputBlur", data.value);
        }
    }

    public change(evt) {
        const input = evt.target || evt.srcElement;
        this.handleInput({
            name: this.name,
            value: input.value,
            event: evt
        });

    }


    private validateInput(event: string, value: string) {
        if (this.readonly || this.readonlyValue) {
            return;
        }
        if (event === "blur" || 
            (event === "keyup" && this.validationErrorFormatText) || 
            (event === 'input' && value?.length >= 1) ) {
            // make sure error doesn't show instantly
            setTimeout(() => {
                this.hasError = !this.fieldValidator.isValid(value);
                this.validationErrorFormatText = !this.hasError ? '' : this.fieldValidator.errorMsg;                    
            }, 100);
        }
    }
    // private track(): void {
    //     const track = {
    //         event: 'InputEvent',
    //         eventAction: 'Input field',
    //         eventCategory: 'Engagement',
    //         eventLabel: `${this.label ? this.label : this.name} - indtastet`,
    //     }
    //     GtmService.triggerCustomGtmEvent(track);
    //     this.doTrack = false; // only once
    // }

}
