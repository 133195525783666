/**
 *  @classdesc Holds functions for communicating with the app
 */
import { Log } from '@/appinsights/logging/log';

export default class AppService {
    /**
     * @returns true if open in App, else false
     */
    public static isEnabled(): boolean {
        if (window.location.href.indexOf("client=app") > -1) {
            return true;
        }

        if((<any>window).nsWebViewBridge !== undefined) {
            return true;
        }

        return false;
    }

    /**
     * Sends a message to the app via gotMessage
     * @param message An object containing the message: { 'MAIN_TITLE': 'title'}
     *  
     */
    public static sendMessage(message: {}) {
        if(this.isEnabled()) {
            (<any>window).nsWebViewBridge?.emit('gotMessage', message);
            if(!(<any>window).nsWebViewBridge) {
                Log.information('App_message', message);
            }
        }
    }
}
