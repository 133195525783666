import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
import { CarSettings } from './CarSettings';
import { BuyInsuranceHelper, Formatter, STEP, Validator } from '../BuyInsuranceHelper';
import CarCalculator from './CarCalculator';
import CarInfoStepComponent from '../steps/car/CarInfoStepComponent.vue';
import PersonInfoStepComponent from '../steps/car/PersonInfoStepComponent.vue';
import ChoosePackageStepComponent from '../steps/common/ChoosePackageStepComponent.vue';
import ContactInformationStepComponent from '../steps/common/ContactInformationStepComponent.vue';
import OverviewStepComponent from '../steps/common/OverviewStepComponent.vue';
import AdditionalInfoStepComponent from '../steps/car/AdditionalInfoStepComponent.vue';
import PaymentStepComponent from '../steps/common/PaymentStepComponent.vue';
import ReceiptStepComponent from '../steps/common/ReceiptStepComponent.vue';
import CampaignStepComponent from '../steps/common/CampaignStepComponent.vue';
import {Model, STEPS} from "./Model";
import { cloneDeep } from "lodash";
import { PropType } from 'vue';
import { CardBlock, HtmlBlock, UsabillaInPageBlock } from '@/cms/definitions/content-types';
import store from '@/store/store';
import { CALCULATOR_LOADED, INIT_CALCULATOR, RESET_CALCULATORS } from '@/store/modules/calculatorContext';
import { EnvironmentService } from '@/services/environmentService';
import UrlUtilService from '@/services/urlUtilService';

@Options({
    name: 'BuyInsuranceCarComponent',
    props: {
        calculatorInfoBlock: Object as PropType< Array<HtmlBlock>>,
        contentBlocks: Object as PropType< Array<CardBlock>>,
        cardReceipt: Object as PropType<CardBlock>,
        settingsBlock: Object as PropType<HtmlBlock>,
        usabillaBlock: Object as PropType<UsabillaInPageBlock>,
        usabillaBlockLeaveIntent: Object as PropType<UsabillaInPageBlock>,
        contentUrl: String, // Used by helper to handle browser history
        headerBadgeTxt: String,
    },
    components: {
        UsabillaInPageComponent,
        CarInfoStepComponent,
        PersonInfoStepComponent,
        ChoosePackageStepComponent,
        OverviewStepComponent,
        AdditionalInfoStepComponent,
        ContactInformationStepComponent,
        PaymentStepComponent,
        ReceiptStepComponent,
        CampaignStepComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
})

export default class BuyCar extends Vue {
    contentBlocks: Array<CardBlock>;
    cardReceipt: CardBlock;
    settingsBlock: HtmlBlock;
    usabillaBlock: UsabillaInPageBlock;
    usabillaBlockLeaveIntent: UsabillaInPageBlock;
    contentUrl: string; // Used by helper to handle browser history
    headerBadgeTxt: string;
    calculatorInfoBlock: HtmlBlock;


    // list and order of steps
    public steps : Array<string> = STEPS

    public model!: any; // model from store
    public cms!: CarSettings; // setting from store
    public helper: BuyInsuranceHelper;
    public calculator: CarCalculator = null;
    public componentInit = false;
    public checkout = false;

    public async created () {

        this.helper = new BuyInsuranceHelper(this);
        if(!store.getters.getActiveCalculator) {
            const cms = new CarSettings(this.settingsBlock);
            await store.dispatch(INIT_CALCULATOR, {cms, model: cloneDeep(Model.model)});
        } else {
            await store.dispatch(CALCULATOR_LOADED);
        }

        if (!await this.helper.initComponent()) {
            return;
        }
        
        this.calculator = new CarCalculator(this);

        if (this.$route.query.licenseplate) {
            this.model.carInfo.licenseplate = this.$route.query.licenseplate;
        }

        if (this.model.campaign.valid) {
            if (this.$route.query.customerAge) {
                this.model.personInfo.customerAge = this.$route.query.customerAge;
            }
            if (this.$route.query.zipCode || this.$route.query.zipcode) { // klk todo address
                this.model.personInfo.zipCode = this.$route.query.zipCode;
            }

            try {
                const yearlyMileageIndex = this.$route.query.yearlyMileageIndex as string;
                const index = parseInt(yearlyMileageIndex);
                const yearlyMileage = this.cms.kmForbrug[index];
                if (yearlyMileage) {
                    this.model.carInfo.yearlyMileage = this.cms.kmForbrug[index];
                }
            } catch(e) {}
            if(this.$route.query.displayText) {
                this.model.carInfo.displayText = this.$route.query.displayText;
                this.model.carInfo.searchBrandModel = true;
            }
        }
        this.helper.setFirstCard();
    }

    // called by helper
    public addResetSubscription() {
        const unsubscribe = store.subscribeAction((action, state) => {
            if (action.type === RESET_CALCULATORS) {
                setTimeout(() => {
                    this.checkout = true;
                    store.dispatch(INIT_CALCULATOR, {cms : this.cms, model: cloneDeep(Model.model)});
                    unsubscribe();
                }, 2000);
            }
        });
    }

    public get showValidNowOption() {
        if (this.isNewDriver) {
            return true;
        }
        if (!this.model.additionalInfo.existInsurance && !this.model.additionalInfo.existFormerInsurance) {
            return false;
        }

        if (this.model.additionalInfo.existInsurance === 'ja' && this.model.additionalInfo.existingInsurance) {
            return true;
        }

        if (this.model.additionalInfo.existInsurance === 'nej') {
            if (this.model.additionalInfo.existFormerInsurance === 'nej' ) {
                return true;
            }
            if (this.model.additionalInfo.existFormerInsurance === 'ja' && this.model.additionalInfo.formerInsurance) {
                return true;
            }
        }
        return false;
    }

    public get isNewDriver(): boolean {
        // customer has never had a car
        return this.model.personInfo.carOwnerYears && this.cms.carOwnerYears.indexOf(this.model.personInfo.carOwnerYears) === 0;
    }

    public async gotoCard(cardName: string): Promise<boolean> {
        if (cardName === STEP.CAR_INFO || cardName === STEP.PERSON_INFO  || cardName === STEP.PACKAGE) {
            BuyInsuranceHelper.resetSelectedCalculation(this);
        }
        if(cardName === STEP.PERSON_INFO || cardName === STEP.PACKAGE) {
            this.model.showSpinner = true;
            this.model.calculating = false;
            const isSpecialCar = await this.calculator.isSpecialCar();
            
            this.model.showSpinner = false;
            this.model.calculating = true;
            if (isSpecialCar) {
                this.model.currentCardName = cardName;
                this.gotoCard(STEP.CAR_INFO);
                return false;
            }
        }
        if(cardName === STEP.PACKAGE) {
            this.calculator.setupExcessList();
            // check Age:
            if (!this.helper.handleValidCustomerAge()) {
                this.gotoCard(STEP.PERSON_INFO);
                return false;
            }

        // under 25 år, kun én selvrisiko og beregn nu
            if (this.model.ownRiskOptions.length === 1) {
                this.model.choosePackage.ownRiskId = this.model.ownRiskOptions[0].value;
                this.calculator.getCalculations();
                // this.model.calculation.calculationUpdated = 'calcNotUpdated';
            }
        }

        if(cardName === STEP.PAYMENT) {
            // force user to choose yearly/monthly payment to ensure progressive steps
            this.model.choosePackage.monthYear = undefined;
        }
        // const previousCard = this.model.currentCardName;
        // all validations are good, so make sure blur doesn't set errors on fields
        this.model.currentCardName = cardName;

        if(cardName === STEP.ORDER) {
            await this.orderByEmail();
            return false;
        }
        return true;
    }

    /**
     * when nextbtn is clicked - check for valid
     * @param cardName
     */
    public nextStep(cardName: string, addToHistory: boolean = true) {
        this.helper.nextStep(cardName, addToHistory);
    }

    public getSubtitle(cardName) {
        return this.helper.getSubtitle(cardName);
    }

    public getExcessAmount(): string {
        const inx = this.model.ownRiskOptions.indexOf(this.model.choosePackage.ownRiskId);
        return this.model.ownRiskLabels[inx];
    }

    public isActiveCard(cardName: string): boolean {
        return cardName === this.model.currentCardName;
    }

    public isValid(cardName): boolean {
        switch(cardName) {
            case STEP.CAR_INFO:
                return !!(this.model.carInfo.variantId && this.model.carInfo.yearlyMileage);
            case STEP.PERSON_INFO:
                const okPerson = this.model.personInfo.carOwnerYears &&
                    this.model.personInfo.address &&
                    Validator.isValidAge(this.model.personInfo.customerAge) &&
                    this.helper.isValidAlmbrandProducts();
                if (okPerson) {
                    this.model.personInfo.customerAge = this.model.personInfo.customerAge.trim().replace(/\D/g,'');
                    // const customerAge = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g,''));
                    this.model.personInfo.subtitle = `${this.model.personInfo.customerAge} år, ${this.model.personInfo.address}`;
                }
                return !!okPerson;
            case STEP.PACKAGE:
                return this.helper.isValidPackage();
            case STEP.OVERVIEW:
                return this.helper.isValidPackage();
            case STEP.CONTACT_INFORMATION:
                return this.helper.isValidContactInfo();
            case STEP.ADDITIONAL_INFO:
                let ok = this.helper.isValidAdditionalInfo(this.isNewDriver);
                if (!ok) {
                    return ok;
                }
                if (this.model.additionalInfo.existInsurance === 'nej') {
                    ok = this.model.additionalInfo.existFormerInsurance;
                    if(!ok) {
                        return false;
                    }
                    if(this.model.additionalInfo.existFormerInsurance === 'ja') {
                        ok = Validator.isValidExistingInsurance(this.model.additionalInfo.formerInsurance);
                    }
                }
                return !!ok;
            case STEP.PAYMENT:
                return this.helper.isValidPaymentInfo();
            default: return true;
        }
    }


    // called by helper
    public setOverviewData() {
        this.helper.setHighLights();

        this.model.overview.details = [
            this.model.carInfo.car,
            this.model.personInfo.customerAge + ' år',
            this.model.personInfo.address,
            'Selvrisiko: ' + this.getExcessAmount(),
            'År med egen bil: ' + this.model.personInfo.carOwnerYears,
            'Kørselsbehov: ' + this.model.carInfo.yearlyMileage,
        ];
        this.helper.setDetailsYearlyPrice();
    }

    public async orderByEmail() {
        try {
            this.model.showSpinner = true;
            if (!this.isValid(this.model.currentCardName)) {
                this.model.showSpinner = false;
                return;
            }
            const pack = this.model.choosePackage.selectedPackage;
            let desc = '';

            let tracking = '';

            if (this.model.contact_information.customerNo) {
                const device = EnvironmentService.isApp() ? '(APP)' : '(WEB)';
                desc += `<li><h2><i>Kunde genkendt ${device}</i></h2></li>`;
                tracking += "/existingCustomer";
            }
            if (this.model.calculation?.addressSupplement > 1) {
                desc += '<li><h2><i>Adresse tillæg</i></h2></li>';
                tracking += "/addressSupplement";
            }
            if (this.model.campaign.valid) {
                const id = this.model.campaign.ID ? this.model.campaign.ID.toLocaleUpperCase() : 'Ukendt!'
                desc += `<li><h2><i>Kampagne ${id}</i></h2></li>`;
                tracking += `/campaign/${id}`;
            }
            if (this.model.additionalInfo.rki?.toLowerCase() === 'ja') {
                desc += '<li><h2><i>Kunde er registreret i RKI</i></h2></li>';
                // tracking += "/rki";
            }
            if (this.model.additionalInfo.skader !== '0') {
                desc += `<li><h2><i>Kunde har ${this.model.additionalInfo.skader} skader</i></h2></li>`;
                // tracking += "/damages";
            }

            if (!this.model.carInfo.licenseplate || this.model.carInfo.licenseplate === 'ikke oplyst') {
                desc += `<li><h2><i>Nummerplade ikke oplyst</i></h2></li>`;
                tracking += "/nolicenseplate";
            }

            if (desc !== '') {
                desc = '<ul>' + desc + '</ul>'
            }

            await this.helper.submitOrder(desc, tracking);

            const price: number = this.model.choosePackage.monthYear === 'M' ? pack.totalPrice * 12 : pack.yearlyPriceTotal;
            const product = {
                name: 'AU_PB',
                id: pack.name,
                price: '' + price,
                brand: 'Fakedoor flow',
                variant: this.model.carInfo.car,
                category: 'Online Salg',
                quantity: '1'
            };
            this.helper.trackPurchase(product, price);

        } catch(e) {
            this.model.showSpinner = false;
            if (UrlUtilService.isDevelop()) {
                this.model.modal.content = this.model.modal.content + '<br><div style="border: 2px solid red">' + e +'</div> ';
            }
            BuyInsuranceHelper.togglePopup(this.cms, {            
                    id: 'error',
                    show: true,
                    redirect: undefined,
                    track: true,
                }
            );
            this.helper.sendErrorMailToTeam(e);
        }
    }

    /** Called by helper */
    public buildDescriptionForEmail(desc: string) {
        const addProp: Function = this.helper.addProp;
        const pack = this.model.choosePackage.selectedPackage;

        if (pack?.specialDiscount) {
            const discount = BuyInsuranceHelper.handleSpecialCarDiscounts(this);
            if (discount.specialDiscountType === 'vehicleGroup') {
                desc += addProp(`Bilgruppe rabat (${this.cms.vehicleDiscountGroups.join(', ')}) inkluderet i pris`, Formatter.format(100 * pack.specialDiscount) + ' %');
            } else if (discount.specialDiscountType === 'fuelType') {
                desc += addProp(`El/Hybrid rabat (bilgrp. ${this.cms.electricFuelTypeVehicleGroups.join(', ')}) inkluderet i pris`, Formatter.format(100 * pack.specialDiscount) + ' %');
            } else {
                desc += addProp(`UKENDT rabat inkluderet i pris`, Formatter.format(100 * pack.specialDiscount) + ' %');
            }
        }

        desc += '<h2>Ejer</h2>';
        const now: Date = new Date();
        desc += addProp('Email sendt', `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${(now.getMonth()+1)}-${now.getFullYear()}`);
        desc += addProp('Navn', this.model.contact_information.name);
        desc += addProp('Adresse', `${this.model.personInfo.address}`);
        desc += addProp('Alder', this.model.personInfo.customerAge);
        if (this.model.contact_information.customerNo) {
            desc += addProp('KundeNr', this.model.contact_information.customerNo);
        } else {
            desc += addProp('Cpr', this.model.contact_information.cpr);
        }
        desc += addProp('Antal skader', this.model.additionalInfo.skader);
        desc += addProp('Kunde har accepteret betingelser', this.model.contact_information.accept ? 'Ja' : 'Nej');
        desc += addProp('Email', this.model.contact_information.email);
        desc += addProp('Telefonnummer', this.model.contact_information.phone);

        desc += this.helper.buildExistingProductsDescription();

        desc += '<h2>Forsikring</h2>';
        desc += addProp('Rki', this.model.additionalInfo.rki);
        if (!this.isNewDriver) {
            desc += addProp('Eksisterende forsikring', this.model.additionalInfo.existInsurance);
                if (this.model.additionalInfo.existInsurance === 'nej') {
                    desc += addProp('Tidligere forsikring', this.model.additionalInfo.existFormerInsurance);
                    if (this.model.additionalInfo.existFormerInsurance === 'ja') {
                        desc += addProp('Tidligere selskab', this.model.additionalInfo.formerInsurance);
                    }
                }
                else {
                    desc += addProp('Nuværende selskab', this.model.additionalInfo.existingInsurance);
                }
            } else {
            desc += addProp('Eksisterende forsikring', 'Ingen');
            desc += addProp('Nuværende selskab', 'Ingen, ny bilist');
        }

        desc += addProp('Gyldig hurtigst mulig', this.model.additionalInfo.validNow);
        if(this.model.additionalInfo.validNow === 'nej') {
            desc += addProp('Gyldig pr.', this.model.additionalInfo.validFromDate);
        }

        desc += '<h2>Ønsket forsikring</h2>';
        desc += addProp('Forsikringstype', pack.name);
        desc += addProp('Årlig kørselsbehov', this.model.carInfo.yearlyMileage);
        desc += addProp('Antal år med egen bil', this.model.personInfo.carOwnerYears);
        desc += addProp('Selvrisiko', this.getExcessAmount());
        desc += addProp('<br>Betaling', this.model.choosePackage.monthYear === 'M' ? 'Månedlig' : 'Årlig');
        desc += addProp('Samlet pris', this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay);
        desc += addProp('Beregningstype', this.model.choosePackage.useTiaOffer ? 'Tia beregning' : 'AU_PB beregning');
        desc += addProp('Dækninger', this.helper.getCoverageNames(pack));

        desc += '<h2>Betaling</h2>';
        desc += addProp('Reg. nr.', this.model.payment.regNo);
        desc += addProp('Konto nr.', this.model.payment.kontoNo);

        desc += '<h2>Bil oplysninger</h2>';
        desc += addProp('Nummerplade', this.model.carInfo.licenseplate ? this.model.carInfo.licenseplate.toUpperCase() : 'Ikke oplyst');
        desc += addProp('Bil', this.model.carInfo.car);
        desc += addProp('Variant', this.model.carInfo.variantName);
        desc += addProp('Årgang', this.model.carInfo.year);
        desc += addProp('Kid', this.model.carInfo.kid + '');
        desc += addProp('VariantId', this.model.carInfo.variantId);
        desc += addProp('Drivmiddel', this.model.carInfo.fuelType);
        desc += addProp('VehicleGroup', this.model.carInfo.vehicleGroup);
        const params: Map<string, string> = new Map();
        params.set('description', desc);
        return params;
    }

    public getExistingInsuranceText() {
        return this.cms.existingInsuranceText.replace('¤carInfo', this.model.carInfo.car);
    }

    public gotoAddInsurances(step: string) {
        this.helper.gotoAddInsurances(step);
    }

    public get showReceipt() {
        return BuyInsuranceHelper.getShowReceipt(this);
    }

    public getCardTitle(card): string {
        return card.name === 'choose_package' && this.model.choosePackage.selectedPackage !== undefined 
            ? this.cms.calculatedHeadline : card.title;
    }
}
