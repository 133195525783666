
import { UPDATE_CALCULATOR_INDEX } from '@/store/modules/calculatorContext';
import { SelectableData, UPDATE_SELECTABLE } from '@/store/modules/selectableContext';
import store from '@/store/store';
import GtmService from '@/services/gtmService';
import { Options, Vue } from 'vue-class-component';
import { AccidentSettings } from '../../accident/AccidentSettings';
import { BuyInsuranceHelper } from '../../BuyInsuranceHelper';
import { CarSettings } from '../../car/CarSettings';
import { DogSettings } from '../../dog/DogSettings';
import { HomeSettings } from '../../home/HomeSettings';
import { HouseSettings } from '../../house/HouseSettings';
import { TravelSettings } from '../../travel/TravelSettings';
import UrlHandler from '../../UrlHandler';

@Options({
    name: 'OverviewCalculatorStepComponent',
    components: {
    },
    props: {
        productName: String,
    }
})

export default class OverviewCalculatorStepComponent extends Vue {
    productName: string;

    public showOrderDetails = false;
    public productCms: DogSettings | TravelSettings | CarSettings | AccidentSettings | HomeSettings | HouseSettings; // product settings from CMS (store)
    public productModel: any = null;
    public coverages: any = [];
    public packName: string = 'Ikke valgt';
    public notCalculatedTxt: string = 'Mangler indtastning';
    public show=true;

    public async created() {
        const calculator = await store.getters.getCalculator(this.productName);
        if (calculator) {
            this.productCms = calculator.cms;
            this.productModel = calculator.model;
            if(this.productModel?.basketMessage || this.productCms?.calculationNotDone) {
                this.notCalculatedTxt = this.productModel?.basketMessage || this.productCms?.calculationNotDone;
            }
            if (this.productModel.readyForBasket) {
                this.packName = this.productModel.choosePackage.selectedPackage.name
                if (this.productModel.choosePackage.selectedPackage.expandedCoverages?.length > 0) {
                    this.coverages = [];
                    this.productModel.choosePackage.selectedPackage.expandedCoverages.forEach(name => {
                        this.coverages.push({name});
                    });
                } else {
                    this.coverages = this.productModel.choosePackage.selectedPackage.coverages;
                }
            }
        }
    }
    public async gotoProduct() {
        if (this.productModel) {
            this.productModel.currentCardName = 'none';
        }
        GtmService.triggerCustomGtmEvent({
            'event': 'track-vp',
            'virtualPath': `${UrlHandler.getTrackingPreUrl()}kurv/${this.productName}/edit`,
        });
        const productInx = BuyInsuranceHelper.getCalculatorIndex(this.productName);
        if (productInx > -1) {
            await store.dispatch(UPDATE_CALCULATOR_INDEX, productInx);
        }

        const url = '/gotoproduct?product=' + BuyInsuranceHelper.getUrlProductName(this.productName);
        this.$router.push(url);
        return;
    }

    public async removeProduct() {
        this.show=false;
        // for animation
        await new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, 250);
        });
        // redirect to next product if this is current product
        const gotoOtherProduct = this.isCurrentProduct;

        if (this.productModel) {
            this.productModel.currentCardName = 'none';
        }
        const remove: SelectableData = {
            group: 'basket',
            id: this.productName,
            isSelected: false,
            // sortMultiple: true,
        }
        GtmService.triggerCustomGtmEvent({
            'event': 'track-vp',
            'virtualPath': `${UrlHandler.getTrackingPreUrl()}kurv/${this.productName}/remove`,
        });

        await store.dispatch(UPDATE_SELECTABLE, remove);

        if (gotoOtherProduct) {
            this.$router.push('gotoproduct');
        } else {
            // check if current product is now only product in basket
            const basket = await store.getters.getSelectableGroup('basket');
            if (basket?.length === 1) {
                // check that we are not in basket context
                if (!this.$route.path.includes('/kurv')){
                    this.$router.push('gotoproduct');    
                }
                
            }
        }
}

    public toggleOrderDetails() {
        this.showOrderDetails = !this.showOrderDetails;
    }

    public get productNameCap() {
        return BuyInsuranceHelper.getCalculatorNameUi(this.productName);
    }

    public getCoverageName(coverage) {
        return this.productCms.getCoverageName(coverage.name) || this.productCms.getCoverageName(coverage.title);
    }

    public getMonthlyYearlyPrices(monthly: boolean) {
        if (this.productModel.readyForBasket) {
            const pack = this.productModel.choosePackage.selectedPackage;
            return monthly ? pack.totalPriceDisplay + ' / pr. md.': pack.yearlyPriceTotalDisplay + ' årligt';
        }
        return '';
    }

    public get isCurrentProduct(): boolean {
        const ulrProductName = BuyInsuranceHelper.getUrlProductName(this.productName);
        if (this.$route.path.endsWith(ulrProductName)) {
            const productName = BuyInsuranceHelper.stripProductIndex(this.productName);
            const activeIndex = store.getters.getActiveCalculatorInx;
            if (this.productName === productName + activeIndex) {
                return true;
            }
        }
        return false;
    }

    public get showButtons(): boolean {
        // return true;
        return !this.$route.path.toLocaleLowerCase().includes('beregn/forsikringer');
    }

}
