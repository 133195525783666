import { mapState } from "vuex";
import { PropositionPage } from "@/cms/definitions/content-types";
import PropositionComponent from "@/views/Proposition/PropositionComponent.vue";
import UtilService from '@/services/utilService';
import { Options, Vue } from "vue-class-component";
import {PropType} from 'vue'

@Options({
    name: 'PropositionPageComponent',
    components: {
        PropositionComponent
    },
    computed: mapState<any>({
        isInEditMode: state => state.epiContext.inEditMode
    }),
    props: {
        model: Object as PropType<PropositionPage>
    },
})

export default class PropositionPageComponent extends Vue {
    model: PropositionPage;

    beforeMount() {
        UtilService.setPageMetaData(this.model);
    }

}
