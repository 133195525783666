import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, TransitionGroup as _TransitionGroup, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "pb-3",
  key: "postnummer"
}
const _hoisted_2 = {
  key: "familyMemberCountLabel",
  class: "input__label",
  textContent: 'Antal personer i husstanden'
}
const _hoisted_3 = {
  key: "familyMemberCountRo",
  class: "validatable mb-4"
}
const _hoisted_4 = { class: "flex input__field readonly disabled input--valid" }
const _hoisted_5 = {
  key: "familyMemberCount_info_box",
  class: "highlights fill mb-4 mt-3"
}
const _hoisted_6 = { class: "text-left" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "button-container text-center" }
const _hoisted_9 = ["textContent", "data-gtm", "data-gtm-click-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddressSearchComponent = _resolveComponent("AddressSearchComponent")!
  const _component_InputFieldComponent = _resolveComponent("InputFieldComponent")!
  const _component_SelectModalComponent = _resolveComponent("SelectModalComponent")!
  const _component_ValidationErrorComponent = _resolveComponent("ValidationErrorComponent")!
  const _component_ExistingABProductsComponent = _resolveComponent("ExistingABProductsComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_TransitionGroup, { name: "slide-simple" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_AddressSearchComponent, {
            label: "Postnummer by",
            autocompleteId: "zipCode",
            placeholder: "F.eks 8000 Århus C",
            isOnlyZipSearch: true,
            defaultValue: _ctx.zipCodeDisplay,
            isValid: _ctx.model.personInfo.zipCode !== undefined,
            readonly: _ctx.readonlyZip,
            onAddressSearch: _ctx.zipSearch
          }, null, 8, ["defaultValue", "isValid", "readonly", "onAddressSearch"])
        ]),
        _withDirectives(_createVNode(_component_InputFieldComponent, {
          class: "text-left pb-3",
          key: "customerAge",
          id: "customerAge",
          name: "customerAge",
          placeholder: "F.eks. 42 ",
          label: "Din alder",
          readonly: _ctx.readonlyAge,
          validationClass: _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge) ? 'input--valid' : undefined,
          validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
          innerData: _ctx.model.personInfo,
          required: true
        }, null, 8, ["readonly", "validationClass", "validateOnLoad", "innerData"]), [
          [_vShow, (_ctx.model.personInfo.zipCode && _ctx.model.personInfo.zipName) || _ctx.readonlyAge]
        ]),
        (_ctx.readonlyFamilyMemberCount || (_ctx.model.personInfo.zipCode && _ctx.model.personInfo.zipName && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge)))
          ? (_openBlock(), _createElementBlock("label", _hoisted_2))
          : _createCommentVNode("", true),
        (!_ctx.readonlyFamilyMemberCount && _ctx.model.personInfo.zipCode && _ctx.model.personInfo.zipName && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge))
          ? (_openBlock(), _createBlock(_component_SelectModalComponent, {
              key: "familyMemberCount",
              id: "familyMemberCount",
              name: "familyMemberCount",
              preSelect: _ctx.model.personInfo.familyMemberCount ?  _ctx.model.personInfo.familyMemberCount : 'Vælg antal personer i husstand',
              innerData: _ctx.familyMemberList,
              selected: _ctx.model.personInfo.familyMemberCount !== undefined,
              "onSelectModalComponent:selected": _ctx.familyMemberSelected
            }, null, 8, ["preSelect", "innerData", "selected", "onSelectModalComponent:selected"]))
          : _createCommentVNode("", true),
        (!_ctx.model.pristineStep.get(_ctx.card.name) && _ctx.model.personInfo.familyMemberCount === undefined)
          ? _withDirectives((_openBlock(), _createBlock(_component_ValidationErrorComponent, {
              key: "familyMemberCountError",
              class: "input-error--show mt-n3",
              text: 'Du skal vælge hvor mange personer der er i din husstand'
            }, null, 512)), [
              [_vShow, _ctx.model.personInfo.zipCode && _ctx.model.personInfo.zipName && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge)]
            ])
          : _createCommentVNode("", true),
        (_ctx.readonlyFamilyMemberCount)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.readonlyFamilyMemberCount), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", {
              innerHTML: _ctx.cms.familyMemberInfo
            }, null, 8, _hoisted_7)
          ])
        ], 512), [
          [_vShow, _ctx.cms.familyMemberInfo && !_ctx.readonlyFamilyMemberCount && _ctx.model.personInfo.zipCode && _ctx.model.personInfo.zipName && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge)]
        ]),
        (_ctx.model.showExistingAlmBrandProducts && _ctx.model.personInfo.familyMemberCount)
          ? (_openBlock(), _createBlock(_component_ExistingABProductsComponent, {
              key: "ExistingABProductsComponent",
              class: "pt-3",
              card: _ctx.card
            }, null, 8, ["card"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("a", {
        class: _normalizeClass([_ctx.card.buttonStyle, 'mt-3', {'btn-disabled': !_ctx.isValid}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextStep())),
        textContent: _toDisplayString(_ctx.card.linkText),
        "data-gtm": _ctx.cms.dataGtm,
        "data-gtm-click-text": _ctx.card.routePath
      }, null, 10, _hoisted_9)
    ])
  ]))
}