import { Options, Vue } from 'vue-class-component';
import PageTitleComponent from '@/sharedcomponents/pageComponents/pageTitle/PageTitleComponent.vue';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import BuyCarComponent from './car/BuyCarComponent.vue';
import BuyAccidentComponent from './accident/BuyAccidentComponent.vue';
import BuyAccidentChildrenComponent from './accidentChildren/BuyAccidentChildrenComponent.vue';
import BuyHomeComponent from './home/BuyHomeComponent.vue';
import BuyHouseComponent from './house/BuyHouseComponent.vue';
import BuyTravelComponent from './travel/BuyTravelComponent.vue';
import BuyDogComponent from './dog/BuyDogComponent.vue';
import BuyFamilyComponent from './family/BuyFamilyComponent.vue';
import ContactComponent from './components/ContactComponent.vue';
import { UPDATE_ACTIVE_CALCULATOR, CALCULATOR_LOADED, BASKET_LOADED, CustomerCalculation, RESET_CALCULATORS } from '@/store/modules/calculatorContext';
import BasketWidgetComponent from './components/BasketWidgetComponent.vue';
import BasketComponent from './basket/BasketComponent.vue';
// import MenuComponent from '@/views/overview/_partials/menu';
import { EnvironmentService } from '@/services/environmentService';
// import { UserLoginHandler } from './UserLoginHandler';
import UrlHandler from './UrlHandler';
import { CalculatorPage, HtmlBlock, CardBlock, HeaderCardBlock } from '@/cms/definitions/content-types';
import store from '@/store/store';
import { BuyInsuranceHelper } from './BuyInsuranceHelper';
import CookieFooter from "@/sharedcomponents/pageComponents/cookieFooter/cookieFooter.vue";
import UserLoginHandler from './UserLoginHandler';
import AppService from '@/services/appService';

@Options({
    name: 'BuyInsuranceComponent',
    components: {
        PageTitleComponent,
        BuyCarComponent,
        BuyAccidentComponent,
        BuyAccidentChildrenComponent,
        BuyHomeComponent,
        BuyHouseComponent,
        BuyTravelComponent,
        BasketComponent,
        BuyFamilyComponent,
        BuyDogComponent,
        ContactComponent,
        BasketWidgetComponent,
        // MenuComponent,
        CookieFooter,
    },
    props: {
        model: Object,
    }
})

export default class BuyInsuranceComponent extends Vue {

    model: CalculatorPage; // epi contentModel

    public contentBlocks: Array<CardBlock> = [];
    public receiptBlock: CardBlock;
    public settingsBlock: HtmlBlock;
    public calculatorInfoBlock: HtmlBlock;
    public usabillaBlock: any = null;
    public usabillaBlockLeaveIntent: any = null;
    public contentBlocksLoaded = false;


    public cms: any = null; // from the selected calculator store, used for content i contact component
    public productName: string = null;
    public renderPage= false;
    public loadSpinner = 'spinner-full-page';
    public showMenu: boolean = false;
    public hideLogo = true;
    public headerBadgeTxt: string = null;
    public preTitle: string = '...';
    public mainTitle: string = '...';
    public loadingAgreements = false;
    public static sendLeadMail = true;
    private static onBeforeLoadRegistered = false;


    public beforeCreate() {
        UrlHandler.path; // make sure url is resolved
        const unsubscribe = store.subscribeAction((action, state) => {
            if (action.type === CALCULATOR_LOADED || action.type === BASKET_LOADED) {
                this.cms = action.type === CALCULATOR_LOADED ? store.getters.getActiveCalculator.cms : store.getters.getBasket.cms;
                this.handleUser();
                this.handlePreTitle();
                this.renderPage = true;
                this.setupMailLead();
                // BuyInsuranceHelper.togglePopup(this.cms, {show: true, id:'Test'})
                // console.log('cms loaded');
                unsubscribe();
            }
        });
        store.subscribeAction((action, state) => {
            if (action.type === RESET_CALCULATORS) {
                // don't send leadmail, customer has ordered product(s)
                BuyInsuranceComponent.sendLeadMail = false;
            }
        });

    }

    public async beforeMount() {
        window.addEventListener('resize', this.handlePreTitle);

        // timeout for loadSpinner - takes ages because of load agreements
        setTimeout(() => {
            this.loadSpinner = '';
        }, 8000);

    }

    public async mounted() {
        console.log('BuyInsuranceComponent', this.model);
        
        this.productName = this.model.preTitle.toLocaleLowerCase();

        if (this.productName !== 'kurv') {
            await store.dispatch(UPDATE_ACTIVE_CALCULATOR, this.productName);
        }
        this.handleContentBlocks();
    }

    public isLoggedInAsApp() {
        return AppService.isEnabled();
    }
    private async handleLoggedInUser() {
        this.loadingAgreements = true;
        await UserLoginHandler.loadCustomer(this.cms);
        this.loadingAgreements = false;
    }

    private async handleUser() {
        await this.handleLoggedInUser();
        const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
        // Vis menu hvis bruger er logget ind via en browser og er i samme vindue som mitalmbrand
        this.showMenu = customerCalculation.hasCustomerData
            && EnvironmentService.isWeb()
            && UrlHandler.path.includes('mitalmbrand');

        this.hideLogo = AppService.isEnabled() || this.showMenu;
        BuyInsuranceComponent.sendLeadMail = this.cms.sendLeadMail;
    }

    private setupMailLead() {
        if (BuyInsuranceComponent.onBeforeLoadRegistered) {
            return;
        }
        window.addEventListener("beforeunload", evt => {
            if (BuyInsuranceComponent.sendLeadMail) {
                BuyInsuranceComponent.sendLeadMail = false;
                BuyInsuranceHelper.sendMailLead(this);
            }
            return undefined;
        });
        BuyInsuranceComponent.onBeforeLoadRegistered = true;
    }

    public beforeUnmount() {
		window.removeEventListener('resize', this.handlePreTitle);
	}

    public handlePreTitle() {
        let calculatorInx = store.getters.getActiveCalculatorInx;
        let calcNo: string = '';
        if (calculatorInx > 0 && this.productName !== 'kurv') {
            calcNo = ' ' + (calculatorInx+1);
        }
        try {
            if (screen.width < 768) {
                this.preTitle = this.cms.shortTitle;
                this.mainTitle = this.preTitle !== 'Kurv' ? this.cms.shortMainTitle : this.model.mainTitle;
            } else {
                this.preTitle = this.model.preTitle;
                this.mainTitle = this.model.mainTitle;
            }
            this.preTitle += calcNo;
        } catch (error) {
            this.preTitle = this.model.preTitle;
            this.mainTitle = this.model.mainTitle;
            this.preTitle += calcNo;
        }
    }

    private handleContentBlocks() {
        const blocks: Array<CardBlock> = [];
        this.settingsBlock = { markup: '' } as HtmlBlock;
        this.calculatorInfoBlock = { markup: '' } as HtmlBlock;
        this.model.mainArea.forEach(block => {
            if (block.contentType === 'CardBlock') {
                if (block.name === 'receipt' || block.name === 'reciept') {
                    this.receiptBlock = block as CardBlock;
                    this.receiptBlock.name = 'receipt'; 
                } else {
                    blocks.push(block as CardBlock);
                }
            } else if (block.contentType === 'HeaderCardBlock' ) {
                const headerBlock = block as HeaderCardBlock;
                const text = headerBlock.text && headerBlock.text.trim() === '' ? '' : headerBlock.text;
                const hasText = text !== '';
                const hasCss = headerBlock.css && headerBlock.css?.trim() !== '';
                
                if (hasText) {
                    this.headerBadgeTxt = this.headerBadgeTxt ? this.headerBadgeTxt + text : text
                }
                if (hasCss) {
                    this.headerBadgeTxt = this.headerBadgeTxt ? this.headerBadgeTxt + headerBlock.css : headerBlock.css
                }
                if (headerBlock.script) {
                    const s = document.createElement('script');
                    s.type = 'text/javascript';
                    try {
                      s.appendChild(document.createTextNode(headerBlock.script));
                      document.body.appendChild(s);
                    } catch (e) {
                        console.error(e);
                    }
                }
            } else if (block.contentType === 'HtmlBlock' ) {
                if (block.name.toLocaleLowerCase() === 'global settings') {
                    // global settings first
                    this.settingsBlock.markup = (block as HtmlBlock).markup + this.settingsBlock.markup
                } else if (block.name.toLocaleLowerCase().endsWith('settings')) {
                    this.settingsBlock.markup += (block as HtmlBlock).markup;
                } else if (block.name.toLocaleLowerCase().endsWith('calculatorinfo')) {
                    this.calculatorInfoBlock.markup += (block as HtmlBlock).markup;
                }
            }
            else if (block.contentType === 'UsabillaInPageBlock' ) {
                if (block.name?.toLocaleLowerCase() === 'usabilla leave intent widget') {
                    this.usabillaBlockLeaveIntent = block;
                } else {
                    this.usabillaBlock = block;
                }
            }
        });
        this.contentBlocks = blocks;
        this.contentBlocksLoaded = true;
    }
    public get homeUrl(): string {
        const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
        if (!customerCalculation.hasCustomerData || !this.showMenu) {
            // goto Almbrand.dk
            return 'https://www.almbrand.dk';
        }
        return '/';
    }

    public get isCar(): boolean {
        return this.productName === InsurancesProductEnum.BIL_FORSIKRING;
    }
    public get isAccident(): boolean {
        return this.productName === InsurancesProductEnum.ULYKKES_FORSIKRING;
    }
    public get isAccidentChildren(): boolean {
        return this.productName === InsurancesProductEnum.BOERNEULYKKES_FORSIKRING;
    }
    public get isHome(): boolean {
        return this.productName === InsurancesProductEnum.INDBO_FORSIKRING;
    }
    public get isFamily(): boolean {
        return this.productName === InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING;
    }
    public get isHouse(): boolean {
        return this.productName === InsurancesProductEnum.HUS_FORSIKRING || this.productName === InsurancesProductEnum.FRITIDSHUS_FORSIKRING;
    }
    public get isTravel(): boolean {
        return this.productName === InsurancesProductEnum.REJSE_FORSIKRING;
    }
    public get isDog(): boolean {
        return this.productName === InsurancesProductEnum.HUNDE_FORSIKRING;
    }
    public get isBasket(): boolean {
        return this.productName === 'kurv';
    }
}
