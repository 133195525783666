import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 2,
  class: "validatable"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "flex input__field disabled input--valid" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutocompleteComponent = _resolveComponent("AutocompleteComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["autocomplete__container text-left", [{'input--valid' : !_ctx.dirty && _ctx.isValid && !_ctx.searching}, {'input--searching' : _ctx.searching}]])
  }, [
    (_ctx.label && !_ctx.readonlyValue)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "input__label",
          innerHTML: _ctx.label
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.readonlyValue)
      ? (_openBlock(), _createBlock(_component_AutocompleteComponent, {
          key: 1,
          autocompleteName: "address",
          autocompleteNative: "off",
          onInput: _ctx.search,
          onOnSelect: _ctx.handleSubmit,
          onBlur: _ctx.blur,
          onFocus: _ctx.onFocus,
          autocompleteId: _ctx.autocompleteId,
          results: _ctx.results,
          acRef: 'resultList',
          placeholder: _ctx.placeholder,
          max: 10,
          "input-class": ['autocomplete-input','input__field','autocomplete'],
          "display-item": _ctx.displayItem,
          onAutoCompleteField: _ctx.setAutoCompleteField
        }, null, 8, ["onInput", "onOnSelect", "onBlur", "onFocus", "autocompleteId", "results", "placeholder", "display-item", "onAutoCompleteField"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("label", {
                key: 0,
                class: "input__label",
                innerHTML: _ctx.label
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", { innerHTML: _ctx.readonlyValue }, null, 8, _hoisted_5)
          ])
        ])),
    _createElementVNode("div", { innerHTML: _ctx.testHtml }, null, 8, _hoisted_6)
  ], 2))
}