import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "pb-3 text-left" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inputFieldComponent = _resolveComponent("inputFieldComponent")!
  const _component_PopoverComponent = _resolveComponent("PopoverComponent")!
  const _component_RadioComponent = _resolveComponent("RadioComponent")!
  const _component_SegmentedControlComponent = _resolveComponent("SegmentedControlComponent")!
  const _component_DatePickerComponent = _resolveComponent("DatePickerComponent")!
  const _component_checkboxComponent = _resolveComponent("checkboxComponent")!
  const _component_SearchComponent = _resolveComponent("SearchComponent")!
  const _component_SelectModalComponent = _resolveComponent("SelectModalComponent")!

  return (_ctx.renderPage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.step.policyFields, (field, inx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'field' + inx
          }, [
            (_openBlock(), _createBlock(_Transition, {
              name: "slide-simple",
              tag: "div",
              key: field.fieldId
            }, {
              default: _withCtx(() => [
                (field.type === 'Text only')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", {
                      key: 0,
                      innerHTML: field.prefixLabel,
                      class: "text-left pb-3"
                    }, null, 8, _hoisted_2)), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)),
            (_openBlock(), _createBlock(_Transition, {
              name: "slide-simple",
              tag: "div",
              key: field.fieldId
            }, {
              default: _withCtx(() => [
                (field.type === 'Text')
                  ? _withDirectives((_openBlock(), _createBlock(_component_inputFieldComponent, {
                      class: "text-left pb-3",
                      key: field.fieldId,
                      id: field.fieldId,
                      name: 'value',
                      placeholder: field.placeholder,
                      label: field.prefixLabel,
                      labelPopover: field.toolTip,
                      "___:validateOnLoad": "!model.pristineStep.get(card.name)",
                      innerData: field.model,
                      readonlyValue: field.model.readOnly ? field.model.value : undefined,
                      validationClass: field.model.value ? 'input--valid' : undefined,
                      fieldValidatorType: "default",
                      required: field.required,
                      onInputChanged: ($event: any) => (_ctx.inputChange(field, $event))
                    }, null, 8, ["id", "placeholder", "label", "labelPopover", "innerData", "readonlyValue", "validationClass", "required", "onInputChanged"])), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)),
            (_openBlock(), _createBlock(_Transition, {
              name: "slide-simple",
              tag: "div",
              key: field.fieldId
            }, {
              default: _withCtx(() => [
                (field.type === 'Radio Button')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("span", {
                          innerHTML: field.prefixLabel
                        }, null, 8, _hoisted_5),
                        (field.toolTip)
                          ? (_openBlock(), _createBlock(_component_PopoverComponent, {
                              key: 0,
                              type: "icon",
                              class: "page-component__popover text-left",
                              buttonClass: "popover__icon popover__icon__questionmark",
                              innerData: field.toolTip
                            }, null, 8, ["innerData"]))
                          : _createCommentVNode("", true)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.options, (item, inx) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: _normalizeClass([(inx + 1) === field.options.length ? 'pb-3' : 'pb-2']),
                          key: field.model.id + inx
                        }, [
                          _createVNode(_component_RadioComponent, {
                            name: field.model.id,
                            id: field.model.id + item,
                            inputValue: item,
                            preSelect: field.model.value,
                            trackingLabel: field.model.id,
                            onRadioComponent: ($event: any) => (_ctx.radioClick(field, $event))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(field.labels[inx]), 1)
                            ]),
                            _: 2
                          }, 1032, ["name", "id", "inputValue", "preSelect", "trackingLabel", "onRadioComponent"])
                        ], 2))
                      }), 128))
                    ], 512)), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)),
            (_openBlock(), _createBlock(_Transition, {
              name: "slide-simple",
              tag: "div",
              key: field.fieldId
            }, {
              default: _withCtx(() => [
                (field.type === 'Radio Group')
                  ? _withDirectives((_openBlock(), _createBlock(_component_SegmentedControlComponent, {
                      key: field.fieldId,
                      class: "pb-3",
                      id: field.fieldId,
                      options: field.options,
                      labels: field.labels,
                      preSelected: field.model.value,
                      controlsPerRow: 4,
                      onSegmentedComponent: ($event: any) => (_ctx.selectGroup(field, $event))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "pb-3 pt-3 text-left",
                          innerHTML: field.prefixLabel
                        }, null, 8, _hoisted_6)
                      ]),
                      _: 2
                    }, 1032, ["id", "options", "labels", "preSelected", "onSegmentedComponent"])), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)),
            (_openBlock(), _createBlock(_Transition, {
              name: "slide-simple",
              tag: "div",
              key: field.fieldId
            }, {
              default: _withCtx(() => [
                (field.type === 'Date Picker')
                  ? _withDirectives((_openBlock(), _createBlock(_component_DatePickerComponent, {
                      popoverContent: field.toolTip,
                      key: field.fieldId,
                      class: "text-left mb-3",
                      label: field.prefixLabel,
                      innerId: 'value',
                      innerData: field.model,
                      placeholder: field.placeholder,
                      validationRule: "BeforeToday",
                      dateLimitIncluded: false,
                      readonlyValue: field.model.readOnly ? field.model.value : undefined,
                      onDateSelected: ($event: any) => (_ctx.dateSelected(field, $event))
                    }, null, 8, ["popoverContent", "label", "innerData", "placeholder", "readonlyValue", "onDateSelected"])), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)),
            (_openBlock(), _createBlock(_Transition, {
              name: "slide-simple",
              tag: "div",
              key: field.fieldId
            }, {
              default: _withCtx(() => [
                (field.type === 'Checkbox')
                  ? _withDirectives((_openBlock(), _createBlock(_component_checkboxComponent, {
                      key: field.fieldId,
                      id: field.fieldId,
                      name: 'value',
                      preSelect: field.model.value,
                      inputValue: field.fieldId,
                      class: "pb-3",
                      onCheckboxComponent: ($event: any) => (_ctx.checkboxEvent(field, $event))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          class: "text-left font--small",
                          innerHTML: field.prefixLabel
                        }, null, 8, _hoisted_7)
                      ]),
                      _: 2
                    }, 1032, ["id", "preSelect", "inputValue", "onCheckboxComponent"])), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)),
            (_openBlock(), _createBlock(_Transition, {
              name: "slide-simple",
              tag: "div",
              key: field.fieldId
            }, {
              default: _withCtx(() => [
                (field.type === 'Text Autocomplete')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", {
                      key: field.fieldId,
                      class: "pb-3"
                    }, [
                      _createVNode(_component_SearchComponent, {
                        defaultValue: field.model.value,
                        autocompleteId: field.fieldId,
                        placeholder: field.placeholder,
                        label: field.prefixLabel,
                        searchByCleanedInput: true,
                        resultList: field.uiList,
                        isValid: field.model.value !== undefined,
                        readonlyValue: field.model.readOnly ? field.model.value : undefined,
                        onSearch: ($event: any) => (_ctx.searchSelected(field, $event))
                      }, null, 8, ["defaultValue", "autocompleteId", "placeholder", "label", "resultList", "isValid", "readonlyValue", "onSearch"])
                    ])), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)),
            (_openBlock(), _createBlock(_Transition, {
              name: "slide-simple",
              tag: "div",
              key: field.fieldId,
              class: "text-left"
            }, {
              default: _withCtx(() => [
                (field.type === 'Dropdown')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", {
                      key: field.fieldId,
                      class: "pb-3"
                    }, [
                      _createElementVNode("label", {
                        class: "input__label",
                        innerHTML: field.prefixLabel
                      }, null, 8, _hoisted_8),
                      _createVNode(_component_SelectModalComponent, {
                        id: field.fieldId,
                        name: field.fieldId,
                        placeholder: field.placeholder,
                        preSelect: field.model.value ?  field.model.value : undefined,
                        innerData: field.options,
                        selected: field.model.value !== undefined,
                        "onSelectModalComponent:selected": ($event: any) => (_ctx.modalSelected(field, $event))
                      }, null, 8, ["id", "name", "placeholder", "preSelect", "innerData", "selected", "onSelectModalComponent:selected"])
                    ])), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024))
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}