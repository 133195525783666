import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import CmsPageComponentSelector from '@/cms/components/infrastructure/pageComponentSelector/PageComponentSelector.vue';
import store from '@/store/store';
import AppService from "@/services/appService";
import GtmService from "@/services/gtmService";

import { UPDATE_MODEL_BY_URL } from "@/store/modules/epiContent";
// import BasketProductSelectorComponent from './views/calculators/BasketProductSelectorComponent.vue';

const isInAppContext = AppService.isEnabled();
//const baseUrl = isInAppContext ? window.location.pathname : '';
const baseUrl = '';

// const appCallbackPath = (to, from, next) => {
    
//     // console.log('appCallbackPath', to, from, next);
    
//   // if (AppService.isEnabled()) {
//   //   if (store.getters.getAppReplaceRoute) {
//   //     AppService.sendMessage({ "REPLACE_ROUTE_PATH": to.path });
//   //     store.dispatch('setAppReplaceRoute', false);
//   //   } else {
//   //     AppService.sendMessage({ "ROUTE_PATH":  to.path });
//   //   }
//   // }
//   next();
// };

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/:catchAll(.*)",
  //   component: NotFound,
  // },
  {
    name: 'page-component-selector',
    path: '/:pathMatch(.*)',
    component: CmsPageComponentSelector,
    // beforeEnter: appCallbackPath,
  },
];


const router = createRouter({
  //history: isInAppContext?createWebHashHistory():createWebHistory(),    
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return to scrolled position using browser history back
    // somehow returning savedposition object using Vue's native scrolling not working 
    setTimeout(() => {
      let position = { left: 0, top: 0 };
      if (savedPosition) position = { left: savedPosition.left, top: savedPosition.top }
      window.scrollTo(position.left, position.top)
    }, 300); // match page transition duration
  }
});

router.afterEach((to, from) => {
  buildTracking(to);
})

function buildTracking(to) {
  try {

    //add GTM virtualPath to all SPA routings
    //is business part only works with app
    GtmService.triggerCustomGtmEvent({
      'event': 'track-vp',
      'virtualPath': GtmService.buildTrackingUrl(store.getters.getCustomer, to.fullPath)
    });
  } catch (err) { }
}


// Sends message to app and cancel navigation, must be before epiContent beforeEach!
// router.beforeEach((to, from, next) => {
  
//   // if (isInAppContext && to.query.appMessageKey && to.query.appMessageValue) {
//   //   //supports sending multiple messages to the app via query, appMessageKey and appMessageValue length and order must match
//   //   if (to.query.appMessageKey instanceof Array) {

//   //     for (var i = 0; i < to.query.appMessageKey.length; i++) {
//   //       const key = to.query.appMessageKey[i];
//   //       const value = to.query.appMessageValue[i];
//   //       const message = {};
//   //       message[key] = value;
//   //       AppService.sendMessage(message);
//   //     }
//   //   }
//   //   else {
//   //     const message = {};
//   //     message[to.query.appMessageKey] = to.query.appMessageValue;
//   //     AppService.sendMessage(message);
//   //   }

//   //   return next(false);
//   // }

//   next();
// });


router.beforeEach(async (to, from, next) => {
  // console.log('beforeeach', to);

  // URL is updated by vue-route-sync, and when time travelling with the
  // debugger we don't want to trigger a model commit as the model is already
  // part of the store holding the url update.
  const currentModelUrl = store.state.epiContent.model?.canonicalUrl.path;
  await updateModelByUrlFromFullpath(to, next, currentModelUrl);
});

async function updateModelByUrlFromFullpath(to, next, currentModelUrl) {
  const fullPath = baseUrl + to.fullPath.replace("#", "");
  if (currentModelUrl !== fullPath) {
    await store.dispatch(UPDATE_MODEL_BY_URL, fullPath)
      //dont render next page before model has been updated from url
      next();
  } else {
    next();
  }
}

export default router
