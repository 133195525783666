import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, TransitionGroup as _TransitionGroup, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pb-3" }
const _hoisted_2 = {
  key: "carOwnerYearsLabel",
  class: "input__label",
  textContent: 'Antal år med egen bil'
}
const _hoisted_3 = {
  key: "readonlyCarOwnerYears",
  class: "validatable mb-4"
}
const _hoisted_4 = { class: "flex input__field readonly disabled input--valid" }
const _hoisted_5 = { class: "button-container text-center" }
const _hoisted_6 = ["textContent", "data-gtm", "data-gtm-click-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddressSearchComponent = _resolveComponent("AddressSearchComponent")!
  const _component_inputFieldComponent = _resolveComponent("inputFieldComponent")!
  const _component_SelectModalComponent = _resolveComponent("SelectModalComponent")!
  const _component_ValidationErrorComponent = _resolveComponent("ValidationErrorComponent")!
  const _component_ExistingAbProductsComponent = _resolveComponent("ExistingAbProductsComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AddressSearchComponent, {
        id: "address",
        key: "address",
        elementId: "address",
        isOnlyZipSearch: false,
        label: "Adresse",
        placeholder: "F.eks Gadenavn 29, 2.tv...",
        defaultValue: _ctx.model.personInfo.address || _ctx.defaultAddress,
        isValid: _ctx.model.personInfo.address !== undefined,
        readonly: _ctx.readonlyAddress,
        onAddressSearch: _ctx.addressSearch
      }, null, 8, ["defaultValue", "isValid", "readonly", "onAddressSearch"])
    ]),
    _createVNode(_TransitionGroup, { name: "slide-simple" }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_inputFieldComponent, {
          class: "text-left pb-3",
          key: "customerAge",
          id: "customerAge",
          name: "customerAge",
          placeholder: "F.eks. 42 ",
          label: "Din alder",
          validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
          readonly: _ctx.readonlyAge,
          validationClass: _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge) ? 'input--valid' : undefined,
          innerData: _ctx.model.personInfo,
          required: true,
          onInputChanged: _ctx.setCarOwnerArray
        }, null, 8, ["validateOnLoad", "readonly", "validationClass", "innerData", "onInputChanged"]), [
          [_vShow, _ctx.model.personInfo.address || _ctx.readonlyAge]
        ]),
        (!_ctx.readonlyCarOwnerYears && _ctx.model.personInfo.customerAge && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge))
          ? (_openBlock(), _createElementBlock("label", _hoisted_2))
          : _createCommentVNode("", true),
        (!_ctx.readonlyCarOwnerYears && _ctx.model.personInfo.address && _ctx.model.personInfo.customerAge && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge))
          ? (_openBlock(), _createBlock(_component_SelectModalComponent, {
              key: _ctx.updateCarOwnerYears,
              id: "carOwnerYears",
              name: "carOwnerYears",
              preSelect: _ctx.model.personInfo.carOwnerYears ? _ctx.model.personInfo.carOwnerYears : 'Vælg venligst',
              innerData: _ctx.model.personInfo.carOwnerYearsByAgeArray,
              selected: _ctx.model.personInfo.carOwnerYears !== undefined,
              "onSelectModalComponent:selected": _ctx.selectCarOwnerYears
            }, null, 8, ["preSelect", "innerData", "selected", "onSelectModalComponent:selected"]))
          : _createCommentVNode("", true),
        (!_ctx.readonlyCarOwnerYears && !_ctx.model.pristineStep.get(_ctx.card.name) && _ctx.model.personInfo.carOwnerYears === undefined)
          ? _withDirectives((_openBlock(), _createBlock(_component_ValidationErrorComponent, {
              key: "carOwnerYearsVal",
              class: "input-error--show mt-n5",
              text: 'Du skal vælge hvor lang tid du har haft bil'
            }, null, 512)), [
              [_vShow, _ctx.model.personInfo.address && _ctx.model.personInfo.customerAge && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge)]
            ])
          : _createCommentVNode("", true),
        (_ctx.readonlyCarOwnerYears)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.readonlyCarOwnerYears), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.model.showExistingAlmBrandProducts && _ctx.model.personInfo.customerAge && _ctx.fieldValidator.isValidAge(_ctx.model.personInfo.customerAge) && _ctx.model.personInfo.carOwnerYears)
          ? (_openBlock(), _createBlock(_component_ExistingAbProductsComponent, {
              key: "ExistingAbProductsComponent",
              class: "pt-3",
              card: _ctx.card
            }, null, 8, ["card"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("a", {
        class: _normalizeClass([_ctx.card.buttonStyle, 'mt-3', {'btn-disabled': !_ctx.isValid}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextStep())),
        textContent: _toDisplayString(_ctx.card.linkText),
        "data-gtm": _ctx.cms.dataGtm,
        "data-gtm-click-text": _ctx.card.routePath
      }, null, 10, _hoisted_6)
    ])
  ]))
}