import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";
import { mapState } from "vuex";
import { IContent } from "@/cms/definitions/content";
import ContentComponentSelector from "@/cms/components/infrastructure/contentComponentSelector/ContentComponentSelector.vue";
import getComponentTypeForContent from "@/cms/api/contentComponentSelector";

@Options({
    name: 'ContentAreaComponent',
    components: {
        ContentComponentSelector
    },
    computed: mapState<any>({
        isEditable: state => state.epiContext.isEditable
    }),
    props: {
        model: Array as PropType<IContent[]>,
        rootClass: { type: String, default: "ContentArea" },
        itemClass: { type: String, default: "ContentAreaItem" }
    },
})

export default class ContentAreaComponent extends Vue {
    isEditable = false; // KLK ?
    model: IContent | any;
    rootClass: { type: String, default: "ContentArea" };
    itemClass: { type: String, default: "ContentAreaItem" };

    getComponentTypeForBlock(block: IContent) {
        return getComponentTypeForContent(block);
    }

    getBlockClasses(block: IContent) {
        const clzz = (block as any)?.containerClass ? (block as any).containerClass : '';
        return `${this.itemClass} ${clzz}`;
    }
}
