
import store from '@/store/store';
import AppService from "@/services/appService";
import CookieService from "@/services/cookieService";
import ThemeService from "@/services/themeService";


export async function runAtStart(parent: any) {

    const isInAppContext = AppService.isEnabled();

    ThemeService.changeFavicon(parent.$root['themeFavicon']);

    /** MULTI BRAND HANDLING
    //const themeStr = "--color-primary:#000000;--color-secondary:#EA5D1A;--color-bg-app:#F3f4f6;--color-bg-component:#FFFFFF;--color-interactive-ui:#EA5D1A;--color-interactive-ui-hover:#F08D5F;--color-text:#000000;--color-link:#EA5D1A;--color-active-input:#000000;--color-grey-light:#CCCCCC;--color-grey-medium:#B4B2B2;--color-grey-dark:#817D7D;--border-radius-component:0px;--border-radius-button:4px;--font-family-base:SEATBCN-Regular;--font-family-headings:SEATBCN-Black;--font-family-component-headings:SEATBCN-Regular;--font-family-sub-headings:SEATBCN-Bold;--theme-brand-height:55px";
    //const themeStr = "--color-primary:#000000;--color-secondary:#F50537;--color-bg-app:#F3F4F6;--color-bg-component:#FFFFFF;--color-interactive-ui:#000000;--color-interactive-ui-hover:#333333;--color-text:#000000;--color-link:#F50537;--color-active-input:#000000;--color-grey-light:#CCCCCC;--color-grey-medium:#b4b2b2;--color-grey-dark:#817d7d;--border-radius-component:0;--border-radius-button:0;--font-family-base:AudiType-Normal;--font-family-headings:AudiType-ExtendedBold;--font-family-component-headings:AudiType-Bold;--font-family-sub-headings:AudiType-ExtendedNormal;--theme-brand-height:62px";
    //const themeStr = "--color-primary:#000000;--color-secondary:#001e50;--color-bg-app:#F3F4F6;--color-bg-component:#FFFFFF;--color-interactive-ui:#001e50;--color-interactive-ui-hover:#00b0f0;--color-text:#000000;--color-link:#00B0F0;--color-active-input:#00b0f0;--color-grey-light:#E5E7EB;--color-grey-medium:#9CA3AF;--color-grey-dark:#4B5563;--border-radius-component:0;--border-radius-button:500px;--font-family-base:VWHeadWeb-Regular;--font-family-headings:VWHeadWeb-ExtraBold;--font-family-component-headings:VWHeadWeb-Regular;--font-family-sub-headings:VWHeadWeb-Bold;--theme-brand-height:56px";
    const themeStr = "--color-primary:#000000;--color-secondary:#a7aeb4;--color-bg-app:#F3F4F6;--color-bg-component:#FFFFFF;--color-interactive-ui:#4ba82e;--color-interactive-ui-hover:#419128;--color-text:#000000;--color-link:#4ba82e;--color-active-input:#4BA82E;--color-grey-light:#CCCCCC;--color-grey-medium:#B4B2B2;--color-grey-dark:#817D7D;--border-radius-component:1px;--border-radius-button:23px;--font-family-base:SKODANext-Regular;--font-family-headings:SKODANext-Black;--font-family-component-headings:SKODANext-Regular;--font-family-sub-headings:SKODANext-Bold;--theme-brand-height:56px;";
    const themArr = themeStr.split(";");
    let root = document.documentElement;
    themArr.forEach(element => {
        const parts = element.split(":");
        root.style.setProperty(parts[0], parts[1]);  
    });
    */
  

    /* flyttet til Private.vue
    if (!route.path.startsWith('/beregn/')) {
        this.isBusiness = await this.customerIsBusiness();
    }*/

    if (window.location.href.indexOf("cookies=required_only") > -1) {
        const intervalId = setInterval(() => {
            if ((window as any).CookieInformation !== undefined) {
                (window as any).CookieInformation.declineAllCategories();
            }
            clearInterval(intervalId);
        }, 1000);
    }

    // Get consent cookie and send to app
    if (parent.$root['cookieBanner'] === "True") {
        setInterval(() => {
            if (isInAppContext && store.getters.getConsentSetInApp === false) {
                const cookieInformationConsent = CookieService.getCookie("CookieInformationConsent");

                if (cookieInformationConsent !== null) {
                    store.dispatch("setConsentSetInApp", true);
                    AppService.sendMessage({ "CONSENT_COOKIE": cookieInformationConsent })
                }
            }
        }, 3000);
    }
    
    window.addEventListener("load", function () {
        if (window.fetch && (window as any).usabilla) {
            (window as any).usabilla('setEventCallback', function (category, action, label, value, userData) {
                if (action === "In-Page:Success") {
                    //label = comment
                    //value = 1-5 of score
                    //userData = Responses provided by user
                    fetch('/api/profiles/event', {
                        method: 'POST', headers: { 'Content-Type': 'application/json;charset=utf-8' }, credentials: 'same-origin',
                        body: JSON.stringify({ eventType: 'UsabillaFeedback', payload: { comment: label, score: value, userData: userData }, userInfo: {}, value: value, PageUri: window.location.href, PageTitle: document.title })
                    });
                }
            });
        }
    });
}


