import BuyAccident from './BuyAccidentComponent';
import { monthYearArray, ValuePair, Formatter, BuyInsuranceHelper } from '../BuyInsuranceHelper';
import {PACKAGES, lilleFeatures, mellemFeatures, storFeatures, productId} from './AccidentSettings';
import { cloneDeep } from 'lodash';
import UrlHandler from '../UrlHandler';
import AxiosService from '@/services/axiosService';
import store from '@/store/store';
import CoverageAdapter from '../model_legacy/CoverageAdapter';
import { defaultConfig } from '@/cms/api/ApiConfig';
import GtmService from '@/services/gtmService';
import UrlUtilService from '@/services/urlUtilService';

export default class AccidentCalculator {
    private axiosService: AxiosService;
    private buyAccident: BuyAccident; // component using calculator
    private model: any; // the data structure for state and properties
    private cms: any; // settings from EPI
    private oldCalcConfig: any;
    private tiaCoverages =  new Map<string, Array<CoverageAdapter>>();

    private errorPopup: any;
    private customerAge: string = undefined;

    constructor(buyAccident: BuyAccident) {
        this.buyAccident = buyAccident;
        this.model = buyAccident.model;
        this.cms = buyAccident.cms;

        this.errorPopup = {
            id: 'calcError',
            title: this.cms.defaultCalcErrorTitle,
            content: this.cms.defaultCalcErrorContent,
            btnSecondLabel: 'Ok',
            track: true,
            trackToken: 'error/tiacalculator',
            show: true,
        }
        // , { name: 'x-api-key', value: '4c4b2f67-5f9c-49d9-b1db-09c72ab2c968'}

        // const headers = [{ name: 'quote-api', value: 'true'}];
        // this.axiosService = new AbAxiosSelfservice(store.getters.getConfigEpi.openServiceUrl + this.calculationUrl,
        //     'application/json',
        //     store.getters.getConfigEpi.csrfToken,
        //     headers,
        //     3);
        // this.axiosService.useRecaptcha(true);
        const ulykkeCalculatorUrl =  defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/accident/offers';
        this.axiosService = new AxiosService(ulykkeCalculatorUrl);

        this.setUpConfig();
    }

    private async setupTiaCoverages(): Promise<any> {
        return new Promise( (resolve) => {
            let coverageAdaptors: Array<CoverageAdapter> = [];
            this.cms.getFeaturesByAge(lilleFeatures, this.customerAge).forEach( productFeature => {
                coverageAdaptors.push(...this.getMappedTiaCoverage(productFeature));
            });
            this.tiaCoverages.set(PACKAGES.LILLE, coverageAdaptors);

            coverageAdaptors = [];
            let features = this.cms.extendCoverages ? mellemFeatures : [...lilleFeatures, ...mellemFeatures];
            this.cms.getFeaturesByAge(features, this.customerAge).forEach( productFeature => {
                coverageAdaptors.push(...this.getMappedTiaCoverage(productFeature));
            });
            this.tiaCoverages.set(PACKAGES.MELLEM, coverageAdaptors);

            coverageAdaptors = [];
            features = this.cms.extendCoverages ? storFeatures : [...lilleFeatures, ...mellemFeatures, ...storFeatures];
            this.cms.getFeaturesByAge(features, this.customerAge).forEach( productFeature => {
                coverageAdaptors.push(...this.getMappedTiaCoverage(productFeature));
            });
            this.tiaCoverages.set(PACKAGES.STOR, coverageAdaptors);

            coverageAdaptors = [];
            features = this.cms.extendCoverages ? [...mellemFeatures, ...storFeatures] : [...lilleFeatures, ...mellemFeatures, ...storFeatures];
            this.cms.getFeaturesByAge(features, this.customerAge).forEach( productFeature => {
                coverageAdaptors.push(...this.getMappedTiaCoverage(productFeature));
            });

            this.tiaCoverages.set(PACKAGES.SENIOR, coverageAdaptors);

            return resolve(true);
        });
    }

    private getMappedTiaCoverage(productFeature): Array<CoverageAdapter> {
        const coveragesForPackage: Array<CoverageAdapter> = [];
        let coverageFound = false;
        this.cms.productInfo.products.features.forEach( feature => {
            if (feature.feature_id === productFeature.name ) {
                const _coverage = {
                    description: this.cms.getCoverageDescription(feature.title, feature.description),
                    feature_id: feature.feature_id,
                    name: this.cms.getCoverageName(feature.title),
                    sub_features: [],
                }
                const coverageAdapter: CoverageAdapter = new CoverageAdapter(_coverage);
                coveragesForPackage.push(coverageAdapter);
                coverageFound = true;
                // insert sub_feature_ids around here
            }
        });
        // some features are listed as subfeatures in .../category/products. For now, they are elavated to features.
        if(!coverageFound) {
            this.cms.productInfo.products.sub_features.forEach( subfeature => {
                if (subfeature.sub_feature_id === productFeature.name ) {
                    const _coverage = {
                        description: this.cms.getCoverageDescription(subfeature.title, subfeature.description),
                        feature_id: subfeature.sub_feature_id,
                        name: this.cms.getCoverageName(subfeature.title),
                        sub_features: [],
                    }
                    const coverageAdapter: CoverageAdapter = new CoverageAdapter(_coverage);
                    coveragesForPackage.push(coverageAdapter);
                    coverageFound = true;
                }
            });
        }
        if (!coverageFound) {
            console.error('Could not find feature with name', productFeature.name);
        }
        return coveragesForPackage;
    }

    private mapCoveragesFromCalc() {
        this.model.calculation.allCoverages = new Map<number, Map<number, Array<CoverageAdapter>>>();
        this.model.calculation.abCalc.packs.forEach( (calculations, ownRiscId) => {
            const coveragesForPackage = new Map<number, Array<CoverageAdapter>>();
            calculations.forEach((calc, packageInx) => {
                coveragesForPackage.set(packageInx, this.tiaCoverages.get(calc.name));
                calc.tiaCoverages = this.tiaCoverages.get(calc.name);
                if (this.cms.extendCoverages) {
                    if(calc.name === PACKAGES.LILLE) {
                        // calc.expandedCoverages = this.tiaCoverages.get(calc.name);
                        calc.expandedCoverages = [...this.tiaCoverages.get(PACKAGES.LILLE).map( (e) => e.title)];
                    } else if(calc.name === PACKAGES.MELLEM) {
                        calc.expandedCoverages = [...this.tiaCoverages.get(PACKAGES.LILLE).map( (e) => e.title), ...this.tiaCoverages.get(calc.name).map( (e) => e.title)];
                    } else if (calc.name === PACKAGES.STOR) {
                        calc.expandedCoverages = [...this.tiaCoverages.get(PACKAGES.LILLE).map( (e) => e.title),...this.tiaCoverages.get(PACKAGES.MELLEM).map( (e) => e.title), ...this.tiaCoverages.get(calc.name).map( (e) => e.title)];
                    } else if(calc.name === PACKAGES.SENIOR) {
                        calc.expandedCoverages = [...this.tiaCoverages.get(PACKAGES.LILLE).map( (e) => e.title), ...this.tiaCoverages.get(calc.name).map( (e) => e.title)];
                    }
                }
            });
            this.model.calculation.allCoverages.set(ownRiscId, coveragesForPackage);
        });
    }

    public async setUpConfig() {
        this.customerAge = this.model.personInfo.otherCustomerAge || this.model.personInfo.customerAge;
        // hardcoded oldConfig
        this.oldCalcConfig = this.cms.oldCalcConfig.insuranceConfiguration;

        this.cms.mapCoverages(this.cms.oldCalcConfig.textMappingsOuter);

        this.model.calculation.abCalc = {
            excessIdDefault: this.oldCalcConfig.excessIdDefault,
            packageIds: this.oldCalcConfig.packageIds,
            packages: cloneDeep(this.oldCalcConfig.packages),
        };

        this.setupExcessList();
        await this.setupTiaCoverages();
        this.model.calculation.abCalc.packages.forEach(pack => {
            pack.coverages = this.tiaCoverages.get(pack.name);
        });

        this.model.calculation.abCalc.packs = new Map<number, Object>();
        this.model.calculation.abCalc.excesses.forEach(excess => {
            if (excess.id === this.model.calculation.abCalc.excessIdDefault) {
                const calcs = [];
                this.model.calculation.abCalc.packs.set(excess.id, calcs);
                this.model.calculation.abCalc.packages.forEach( (pack) => {
                    calcs.push(this.buyAccident.helper.reducePack(cloneDeep(pack)));
                });
            }
        });

        if (this.customerAge && this.customerAge >= this.cms.seniorStep2) {
            // remove mellem pack
            const reducedCalcs = this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).filter( (calc) => {
                return calc.name !== PACKAGES.MELLEM;
            });
            this.model.calculation.abCalc.packs.set(this.model.calculation.abCalc.excessIdDefault, reducedCalcs);
            // rename STOR pack name to Senior
            this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach( (calc) => {
                if (calc.name === PACKAGES.STOR) {
                    calc.name = PACKAGES.SENIOR;
                }
            });
        }
        this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'

        this.mapCoveragesFromCalc();
        this.updateCalcKeys();
    }

    public setupExcessList() {
        this.model.calculation.abCalc.excesses = this.oldCalcConfig.excessList;
        if (this.model.calculation.abCalc.excesses[0].id === this.model.calculation.abCalc.excessIdDefault) {
            // ensure default excess is last in list
            this.model.calculation.abCalc.excesses.reverse();
        }
        this.model.ownRiskOptions = [];
        this.model.ownRiskLabels = [];

        this.model.calculation.abCalc.excesses.forEach((excess) => {
            if (excess.id === this.model.calculation.abCalc.excessIdDefault) {
                this.model.ownRiskOptions.push(excess.id);
                this.model.ownRiskLabels.push(Formatter.format(excess.amount) + ' kr.');
            }
        });
    }
    public async getCalculations(excessId?: number) : Promise<boolean> {
        if (this.model.calculation.isCalculated) {
            return;
        }
        this.model.showSpinner = true;
        this.model.calculating = true;
        // handle "eternal" spinning
        setTimeout(() => {
            if(this.model.showSpinner) {
                this.model.showSpinner = false;
                this.model.calculating = false;
            }
        }, this.cms.calculationSpinnerTimeout);

        // reset calculation and setup coverages by age
        await this.setUpConfig();
        this.model.calculation.abCalc.calculated = true;

        try {
            const rebate = this.buyAccident.helper.getDiscount();
            this.model.calculation.discount = rebate.discount;
            this.model.calculation.discountDisplay = rebate.discountDisplay;

            const promises = [];
            promises.push(this.calculateQuickQuote(PACKAGES.LILLE));
            if (parseInt(this.customerAge.replace(/\D/g,'')) < this.cms.seniorStep2) {
                promises.push(this.calculateQuickQuote(PACKAGES.MELLEM));
                promises.push(this.calculateQuickQuote(PACKAGES.STOR));
            } else {
                promises.push(this.calculateQuickQuote(PACKAGES.SENIOR));
            }

            await Promise.all(promises);
            this.model.calculation.isCalculated = true;
            this.model.showSpinner = false;
            this.model.calculating = false;
            GtmService.triggerCustomGtmEvent({
                'event': 'track-vp',
                'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/prices_shown`,
            });
            return Promise.resolve(true);
        } catch(err) {
            console.error('Accident offer', err);
            GtmService.triggerCustomGtmEvent({
                'event': 'track-vp',
                'virtualPath': `${UrlHandler.getTrackingPreUrl()}${this.model.productName}/error/tiaonlineoffer`,
            });

            console.error(err);
            BuyInsuranceHelper.togglePopup(this.cms, this.errorPopup);
            this.model.showSpinner = false;
            this.model.calculating = false;
            return Promise.resolve(false);
        }
    }

    private async calculateQuickQuote(productType: string): Promise<any> {
        const customerAge = parseInt(this.customerAge.trim().replace(/\D/g,''));
        let features: Array<any> = this.getFeatures(productType);
        if (!features) {
            return Promise.reject();
        }
        const createOffer: any =
        {
            product: {
                product_name: productId,
                features,
                parameters: {
                    age: customerAge,
                    postalCode: this.model.personInfo.zipCode,
                    employment: this.model.personInfo.workId,
                },
            }
        };

        try {
            const res = await this.axiosService.post(undefined, createOffer);
            const offer = res.data;
            if (!offer || !offer.price || !offer.price.annually) {
                throw new Error();
            }
            this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach(calc => {
                if (calc.name === productType) {
                    let totalPrice = offer.price.monthly;
                    calc.basePrice = totalPrice;
                    calc.statutoryFee = 0; // not reurned by api

                    // pluscustomer discount
                    totalPrice *= this.model.calculation.discount;

                    // campaign discount
                    if (this.model.campaign.valid)  {
                        totalPrice -= (totalPrice * this.model.campaign.discount);
                    }

                    calc.totalPrice = Math.round(totalPrice);
                    calc.totalPriceDisplay = Formatter.format(calc.totalPrice) + ' kr.';

                    totalPrice = offer.price.annually;
                    calc.yearlyBasePrice = totalPrice;
                    calc.yearlyStatutoryFee = 0; // not reurned by api

                    // pluscustomer discount
                    totalPrice *= this.model.calculation.discount;

                    // campaign discount
                    if (this.model.campaign.valid)  {
                        totalPrice -= (totalPrice * this.model.campaign.discount);
                    }
                    calc.yearlyPriceTotal = Math.round(totalPrice);
                    calc.yearlyPriceTotalDisplay = Formatter.format(calc.yearlyPriceTotal) + ' kr.';
                    this.updateCalcKeys();
                }
            });

            return Promise.resolve();
        } catch(err) {
            console.error(err);
            if (!UrlUtilService.isDevelop()) {
                BuyInsuranceHelper.togglePopup(this.cms, this.errorPopup);
                return Promise.reject(err);
            } else if (this.cms.mockData) {
                // MOCK
                let add = 2000;
                this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach(calc => {
                    add += 100;
                    if (calc.name === productType) {
                        let totalPrice = add;
                        calc.basePrice = totalPrice;
                        calc.statutoryFee = 0; // not reurned by api
                        if (this.model.campaign.valid)  {
                            totalPrice -= (totalPrice * this.model.campaign.discount);
                        }

                        calc.totalPrice = Math.round(totalPrice * this.model.calculation.discount);
                        calc.totalPriceDisplay = Formatter.format(calc.totalPrice) + ' kr.';

                        totalPrice = 12 * add * .97;
                        calc.yearlyBasePrice = totalPrice;
                        calc.yearlyStatutoryFee = 0; // not reurned by api

                        if (this.model.campaign.valid)  {
                            totalPrice -= (totalPrice * this.model.campaign.discount);
                        }
                        calc.yearlyPriceTotal = Math.round(totalPrice * this.model.calculation.discount);
                        calc.yearlyPriceTotalDisplay = Formatter.format(calc.yearlyPriceTotal) + ' kr.';
                        this.updateCalcKeys();
                    }
                });
            }
        };
    }

    private getFeatures(productType: string): Array<any> {
        switch(productType) {
            case PACKAGES.LILLE :  return this.cms.getFeaturesByAge(lilleFeatures, this.customerAge);
            case PACKAGES.MELLEM : return  this.cms.getFeaturesByAge([...lilleFeatures, ...mellemFeatures], this.customerAge);
            case PACKAGES.STOR : return this.cms.getFeaturesByAge([...lilleFeatures, ...mellemFeatures, ...storFeatures], this.customerAge);
            case PACKAGES.SENIOR : return this.cms.getFeaturesByAge([...lilleFeatures, ...mellemFeatures, ...storFeatures], this.customerAge);
            default: console.warn('no package for calculation selected', productType);
                return undefined;
        }
    }

    public updateCalcKeys() {
        this.model.calculation.updatePrices = this.model.calculation.updatePrices.map( (elem) => {
            return elem += '1';
        });
    }
}
