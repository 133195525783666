import { Options, Vue } from "vue-class-component";
import AxiosService from "@/services/axiosService";
import store from '@/store/store';
import Autocomplete from 'vue3-autocomplete';
import { cloneDeep } from 'lodash';
import { defaultConfig } from "@/cms/api/ApiConfig";
// import { getCarLicenseplateSearch } from '@/views/TestData/calculators/apiTestData';

@Options({
    name: "CarSearchComponent",
    components: {
    },
    props: {
        defaultValue: {type: String, default: null},
        label: String,
        isValid: {type: Boolean, default: false},
    }
})
export default class CarSearchComponent extends Vue {
    defaultValue?: string; // if model has value
    label: string;
    isValid?: boolean; // valid searchresult

    public results = [];
    //private searchUrl = "/webapi/CarInsurance/CarSearch?";
    // private searchParams = "searchType=model&searchText=";
    private searchUrl: string = '';
    private brandModelSelected = 'false';
    private autocomplete: Autocomplete;
    public lastResult: any;
    private simularResults = {count: 0};
    public searching = false;

    public axiosService = new AxiosService();

    public mounted() {
        this.searchUrl = defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + 
        '/insurance/car/search/by-text-v2?';
    }

    public setAutoCompleteField(input) {
        this.autocomplete = input;
        if (this.defaultValue) {
            this.autocomplete.setText(this.defaultValue);
        } else {
            setTimeout(() => {
                this.autocomplete.$refs.autocompleteRef.focus();
            }, 50);
        }
    }


    public async search(input): Promise<any> {
        this.searching = true;
        if (input.length < 2) {
            this.searching = false
            return Promise.resolve([]);
        }
        
        input = this.handleSpecialChars(input);
        
        const url = `${this.searchUrl}brand_model_selected=${this.brandModelSelected}&text=${encodeURI(input)}`;//&searchType=${this.type}
        const response = await this.axiosService.getRetry(url);
        if (response?.status !== 200) {
            this.$emit('CarSearchError');
            this.searching = false;
            this.results = [];
            return;
        }
       
        this.handleMatches(response.data);
        if (this.lastResult && response.data.matches?.length === 1) {
            if (this.lastResult.matchText === response.data.matches[0].matchText) {
                this.simularResults.count += 1;
                if (this.simularResults.count > 2) {
                    this.$emit('CarSearchProblem', this.autocomplete.searchText);
                    this.simularResults.count = -1;
                }
            } else {
                this.simularResults.count = 0;
            }
        }
        this.fillResults(response.data.matches, input);
    }

    private handleSpecialChars(input: string): string {
        const inp = input?.toLocaleLowerCase();
        //if(['volkswagen', 'citroe', 'citroé', 'citroè'].includes(inp)) {
        if(inp?.startsWith('citro') || input.startsWith('volkswagen')) {
            return input.replaceAll(/citroe|citroé|citroè/ig, 'Citroë').replace(/volkswagen/ig, 'VW');
        }
        return input;
    }

    private handleMatches(data) {
        this.results = [];
        if(data.matches.length === 1) {
            const match = cloneDeep(data.matches[0]);
            // check if model_brand_selected
            if (this.brandModelSelected) {
                // check amount variants
                // console.log('variants length', match.variants[0].values.length);
                const tokens = match.brand_model_no_match_tokens;
                const variants = match.variants[0].values;
                if (variants.length < 20 || tokens.length === 2) { // always show variants when year and fueltype search is done
                    data.matches = [];
                    variants.forEach(variant => {
                        const doors = this.getDoors(variant.variant_details.doors);
                        let displayText = `${match.brand_model} 
                            ${variant.variant_details.year} 
                            ${variant.variant_details.fuel_type} 
                            ${doors} 
                            ${variant.variant_name}`;
                        displayText = displayText.replaceAll('  ', ' ').replaceAll(' undefined','').trim();
                        data.matches.push({
                            matchText: displayText,
                            brand: match.brand,
                            model: match.model,
                            brand_model: match.brand_model,
                            variant_id: variant.variant_id,
                            variant: variant.variant_name,
                            group: variant.variant_group,
                            doors: variant.variant_details.doors,
                            // kid: 
                            details: {
                                weight: variant.variant_details.wieght,
                                fuel_type: variant.variant_details.fuel_type,
                                year: variant.variant_details.year,
                            },
                            special: variant.variant_special
                            // + variant.variant_details.body_style
                        });
                    });
                    (this.$refs.AbAutoComplete as any).forceSearch();
                    return;
                }

                const tokenString = tokens.join(' ');
                // check list_of_values, firstly years
                if (data.list_of_values?.years && tokens.length === 0) {
                    const years = data.list_of_values.years.reverse();
                    data.matches = [];
                    years.forEach(year => {
                        data.matches.push({
                            matchText: match.brand_model + ' ' + year,
                            brand: match.brand,
                            model: match.model,
                            brand_model: match.brand_model,
                        });
                    });
                    this.fillResults(data.matches, this.autocomplete.searchText); // fill results
                    (this.$refs.AbAutoComplete as any).forceSearch();
                    return;
                                        
                } else if (data.list_of_values?.fuel_types &&  tokens.length === 1) {
                    const fuelTypes = data.list_of_values.fuel_types;
                    data.matches = [];
                    //match.brand_model_no_match_tokens
                    fuelTypes.forEach(fuelType => {
                        data.matches.push({
                            matchText: match.brand_model + ' ' + tokenString + ' ' + fuelType,
                            brand: match.brand,
                            model: match.model,
                            brand_model: match.brand_model,
                        });
                    });
                    (this.$refs.AbAutoComplete as any).forceSearch();
                    return;
                }
            }
            return;
        }
        if (data.matches?.length > 0) {
            // string matchText = $"{car.Brand} {car.Model} {car.Details.Year} {car.Variant}";
            const match = cloneDeep(data.matches);
            data.matches = [];
            match.forEach(match => {
                if (!match.matchText) {
                    match.matchText = match.brand_model;
                }
                data.matches.push(match);
                
            });
        }
    }
    private getDoors(doors: any): string {
        if(!doors || doors === '' || doors === 'undefined') {
            return '';
        }
        if (doors  === 1) {
            return doors + '-dør'
        } else {
            return doors + '-døre'
        }
    }
    private fillResults(matches, input) {
        this.results = [];
        if(matches.length < 2) {
            this.results.push(matches[0]);
            this.searching = false;
            return;
        }
        const inp = input.trim();
        matches.forEach(element => {
            if (element.matchText === inp && (!element.variant_id || element?.variant_id === 0)) {
                console.warn('simular car search skipped:', element.matchText);
            // } else if (false) { //this.autocomplete.searchText
            //     console.warn('simular car search skipped:', element.matchText);
            } else {
                this.results.push(element);
            }
        });
        // console.log('fillResults', this.results);
        this.searching = false;
    }

    public displayItem(item) {
        return item?.matchText ? item.matchText : undefined;
    }

    public handleSubmit(result) {
        // console.log('handleSubmit', result);
        this.lastResult = result;
        if(result && result.brand) {
            this.tryEmit(result);
        }
    }
    public onFocus(evt) {
        this.$emit('CarSearchFocus', this.autocomplete.searchText);
    }
    public blur(evt) {
        // console.log('blur');
    }

    private tryEmit(result) {
        // console.log('try emit result', result);
        
        const car = result;
        if (car?.variant_id) {
            // this.autocomplete.$refs.autocompleteRef.blur();
            // this.autocomplete.$refs.autocompleteRef.focus();
            this.setCarMatchTxt(car);
            this.autocomplete.setText(car.matchText);
            this.$emit('CarSearch', {
                matchText: result.matchText,
                car
            });
            this.autocomplete.$refs.autocompleteRef.blur()
            
        } else {
            if (car?.brand !== '' && car?.model != '') {
                this.brandModelSelected = 'True';
            } else {
                this.brandModelSelected = 'False';
            }
            this.autocomplete.setText(result.matchText + ' ');
            // trigger new search
            this.search(this.autocomplete.searchText);

        }
        this.searching = false;
    }

    private setCarMatchTxt(car) {
        const doors = this.getDoors(car.doors);
        let displayText = `${car.brand_model} ${car.details.year} ${car.details.fuel_type} ${doors} ${car.variant}`;
        displayText = displayText.replaceAll('  ', ' ');
        car.matchText = displayText;
    }
}
