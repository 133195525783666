import { ADD_PROPOSAL_INFO } from "@/store/modules/ProposalContext";
import store from "@/store/store";
import { cloneDeep } from 'lodash';
import TestConfig from "./TestData/TestData";
export default class ModelMapper {
    private proposal: any;
    private cmsModel: any;

    public formatter: Intl.NumberFormat = Intl.NumberFormat("da-DK", { minimumFractionDigits: 0,  maximumFractionDigits: 0 } as any);


    constructor(proposal: any, cmsModel: any) {
        this.proposal = proposal.data;
        this.cmsModel = cmsModel;
        store.dispatch(ADD_PROPOSAL_INFO, proposal.data);

    }

    public getPropsalSteps() : Array<any> {
        // console.log('proposal', this.proposal);
        // console.log('cmsModel', this.cmsModel);
        const proposalSteps = [];
        // this.mapPaymentStep(proposalSteps);
        // store?
        const policies = this.proposal.policies;
        policies.forEach(policy => {
            const cmsPolicy = this.getCmsPolicy(policy.type);
            
            cmsPolicy.id = policy.id;
            // console.log('mapping policy', policy.id);

            cmsPolicy.policyFields.forEach(cmsField => {
                const modelField = policy.fields.find( modelField => modelField.id === cmsField.fieldId);
                if (modelField) {
                    cmsField.model = this.sanitizeModel(modelField);
                } else {
                    cmsField.model = { 
                        id: cmsField.fieldId,
                        readOnly: false,
                        value: undefined,
                        isValid: false,
                    }
                }
            });

            cmsPolicy.title = this.getPolicyTitle(cmsPolicy.type);
            cmsPolicy.dataGtm = 'tracking variable'; // Todo
            cmsPolicy.subtitle = `
                <div class="flex flex__space">
                    <span>${policy.description}</span> <span><strong>${this.formatter.format(policy.price)} kr./år</strong></span>
                </div>`;
            cmsPolicy.buttonLabel = 'Fortsæt';
            this.addSharedFields(policy, cmsPolicy);
            
            TestConfig.enrichCMSPolicyFields(cmsPolicy);

            proposalSteps.push(cmsPolicy);

        });
        // console.log('policiesForUi', policiesForUi);
        this.mapPreviousInsuranceStep(proposalSteps);
        this.mapConsentStep(proposalSteps);
        this.mapPaymentStep(proposalSteps);
        return proposalSteps;
    }

    private addSharedFields(policy, cmsPolicy) {
        // cancelExistingPolicy - Radio - Skal forsikring opsiges i nuværende selskab ja/nej
        // cancelExistingPolicyBy - Date - Min nuværende forsikring skal opsiges pr. 
        // existingPolicyActiveFor - tid - Min nuværende forsikring har været aktiv? ??? (dropdown)
        // existingInsuranceCompany - autocomplete  - Nuværende forsikringsselskab
        // existingPolicyId - text - Policenummer
        // offerId - text (readOnly)- TIlbudsnummer
        // customerDetails - text (readonly) - Dine oplysninger 
        const ignoreFields = ['offerId', 'customerDetails']
        const hasTerminatedOther = policy.termination;
        this.cmsModel.adjustPoliciesBlock.sharedFields.forEach(sharedField => {
            if (ignoreFields.includes(sharedField.fieldId)) {
                return;
            }
            
            const field = cloneDeep(sharedField);
            field.model = { 
                id: field.fieldId,
                readOnly: false,
                value: undefined,
                isValid: false,
            }

            switch(field.fieldId) {
                case 'existingPolicyId' :
                case 'existingInsuranceCompany' :
                case 'cancelExistingPolicyBy' :
                    field.isDependent = true;
                    field.dependentId = 'cancelExistingPolicy';
                    field.dependentValue = 'ja';
                    break;
                case 'existingPolicyActiveFor' :
                    field.isDependent = true;
                    field.dependentId = 'existingPolicyId';
                    field.dependentValue = '*';
                    break;
            }

            if (hasTerminatedOther) {
                switch(field.fieldId) {
                    case 'existingInsuranceCompany' : field.model.readOnly = true;
                        field.model.isValid = true;
                        field.model.value = policy.termination.previousCompanyId;
                        break;
                    case 'existingPolicyId' : field.model.readOnly = true;
                        field.model.isValid = true;
                        field.model.value = policy.termination.previousPolicyId;
                        break;
                }
            }
            cmsPolicy.policyFields.push(field);
        });

        
        
    }

    private mapPreviousInsuranceStep(proposalSteps: Array<any>) : void {
        const cmsBlock = this.cmsModel.previousInsuranceBlock;
        const step: any = {};
        step.title = cmsBlock.stepHeadline;
        
        step.policyFields = [];

        // maincontent - Text only
        // secondary content - Text only
        const textOnly = {
            fieldId: "mainContent",
            prefixLabel: cmsBlock.mainContent,
            type: 'Text only',
            isVisible: true,
            model: { 
                id: 'mainContent',
                // readOnly: true,
                // value: undefined,
                isValid: true,
            }
        };

        const checkField = {
            fieldId: 'consent',
            prefixLabel: cmsBlock.checkboxLabel,
            type: 'Checkbox',
            isVisible: true,
            model: { 
                id: 'consent',
                readOnly: false,
                value: undefined,
                isValid: false,
            }
        };

        const textOnly1 = {
            fieldId: 'secondaryContent',
            prefixLabel: cmsBlock.secondaryContent,
            type: 'Text only',
            isVisible: true,
            model: { 
                id: 'secondaryContent',
                // readOnly: true,
                // value: undefined,
                isValid: true,
            }
        };

        step.policyFields.push(textOnly);
        step.policyFields.push(checkField);
        step.policyFields.push(textOnly1);
        
        // missing
        step.id = 'consentsBlock';
        step.dataGtm = 'tracking variable';
        step.subtitle = undefined;
        step.type = 'consentsBlock';
        step.buttonLabel = 'Fortsæt';
        // proposalSteps.splice(0, 0, conditionsStep);
        proposalSteps.push(step);
    }

    private mapConsentStep(proposalSteps: Array<any>) : void {
        const cmsBlock = this.cmsModel.consentsBlock;
        const step: any = {};
        step.title = cmsBlock.stepHeadline;
        
        step.policyFields = [];

        // maincontent - Text only
        // secondary content - Text only
        const textOnly = {
            fieldId: "mainContent",
            prefixLabel: cmsBlock.mainContent,
            type: 'Text only',
            isVisible: true,
            model: { 
                id: 'mainContent',
                // readOnly: true,
                // value: undefined,
                isValid: true,
            }
        };

        const checkField = {
            fieldId: 'acceptAllConsent',
            prefixLabel: cmsBlock.acceptAllCheckboxLabel,
            type: 'Checkbox',
            isVisible: true,
            model: { 
                id: 'acceptAllConsent',
                readOnly: false,
                value: undefined,
                isValid: false,
            }
        };

        const textOnly1 = {
            fieldId: 'secondaryContent',
            prefixLabel: cmsBlock.secondaryContent,
            type: 'Text only',
            isVisible: true,
            model: { 
                id: 'secondaryContent',
                // readOnly: true,
                // value: undefined,
                isValid: true,
            }
        };


        step.policyFields.push(textOnly);
        step.policyFields.push(checkField);
        step.policyFields.push(textOnly1);
        
        // missing
        step.id = 'conditionsBlock';
        step.dataGtm = 'tracking variable';
        step.subtitle = undefined;
        step.type = 'conditionsBlock';
        step.buttonLabel = 'Fortsæt';
        // proposalSteps.splice(0, 0, conditionsStep);
        proposalSteps.push(step);
    }

    
    private mapPaymentStep(proposalSteps: Array<any>) : void {
        const cmsBlock = this.cmsModel.paymentMethodBlock;
        const step: any = {};
        step.title = cmsBlock.stepHeadline;
        
        step.policyFields = [];
        
        const policies = this.proposal.policies;
        let uiField = {};
        policies.forEach(policy => {
            const title = this.getPolicyTitle(policy.type);
            const policyentry = `<div class="policy-entry"><strong>${title}</strong><br/>
            <div class="flex flex__space">
             <span>${policy.description}</span> <span>${this.formatter.format(policy.price)} kr./år</span>
            </div>
            <br/> </div>`;

            uiField = {
                fieldId: this.proposal,
                prefixLabel: policyentry,
                type: 'Text only',
                isVisible: true,
                model: { 
                    id: this.proposal.fee,
                    readOnly: true,
                    value: undefined,
                    isValid: true,
                }
            };    
            step.policyFields.push(uiField);
        });

        if (this.proposal.discountCampaign) {
            const campaignTxt = `
            <div class="flex flex__space mb-n3">
             <span>${this.proposal.discountCampaign}</span> <span>${this.proposal.discountCampaignValue}</span>
            </div>`;

            uiField = {
                fieldId: 'campaign',
                prefixLabel: campaignTxt,
                type: 'Text only',
                isVisible: true,
                model: { 
                    id: 'campaign',
                    readOnly: true,
                    value: undefined,
                    isValid: true,
                }
            };
            step.policyFields.push(uiField);
        }
        
        if (this.proposal.discount) {
            const txt = `
            <div class="flex flex__space mb-n3">
            <span><i>Plus</i>Kunderabat</span> <span>${this.formatter.format(this.proposal.discount)} kr./år</span>
            </div>`;
            uiField = {
                fieldId: 'discount',
                prefixLabel: txt,
                type: 'Text only',
                isVisible: true,
                model: { 
                    id: 'discount',
                    readOnly: true,
                    value: undefined,
                    isValid: true,
                }
            };
            step.policyFields.push(uiField);
        }


        const txt = `
        <div class="flex flex__space mb-n5">
        <span><h3>Din samlede pris</h3></span> <span><h3>${this.formatter.format(this.proposal.price)} kr./år</h3></span>
        </div>`;
        uiField = {
            fieldId: 'discount',
            prefixLabel: txt,
            type: 'Text only',
            isVisible: true,
            model: { 
                id: 'discount',
                readOnly: true,
                value: undefined,
                isValid: true,
            }
        };
        step.policyFields.push(uiField);

        uiField = {
            fieldId: 'fee',
            prefixLabel: cmsBlock.feeLabel + ' ' + this.formatter.format(this.proposal.fee) + ' kr. <br/><br/>',
            type: 'Text only',
            isVisible: true,
            model: { 
                id: 'fee',
                readOnly: true,
                value: undefined,
                isValid: true,
            }
        };
        step.policyFields.push(uiField);

        uiField = {
            fieldId: 'selectPaymentMethodLabel',
            prefixLabel: cmsBlock.selectPaymentMethodLabel,
            type: 'Radio Button',
            isVisible: true,
            options: ['kort', 'service'],
            labels: ['Indbetalingskort (35 kr. pr. betaling)', 'Betalingsservice (7 kr. pr. forsikring)'],
            model: { 
                id: 'selectPaymentMethodLabel',
                readOnly: false,
                value: undefined,
                isValid: false,
            }
        };
        step.policyFields.push(uiField);

        uiField = {
            fieldId: 'regnr',
            prefixLabel: 'Reg. nr.',
            type: 'Text',
            isVisible: false,
            placeholder: 'F.eks. 0000',
            model: { 
                id: 'regnr',
                readOnly: false,
                value: undefined,
                isValid: false,
            }
        };
        step.policyFields.push(uiField);

        uiField = {
            fieldId: 'kontonr',
            prefixLabel: 'Kontonr.',
            type: 'Text',
            isVisible: false,
            placeholder: 'F.eks. 00000000',
            model: { 
                id: 'kontonr',
                readOnly: false,
                value: undefined,
                isValid: false,
            }
        };
        step.policyFields.push(uiField);
        
        // missing
        step.id = 'paymentMethodBlock';
        step.dataGtm = 'tracking variable';
        step.subtitle = undefined;
        step.type = 'paymentMethodBlock';
        step.buttonLabel = 'Underskriv tilbud'
        // proposalSteps.splice(0, 0, conditionsStep);
        proposalSteps.push(step);
    }

    private sanitizeModel(model) {
        if (model && model.readOnly && (model.value === undefined || model?.value?.trim() === '')) {
            // if readonly and no value
            model.readOnly = false;
            model.modelAltered = true; // necessary?
            model.isValid = false;
            return model;
        }
        model.isValid = this.hasValue(model.value);
        return model;
    }

    private hasValue(value): boolean {
        if (value === undefined || value === null) {
            return false;
        } else if ( (value + '').trim() === '') {
            return false;
        }
        return true;
    }
    
    private getCmsPolicy(policyType: string) {
        const cmsPolicy = this.cmsModel.adjustPoliciesBlock.policyBlock.items.find(policyBlock => {
            if (policyBlock.content.type.toLocaleLowerCase() === policyType.toLocaleLowerCase()) {
                return policyBlock;
            }
        });

         // TODO only textFields, radiofields, radioGroup (segmented), Datepicker for now
         cmsPolicy.content.policyFields = cmsPolicy.content.policyFields.filter( (field) => {
            return field.type === 'Text' || 
                field.type === 'Text Autocomplete' ||
                field.type === 'Radio Button' || 
                field.type === 'Radio Group' || 
                field.type === 'Date Picker' || 
                field.type === 'Dropdown';
         })
        // always return copy of content structure
        return cloneDeep(cmsPolicy.content);
    }

    private getPolicyTitle(type: string) {
        let title: string;
        const typeUpper = type.toLocaleUpperCase();
        switch (typeUpper) {
            case 'HUND' : title = 'Hundeforsikring'
                break;
            case 'HEST' : title = 'Hesteforsikring'
                break;
            case 'BIL' : title = 'Bilforsikring'
                break;
            case 'INDBO' : title = 'Indboforsikring'
                break;
            case 'ULYKKE' : title = 'Ulykkesforsikring'
                break;
            default : title = type;
        }
        return title;

    }

    public getReceiptPolicyDetails(): any {
        // const cmsBlock = this.cmsModel.paymentMethodBlock;
        const step: any = {};
        // step.title = cmsBlock.stepHeadline;
        
        step.policyFields = [];
        
        const policies = this.proposal.policies;
        let uiField = {};
        policies.forEach(policy => {
            const title = this.getPolicyTitle(policy.type);
            const policyentry = `<div class="policy-entry"><strong>${title}</strong><br/>
            <div class="flex flex__space">
             <span>${policy.description}</span> <span>${this.formatter.format(policy.price)} kr./år</span>
            </div>
            <br/> </div>`;

            uiField = {
                fieldId: this.proposal,
                prefixLabel: policyentry,
                type: 'Text only',
                isVisible: true,
                model: { 
                    id: this.proposal.fee,
                    readOnly: true,
                    value: undefined,
                    isValid: true,
                }
            };    
            step.policyFields.push(uiField);
        });

        if (this.proposal.discountCampaign) {
            const campaignTxt = `
            <div class="flex flex__space mb-n3">
             <span>${this.proposal.discountCampaign}</span> <span>${this.proposal.discountCampaignValue}</span>
            </div>`;

            uiField = {
                fieldId: 'campaign',
                prefixLabel: campaignTxt,
                type: 'Text only',
                isVisible: true,
                model: { 
                    id: 'campaign',
                    readOnly: true,
                    value: undefined,
                    isValid: true,
                }
            };
            step.policyFields.push(uiField);
        }
        
        if (this.proposal.discount) {
            const txt = `
            <div class="flex flex__space mb-n3">
             <span><i>Plus</i>Kunderabat</span> <span>${this.formatter.format(this.proposal.discount)} kr./år</span>
            </div>`;
            uiField = {
                fieldId: 'discount',
                prefixLabel: txt,
                type: 'Text only',
                isVisible: true,
                model: { 
                    id: 'discount',
                    readOnly: true,
                    value: undefined,
                    isValid: true,
                }
            };
            step.policyFields.push(uiField);
        }


        const txt = `
        <div class="flex flex__space mb-n5">
        <span><h3>Din samlede pris</h3></span> <span><h3>${this.formatter.format(this.proposal.price)} kr./år</h3></span>
        </div>`;
        uiField = {
            fieldId: 'discount',
            prefixLabel: txt,
            type: 'Text only',
            isVisible: true,
            model: { 
                id: 'discount',
                readOnly: true,
                value: undefined,
                isValid: true,
            }
        };
        step.policyFields.push(uiField);

        uiField = {
            fieldId: 'fee',
            prefixLabel: 'Lovpligtige afgifter udgør' + ' ' + this.formatter.format(this.proposal.fee) + ' kr. <br/><br/>',
            type: 'Text only',
            isVisible: true,
            model: { 
                id: 'fee',
                readOnly: true,
                value: undefined,
                isValid: true,
            }
        };
        step.policyFields.push(uiField);    
        return step;    
    }

}