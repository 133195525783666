import { Options, Vue } from 'vue-class-component';
import {PropType} from 'vue';
import { mapState } from 'vuex';
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
import { TravelSettings } from './TravelSettings';
import { Model, STEPS } from'./Model';
import TravelCalculator from './TravelCalculator';
import ChoosePackageStepComponent from '../steps/common/ChoosePackageStepComponent.vue';
import ContactInformationStepComponent from '../steps/common/ContactInformationStepComponent.vue';
import OverviewStepComponent from '../steps/common/OverviewStepComponent.vue';
import AdditionalInfoStepComponent from '../steps/common/AdditionalInfoStepComponent.vue';
import PersonInfoStepComponent from '../steps/travel/PersonInfoStepComponent.vue';
import PaymentStepComponent from '../steps/common/PaymentStepComponent.vue';
import ReceiptStepComponent from '../steps/common/ReceiptStepComponent.vue';
import { BuyInsuranceHelper, STEP} from '../BuyInsuranceHelper';
import CampaignStepComponent from '../steps/common/CampaignStepComponent.vue';
import { cloneDeep } from "lodash";
import { CardBlock, HtmlBlock } from '@/cms/definitions/content-types';
import store from '@/store/store';
import { CALCULATOR_LOADED, INIT_CALCULATOR, RESET_CALCULATORS } from '@/store/modules/calculatorContext';
import { EnvironmentService } from '@/services/environmentService'; 
import UrlUtilService from '@/services/urlUtilService';
import { UsabillaInPageBlock } from '@/cms/definitions/content-types';

@Options({
    name: 'BuyInsuranceTravelComponent',
    props: {
        calculatorInfoBlock: Object as PropType< Array<HtmlBlock>>,
        contentBlocks: Object as PropType<Array<CardBlock>>,
        cardReceipt: Object as PropType<CardBlock>,
        settingsBlock: Object as PropType<HtmlBlock>,
        usabillaBlock: Object as PropType<UsabillaInPageBlock>,
        usabillaBlockLeaveIntent: Object as PropType<UsabillaInPageBlock>,
        contentUrl: String, // Used by helper to handle browser history
        headerBadgeTxt: String,
    },
    components: {
        UsabillaInPageComponent,
        PersonInfoStepComponent,
        ChoosePackageStepComponent,
        OverviewStepComponent,
        AdditionalInfoStepComponent,
        ContactInformationStepComponent,
        PaymentStepComponent,
        ReceiptStepComponent,
        CampaignStepComponent,
    },
    computed: mapState<any>({
        model: state => state.calculatorContext[state.calculatorContext.active].model,
        cms: state => state.calculatorContext[state.calculatorContext.active].cms,
    }),
})

export default class BuyTravel extends Vue {
    contentBlocks: Array<CardBlock>;
    cardReceipt: CardBlock;
    settingsBlock: HtmlBlock;
    usabillaBlock: UsabillaInPageBlock;
    usabillaBlockLeaveIntent: UsabillaInPageBlock;
    contentUrl: string; // Used by helper to handle browser history
    headerBadgeTxt: string;
    calculatorInfoBlock: HtmlBlock;

    // list and order of steps
    public steps : Array<string> = STEPS

    public model!: any; // model from store
    public cms!: TravelSettings; // setting from store
    public helper: BuyInsuranceHelper;
    public calculator: TravelCalculator = null;
    public componentInit = false;
    public checkout = false;

    public async created() {
        this.helper = new BuyInsuranceHelper(this);

        if (!store.getters.getActiveCalculator) { // this is first load - INIT store
            const cms = new TravelSettings(this.settingsBlock);
            await store.dispatch(INIT_CALCULATOR, {cms, model: cloneDeep(Model.model)});
        } else {
            await store.dispatch(CALCULATOR_LOADED);
        }

        if (!await this.helper.initComponent()) {
            return;
        }

        this.calculator = new TravelCalculator(this);

        this.helper.setFirstCard();
    }

    // called by helper
    public addResetSubscription() {
        const unsubscribe = store.subscribeAction((action, state) => {
            if (action.type === RESET_CALCULATORS) {
                setTimeout(() => {
                    this.checkout = true;
                    store.dispatch(INIT_CALCULATOR, {cms : this.cms, data: cloneDeep(Model.model)});
                    unsubscribe();
                }, 2000);
            }
        });
    }

    public get showValidNowOption() {
        return this.helper.showValidNowOption;
    }

    public async gotoCard(cardName: string): Promise<boolean> {

        if(cardName === STEP.PERSON_INFO || cardName === STEP.PACKAGE) {
            BuyInsuranceHelper.resetSelectedCalculation(this);
            // this.model.choosePackage.segmentedProductProperty = undefined;
            // altid kun en risk (dummy)
            this.model.choosePackage.ownRiskId = this.model.calculation.abCalc.excessIdDefault;
        }

        if(cardName === STEP.PACKAGE) {
            this.calculator.setupExcessList();
            this.model.calculation.isCalculated = false;
            // check Age:
            if (!this.helper.handleValidCustomerAge()) {
                this.nextStep(STEP.PERSON_INFO);
                return false;
            }
        }

        if(cardName === STEP.PAYMENT) {
            // force user to choose yearly/monthly payment to ensure progressive steps
            this.model.choosePackage.monthYear = undefined;
        }

        this.model.currentCardName = cardName;

        if(cardName === STEP.ORDER) {
            await this.orderByEmail();
            return false; // it's ok - just don't track in helper
        }
        return true;
    }

    /**
     * when nextbtn is clicked - check for valid
     * @param cardName
     */
    public nextStep(cardName: string, addToHistory: boolean = true) {
        this.helper.nextStep(cardName, addToHistory);
    }

    public getSubtitle(cardName: string) {
        return this.helper.getSubtitle(cardName);
    }

    public isActiveCard(cardName: string): boolean {
        return cardName === this.model.currentCardName;
    }

    public isValid(cardName): boolean {
        switch(cardName) {
            case STEP.PERSON_INFO:
                const okPerson = this.model.personInfo.familyMemberCount &&
                    this.helper.isValidPersonInfo()
                return okPerson;
            case STEP.PACKAGE:
                return this.helper.isValidPackage() && this.model.choosePackage.segmentedProductProperty;
            case STEP.OVERVIEW:
                return this.helper.isValidPackage();
            case STEP.CONTACT_INFORMATION:
                return this.helper.isValidContactInfo();
            case STEP.ADDITIONAL_INFO:
                return this.helper.isValidAdditionalInfo(false);
            case STEP.PAYMENT:
                return this.helper.isValidPaymentInfo();
            default: return true;
        }
    }
    // called by helper
    public setOverviewData() {
        this.helper.setHighLights();

        this.model.overview.details = [
            this.model.personInfo.customerAge + ' år',
            this.model.personInfo.zipCode + ', ' + this.model.personInfo.zipName,
            this.model.personInfo.familyMemberCount,
            'Destination: ' + this.model.choosePackage.segmentedProductProperty,
        ];
        this.helper.setDetailsYearlyPrice();
    }

    public async orderByEmail() {
        try {
            this.model.showSpinner = true;
            if (!this.isValid(this.model.currentCardName)) {
                this.model.showSpinner = false;
                return;
            }
            const pack = this.model.choosePackage.selectedPackage;
            let desc = '';

            let tracking = '';
            if(this.model.contact_information.customerNo) {
                const device = EnvironmentService.isApp() ? '(APP)' : '(WEB)';
                desc += `<li><h2><i>Kunde genkendt ${device}</i></h2></li>`;
                tracking += "/existingCustomer";
            }

            if(this.model.campaign.valid) {
                const id = this.model.campaign.ID ? this.model.campaign.ID.toLocaleUpperCase() : 'Ukendt!'
                desc += `<li><h2><i>Kampagne ${id}</i></h2></li>`;
                tracking += `/campaign/${id}`;
            }
            if(this.model.additionalInfo.rki?.toLowerCase() === 'ja') {
                desc += '<li><h2><i>Kunde er registreret i RKI</i></h2></li>';
                tracking += "/rki";
            }
            if(this.model.additionalInfo.skader !== '0') {
                desc += `<li><h2><i>Kunde har ${this.model.additionalInfo.skader} skader</i></h2></li>`;
                tracking += "/damages";
            }
            if( desc !== '') {
                desc = '<ul>' + desc + '</ul>'
            }
            await this.helper.submitOrder(desc, tracking);

            const price: number = this.model.choosePackage.monthYear === 'M' ? pack.totalPrice * 12 : pack.yearlyPriceTotal;
            const product = {
                name: 'FP_FP',
                // sku: 'SKU',
                id: pack.name,
                price: '' + price,
                brand: 'Fakedoor flow',
                variant: undefined,
                category: 'Online Salg',
                quantity: '1'
            }
            this.helper.trackPurchase(product, price);

        } catch(e) {
            this.model.showSpinner = false;
            if (UrlUtilService.isDevelop()) {
                this.model.modal.content = this.model.modal.content + '<br><div style="border: 2px solid red">' + e +'</div> ';
            }
            BuyInsuranceHelper.togglePopup(this.cms, {
                    id: 'error',
                    show: true,
                    redirect: undefined,
                    track: true,
                }
            );
            this.helper.sendErrorMailToTeam(e);
        }
    }

    /** Called by helper */
    public buildDescriptionForEmail(desc: string) {
        const addProp: Function = this.helper.addProp;
        const pack = this.model.choosePackage.selectedPackage;
        desc += '<h2>Person</h2>';
        const now: Date = new Date();
        desc += addProp('Email sendt', `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${(now.getMonth()+1)}-${now.getFullYear()}`);
        desc += addProp('Navn', this.model.contact_information.name);
        desc += addProp('Postnr./by', `${this.model.personInfo.zipCode} ${this.model.personInfo.zipName}`);
        desc += addProp('Alder', this.model.personInfo.customerAge);
        if (this.model.contact_information.customerNo) {
            desc += addProp('KundeNr', this.model.contact_information.customerNo);
        } else {
            desc += addProp('Cpr', this.model.contact_information.cpr);
        }
        desc += addProp('Antal personer i husstand', this.model.personInfo.familyMemberCount);
        desc += addProp('Antal skader', this.model.additionalInfo.skader);
        desc += addProp('Kunde har accepteret betingelser', this.model.contact_information.accept ? 'Ja' : 'Nej');
        desc += addProp('Email', this.model.contact_information.email);
        desc += addProp('Telefonnummer', this.model.contact_information.phone);
        // Kun Mastercard kampagne
        if (this.model.choosePackage.forfald) {
            desc += addProp('Forfald', this.model.choosePackage.forfald);
        }

        desc += this.helper.buildExistingProductsDescription();

        desc += '<h2>Forsikring</h2>';
        desc += addProp('Rki', this.model.additionalInfo.rki);
        desc += addProp('Eksisterende forsikring', this.model.additionalInfo.existInsurance);

        if (this.model.additionalInfo.existInsurance === 'ja') {
            desc += addProp('Nuværende selskab', this.model.additionalInfo.existingInsurance);
        }

        desc += addProp('Gyldig hurtigst mulig', this.model.additionalInfo.validNow);
        if(this.model.additionalInfo.validNow === 'nej') {
            desc += addProp('Gyldig pr.', this.model.additionalInfo.validFromDate);
        }

        desc += '<h2>Ønsket forsikring</h2>';
        desc += addProp('Forsikringstype', pack.name);
        desc += addProp('Destination', this.model.choosePackage.segmentedProductProperty);
        desc += addProp('Betaling', this.model.choosePackage.monthYear === 'M' ? 'Månedlig' : 'Årlig');
        desc += addProp('Samlet pris', this.model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay);
        desc += addProp('Beregningstype', 'FP_FP beregning');
        desc += addProp('Dækninger', this.helper.getCoverageNames(pack));


        desc += '<h2>Betaling</h2>';
        desc += addProp('Reg. nr.', this.model.payment.regNo);
        desc += addProp('Konto nr.', this.model.payment.kontoNo);

        const params: Map<string, string> = new Map();
        params.set('subject', `Kunde ønsker ${this.model.productName}`);
        params.set('name', this.model.contact_information.name);
        params.set('cprNo', this.model.contact_information.cpr);
        params.set('email', this.model.contact_information.email);
        params.set('phoneNo', this.model.contact_information.phone);
        params.set('description', desc);
        return params;
    }

    public gotoAddInsurances(step: string) {
        this.helper.gotoAddInsurances(step);
    }

    public get showReceipt() {
        return BuyInsuranceHelper.getShowReceipt(this);
    }

    public getCardTitle(card): string {
        return card.name === 'choose_package' && this.model.choosePackage.selectedPackage !== undefined 
            ? this.cms.calculatedHeadline : card.title;
    }
}
